import React from "react";
import { useTranslation } from 'react-i18next'
import { makeStyles } from "@material-ui/core/styles";
import SiteSectionCard from "../../components/SiteSectionCard"
import trackerImage from "./components/urbantracker.jpg"
import sentShipmentsImage from "./components/outgoing-shipments.png"
import receivingShipmentsImage from "./components/incoming-shipments.png"
import { RoutePath, navigate } from "../../Main";

const useStyles = makeStyles({
    siteSections: {
        marginBottom: 16,
        display: 'flex',
        justifyContent: 'center',
        flexWrap: "wrap"
    }
})


export default function GenericPage(props) {
    const classes = useStyles(props);
    const {t} = useTranslation()
    
    return (
        <div className={classes.siteSections}>
            <SiteSectionCard
                image={trackerImage}
                title={t("mainActivationCardTitle")}
                description={t("mainActivationCardDescription")}
                button={t("mainActivationCardButton")}
                onClick={() => navigate(props, RoutePath.activation)} />
            <SiteSectionCard
                image={sentShipmentsImage}
                title={t("mainSentAssetsCardTitle")}
                description={t("mainSentAssetsCardDescription")}
                button={t("mainSentAssetsCardButton")}
                onClick={() => navigate(props, RoutePath.sessionsSender)} />
            <SiteSectionCard
                image={receivingShipmentsImage}
                title={t("mainReceivingAssetsCardTitle")}
                description={t("mainReceivingAssetsCardDescription")}
                button={t("mainReceivingAssetsCardButton")}
                onClick={() => navigate(props, RoutePath.sessionsRecipient)} />
        </div>
    )
}