import React from "react";
import { SvgIcon } from "@material-ui/core";

export default function QrCode(props) {
  //Path taken from the svg file in the same dir
  return (
    <SvgIcon onClick = {props.onClick} >
      <path d="M4,4H10V10H4V4M20,4V10H14V4H20M14,15H16V13H14V11H16V13H18V11H20V13H18V15H20V18H18V20H16V18H13V20H11V16H14V15M16,15V18H18V15H16M4,20V14H10V20H4M6,6V8H8V6H6M16,6V8H18V6H16M6,16V18H8V16H6M4,11H6V13H4V11M9,11H13V15H11V13H9V11M11,6H13V10H11V6M2,2V6H0V2C0,0.9 0.9,0 2,0H6V2H2M22,0C23.1,0 24,0.9 24,2V6H22V2H18V0H22M2,18V22H6V24H2C0.9,24 0,23.1 0,22V18H2M22,22V18H24V22C24,23.1 23.1,24 22,24H18V22H22Z" />
    </SvgIcon>
  );
}
