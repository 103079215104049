import React from 'react'
import { makeStyles} from '@material-ui/core/styles'
import { Trans, useTranslation } from 'react-i18next'
import { navigate, RoutePath, smartphoneBps } from "../../Main"
import Header from './components/Header'
import TextField from '@material-ui/core/TextField'
import SideDescription from './components/SideDescription'
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import ButtonBase from "@material-ui/core/ButtonBase"
import { Fragment } from 'react'
import TrialImage from './components/justfollowit-trial.png'
import MockupsImage from './components/justfollowit-mockups.png'
import LoadingView from '../../components/LoadingView'
import PrivacyDialog from './components/PrivacyDialog'
import AlertDialog from '../../components/AlertDialog'
import NoBotSvg from './components/NoBotSvg'
import UtB2bApi from '../../network/UtB2bApi'
import {Helmet} from "react-helmet";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
    },
    content: {
        width: '90vw',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        paddingTop: 49,
        [smartphoneBps(theme)]: {
            width: '100vw',
            paddingTop: 24,
            flexDirection: 'column-reverse',
            justifyContent: 'flex-start'
        }
    },
    contentForm: {
        width: '100%',
        maxWidth: 700,
        display: 'flex',
        flexDirection: 'row',
        justifyItems: 'flex-start',
        alignItems: 'flex-start',
        flexWrap: 'wrap'
    },
    textField: {
        width: '100%',
        maxWidth: 320,
        margin: 12,
        '& p': {
            color: "#e60000"
        },
        [smartphoneBps(theme)]: {
            maxWidth: 1000,
            margin: 16,
        }
    },
    infoBox: {
        width: '100%',
        maxWidth: 320,
        margin: 12,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        [smartphoneBps(theme)]: {
            maxWidth: 1000,
            margin: 16,
        }
    },
    infoTextField: {
        width: '100%',
        '& p': {
            color: "#e60000"
        }
    },
    infoCharCounter: {
        marginTop: 6,
        color: '#00000099',
        font: 'normal normal normal 12px/14px Roboto'
    },
    privacyCheckBox: {
        marginTop: 8,
        marginLeft: 0,
        font: 'normal normal normal 16px/19px Roboto',
        color: '#666666',
        [smartphoneBps(theme)]: {
            margin: 4,
        }
    },
    personalDataCheckBox: {
        marginTop: 16,
        marginLeft: 0,
        font: 'normal normal normal 16px/19px Roboto',
        color: '#666666',
        [smartphoneBps(theme)]: {
            margin: 4,
        }
    },
    areYouHumanBox: {
        width: '100%',
        maxWidth: 320,
        margin: 12,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        [smartphoneBps(theme)]: {
            maxWidth: 1000,
            margin: 16,
        }
    },
    areYouHumanImage: {
        width: 24,
        height: 24,
        marginBottom: 6
    },
    areYouHumanTextField: {
        width: '100%',
        marginLeft: 8,
        '& p': {
            color: "#e60000"
        }
    },
    areYouHumanLabel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    bottomFormPart: {
        width: '100%',
        marginTop: 24,
        marginBottom: 42,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    sendButton: {
        padding: '10px 20px 10px 20px',
        color: "#ffffff",
        fontSize: 14,
        fontWeight: 600,
        background: "#3F58B8",
        border: "1px solid #FFFFFF;",
        borderRadius: 4,
        '&:disabled': {
            background: "#ccc",
        }
    },
    mandatory: {
        marginTop: 24,
        color: '#666666',
        font: 'normal normal normal 14px/17px Roboto'
    },
    privacyTextLink: {
        color: '#3F58B8',
        textDecoration: "underline",
        textDecorationThickness: "1px"
    }
}))

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
const phoneRegex = /^[\d()\-.+ ]*$/
const taxNumberRegex = /^\d*$/
const websiteRegex = /^(https?:\/\/)?([\w\d]+\.)?[\w\d]+\.\w+\/?.+$/

/** @type {Subscription} */ let sendBuyApplicationSubscription

export default function BuyPage(props) {
    const classes = useStyles(props)
    const { t } = useTranslation()
    const [formValues, setFormValues] = React.useState({
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        company: null,
        taxNumber: null,
        enterpriseType: null,
        employees: null,
        serviceDuration: null,
        website: null,
        info: null,
        areYouHuman: null,
        aboutUs: null
    })
    const [helperTexts, setHelperTexts] = React.useState({})
    const [privacyCheckboxEnabled, setPrivacyCheckboxEnabled] = React.useState(false)
    const [personalDataCheckboxEnabled, setPersonalDataCheckboxEnabled] = React.useState(false)
    const [showPrivacyDialog, setShowPrivacyDialog] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [showFormErrorDialog, setShowFormErrorDialog] = React.useState(false)
    const [areYouHumanNumbers, setAreYouHumanNumbers] = React.useState({first: 5, second: 1})
    const emailFieldRef = React.useRef()
    const phoneFieldRef = React.useRef()
    const taxNumberFieldRef = React.useRef()
    const websiteFieldRef = React.useRef()
    const areYouHumanFieldRef = React.useRef()
    React.useEffect(() => {
        generateAreYouHumanNumbers()
        return () => {
            if (sendBuyApplicationSubscription) sendBuyApplicationSubscription.unsubscribe()
        }
    }, [])

    const isOneOff = props.location.pathname === RoutePath.buyOneOff
    const isGold = props.location.pathname === RoutePath.buyGold
    const isTrial = props.location.pathname === RoutePath.buyTrial
    const isTrialByEmail = props.location.pathname === RoutePath.buyTrialByEmail

    const descriptionsArrayKey = isOneOff ? "buyOneOffDescriptions" : isGold ? "buyGoldDescriptions" : isTrial ? "buyTrialDescriptions" : "buyTrialByEmailDescription"

    const generateAreYouHumanNumbers = () => {
        setAreYouHumanNumbers({
            first: Math.floor(Math.random() * 10),
            second: Math.floor(Math.random() * 10)
        })
    }

    const handleTextChange = event => {
        setFormValues({...formValues, [event.target.name]: event.target.value })
        setHelperTexts({...helperTexts, [event.target.name]: null})
    }

    const handlePrivacyLink = event => {
        event.preventDefault()
        console.log("privacylink")
        setShowPrivacyDialog(true)
    }

    const handlePrivacyDialogClose = () => {
        setShowPrivacyDialog(false)
    }

    const isEmailSendButtonDisabled = () =>  !Boolean(formValues.firstName?.length)
        || !Boolean(formValues.lastName?.length)
        || !Boolean(formValues.email?.length)
        || !Boolean(formValues.phone?.length)
        || !privacyCheckboxEnabled
        || !personalDataCheckboxEnabled
        || !Boolean(formValues.areYouHuman?.length)
    
    
    const isSendButtonDisabled = () => !Boolean(formValues.firstName?.length)
        || !Boolean(formValues.lastName?.length)
        || !Boolean(formValues.email?.length)
        || !Boolean(formValues.phone?.length)
        || !Boolean(formValues.company?.length)
        || (isOneOff || isGold) && !Boolean(formValues.taxNumber?.length)
        || (isTrial && formValues.enterpriseType === null)
        || (isTrial && formValues.employees === null)
        || (isTrial && formValues.aboutUs === null)
        || (isOneOff && formValues.serviceDuration === null)
        || !privacyCheckboxEnabled
        || !personalDataCheckboxEnabled
        || !Boolean(formValues.areYouHuman?.length)
    
     
    const handleSendButton = () => {
        let notValidCount = 0
        let helperTextsTemp = {}
        const errorIncrementAndScroll = (fieldRef) => {
            if (notValidCount < 1) {
                fieldRef.current.scrollIntoView({ behavior: 'smooth' })
            }
            notValidCount++
        }
        if (!emailRegex.test(formValues.email)) {
            helperTextsTemp.email = t("buyFormErrorEmailNotValid")
            errorIncrementAndScroll(emailFieldRef)
        }
        if (!phoneRegex.test(formValues.phone)) {
            helperTextsTemp.phone = t("buyFormErrorPhoneNotValid")
            errorIncrementAndScroll(phoneFieldRef)
        }
        if (formValues.taxNumber != null){
            if (!taxNumberRegex.test(formValues.taxNumber)) {
            helperTextsTemp.taxNumber = t("buyFormErrorTaxNumberNotValid")
            errorIncrementAndScroll(taxNumberFieldRef)
            }
        }
        
        if (formValues.website != null){
            if (!websiteRegex.test(formValues.website)) {
            helperTextsTemp.website = t("buyFormErrorWebsiteNotValid")
            errorIncrementAndScroll(websiteFieldRef)
            }
        }
        if ((areYouHumanNumbers.first + areYouHumanNumbers.second) !== parseInt(formValues.areYouHuman)) {
            helperTextsTemp.areYouHuman = t("buyFormErrorAreYouHumanNotValid")
            errorIncrementAndScroll(areYouHumanFieldRef)
        }
        if (notValidCount > 0) {
            formValues.areYouHuman = null
            generateAreYouHumanNumbers()
            setHelperTexts(helperTextsTemp)
        } else {
            const subject = t("buyFormMessageSubject", {form: formValues })
            const message = isOneOff ? t("buyFormMessageBodySolution", 
                                 {solutionType: t("buyFormMessageBodySolutionOneOff"), 
                                 form: formValues,
                                 serviceDurationTypeText: t("buyOneOffServiceDurationValues")[formValues.serviceDuration]}) : 
                            isGold ? t("buyFormMessageBodySolution",
                                 {solutionType: t("buyFormMessageBodySolutionGold"), form: formValues}) :
                            t("buyFormMessageBodyTrial", {
                                 form: formValues,
                                 enterpriseTypeText: t("buyFormEnterpriseTypeValues")[formValues.enterpriseType], 
                                 employeesText: t("buyFormEmployeesValues")[formValues.employees],
                                 aboutUsText: t("buyFormAboutUsValues")[formValues.aboutUs],
                                })
            console.log("Send message subject:\n" + subject)
            console.log("Send message body:\n" + message)
            setLoading(true)
            sendBuyApplicationSubscription = UtB2bApi.rxSendRequestInfoMessage(subject, message)
                .subscribe(() => {
                    console.log("rxSendBuyApplication success")
                navigate(props, isOneOff ? RoutePath.buyOneOffConfirmation : isGold ? RoutePath.buyGoldConfirmation : isTrial ? RoutePath.buyTrialConfirmation : RoutePath.buyEmailTrialConfirmation)
                }, error => {
                    console.log("rxSendBuyApplication error " + error)
                    setLoading(false)
                    setShowFormErrorDialog(true)
                })
        }
    }

    return (
        <Fragment>
            <Helmet>
                <meta name="robots" content="noindex"/>
            
                {isTrialByEmail ?
                    <title>JustFollow.it: Request a Trial Kit</title>
                : isTrial ?
                    <title>JustFollow.it: Request a Trial Kit</title>
                : 
                <title>JustFollow.it: Contact us</title>}
            </Helmet>
           
            <div className={classes.root}>
                { isOneOff ?
                    <Header title={t("buyOneOffHeaderTitle")}
                        listItems={t("buyOneOffHeaderListItems")}
                        image={MockupsImage}/>
                : isGold ?
                    <Header title={t("buyGoldHeaderTitle")}
                        listItems={t("buyGoldHeaderListItems")}
                        image={MockupsImage}/>
                : isTrial ?
                    <Header title={t("buyTrialHeaderTitle")}
                        listItems={t("buyTrialHeaderListItems")}
                        note={t("buyTrialHeaderNote")}
                        image={TrialImage}/>
                : 
                    <Header title={t("buyTrialEmailHeaderTitle")}
                        listItems={t("buyTrialHeaderListItems")}
                        note={t("buyTrialHeaderNote")}
                        image={TrialImage}/>
                }
                <div className={classes.content}>
                    <div className={classes.contentForm}>
                        {   isTrialByEmail ?
                            <TextField 
                                name="firstName"
                                className={classes.textField}
                                label={t("buyEmailFormFirstName")} 
                                value={formValues.firstName || ""}
                                onChange={handleTextChange}
                                autoComplete="given-name"
                                inputProps={{ maxLength: 50 }} />
                            :
                            <TextField 
                                name="company"
                                className={classes.textField}
                                label={t("buyFormCompany")} 
                                value={formValues.company || ""}
                                onChange={handleTextChange}
                                autoComplete="organization"
                                inputProps={{ maxLength: 50 }} />
                                }
                         {   isTrialByEmail ?
                            <TextField 
                                name="lastName"
                                className={classes.textField}
                                label={t("buyEmailFormLastName")} 
                                value={formValues.lastName || ""}
                                onChange={handleTextChange}
                                autoComplete="given-name"
                                inputProps={{ maxLength: 50 }} />
                        : isTrial ?
                        <TextField 
                            name="taxNumber"
                            ref={taxNumberFieldRef}
                            className={classes.textField}
                            label={t("trialBuyFormTaxNumber")} 
                            value={formValues.taxNumber || ""}
                            helperText={helperTexts.taxNumber}
                            onChange={handleTextChange}
                            inputProps={{ maxLength: 50 }} />

                        :
                        <TextField 
                            name="taxNumber"
                            ref={taxNumberFieldRef}
                            className={classes.textField}
                            label={t("buyFormTaxNumber")} 
                            value={formValues.taxNumber || ""}
                            helperText={helperTexts.taxNumber}
                            onChange={handleTextChange}
                            inputProps={{ maxLength: 50 }} />
                         }
                           { isTrial ?
                            <Fragment>
                                <FormControl className={classes.textField}>
                                    <InputLabel htmlFor="type-helper">{t("buyFormEnterpriseType")}</InputLabel>
                                    <Select
                                        name="enterpriseType"
                                        value={formValues.enterpriseType === null ? "" : formValues.enterpriseType}
                                        onChange={handleTextChange}>
                                        {t("buyFormEnterpriseTypeValues").map((item, index) => (
                                            <MenuItem value={index} key={index}>{item}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.textField}>
                                    <InputLabel htmlFor="type-helper">{t("buyFormEmployees")}</InputLabel>
                                    <Select
                                        name="employees"
                                        value={formValues.employees === null ? "" : formValues.employees}
                                        onChange={handleTextChange}>
                                        {t("buyFormEmployeesValues").map((item, index) => (
                                            <MenuItem value={index} key={index}>{item}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Fragment>
                        : null}
                        { isTrialByEmail ?
                            <TextField 
                            name="email"
                            ref={emailFieldRef}
                            className={classes.textField}
                            label={t("buyEmailFormEmail")} 
                            value={formValues.email || ""}
                            helperText={helperTexts.email}
                            onChange={handleTextChange}
                            autoComplete="email"
                            inputProps={{ maxLength: 50 }} />
                        :
                            <TextField 
                                name="firstName"
                                className={classes.textField}
                                label={t("buyFormFirstName")} 
                                value={formValues.firstName || ""}
                                onChange={handleTextChange}
                                autoComplete="given-name"
                                inputProps={{ maxLength: 50 }} />
                            }
                        { isTrialByEmail ?
                            <TextField 
                                name="phone"
                                ref={phoneFieldRef}
                                className={classes.textField}
                                label={t("buyEmailFormPhone")} 
                                value={formValues.phone || ""}
                                helperText={helperTexts.phone}
                                onChange={handleTextChange}
                                autoComplete="tel"
                                inputProps={{ maxLength: 50 }} />
                        :
                            <TextField 
                                name="lastName"
                                className={classes.textField}
                                label={t("buyFormLastName")} 
                                value={formValues.lastName || ""}
                                onChange={handleTextChange}
                                autoComplete="family-name"
                                inputProps={{ maxLength: 50 }} />
                        }
                        {isTrialByEmail ? null
                        :
                        <TextField 
                            name="email"
                            ref={emailFieldRef}
                            className={classes.textField}
                            label={t("buyFormEmail")} 
                            value={formValues.email || ""}
                            helperText={helperTexts.email}
                            onChange={handleTextChange}
                            autoComplete="email"
                            inputProps={{ maxLength: 50 }} />}
                        {isTrialByEmail ? null
                        :
                            <TextField 
                                name="phone"
                                ref={phoneFieldRef}
                                className={classes.textField}
                                label={t("buyFormPhone")} 
                                value={formValues.phone || ""}
                                helperText={helperTexts.phone}
                                onChange={handleTextChange}
                                autoComplete="tel"
                                inputProps={{ maxLength: 50 }} />
                        }
                        {isTrialByEmail ? null
                        :
                        <TextField 
                            name="website"
                            ref={websiteFieldRef}
                            className={classes.textField}
                            label={t("buyFormWebsite")} 
                            value={formValues.website || ""}
                            helperText={helperTexts.website}
                            onChange={handleTextChange}
                            autoComplete="url"
                            inputProps={{ maxLength: 50 }} />
                        }
                        {isOneOff ? 
                        <Fragment>
                            <FormControl className={classes.textField}>
                                    <InputLabel htmlFor="type-helper">{t("buyOneOffServiceDuration")}</InputLabel>
                                    <Select
                                        name="serviceDuration"
                                        value={formValues.serviceDuration === null ? "" : formValues.serviceDuration}
                                        onChange={handleTextChange}>
                                        {t("buyOneOffServiceDurationValues").map((item, index) => (
                                            <MenuItem value={index} key={index}>{item}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Fragment>
                        : null}
                        { isTrial ?
                            <FormControl className={classes.textField}>
                            <InputLabel htmlFor="type-helper">{t("buyAboutJustFollowIt")}</InputLabel>
                            <Select
                                name="aboutUs"
                                value={formValues.aboutUs === null ? "" : formValues.aboutUs}
                                onChange={handleTextChange}>
                                {t("buyFormAboutUsValues").map((item, index) => (
                                    <MenuItem value={index} key={index}>{item}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        : null}
                        { isTrial ?
                            <div className={classes.infoBox}>
                                <TextField 
                                    name="info"
                                    className={classes.infoTextField}
                                    label={t("buyFormInfo")} 
                                    value={formValues.info || ""}
                                    onChange={handleTextChange}
                                    autoComplete="url"
                                    inputProps={{ maxLength: 2000 }}
                                    rows={5}
                                    multiline />
                                <div className={classes.infoCharCounter}>
                                    {Boolean(formValues.info) ? formValues.info.length : 0 }/2000
                                </div>
                            </div>
                        : null}
                        <FormControlLabel
                            className={classes.privacyCheckBox}
                            // label={t("buyFormPrivacy")}
                            label={(
                                <span>
                                    <Trans i18nKey="buyFormPrivacy">
                                        I have read and fully understood the
                                        <span 
                                            className={classes.privacyTextLink} 
                                            onClick={handlePrivacyLink}>
                                                privacy policy
                                        </span>
                                    </Trans>
                                </span>
                            )}
                            labelPlacement="end"
                            control={
                                <Checkbox 
                                    color="primary"
                                    checked={privacyCheckboxEnabled}
                                    onChange={event => setPrivacyCheckboxEnabled(event.target.checked)} />
                            }
                        />
                        <FormControlLabel
                            className={classes.personalDataCheckBox}
                            label={t("buyFormPersonalData")}
                            labelPlacement="end"
                            control={
                                <Checkbox 
                                    color="primary"
                                    checked={personalDataCheckboxEnabled}
                                    onChange={event => setPersonalDataCheckboxEnabled(event.target.checked)} />
                            }
                        />
                        <div className={classes.areYouHumanBox}>
                            <div className={classes.areYouHumanImage}>
                                <NoBotSvg />
                            </div>
                            <TextField 
                                name="areYouHuman"
                                ref={areYouHumanFieldRef}
                                className={classes.areYouHumanTextField}
                                label={t("buyFormAreYouHuman", { firstNumber: areYouHumanNumbers.first, secondNumber: areYouHumanNumbers.second})}
                                value={formValues.areYouHuman || ""}
                                helperText={helperTexts.areYouHuman}
                                onChange={handleTextChange}
                                inputProps={{ maxLength: 10 }} />
                        </div>
                        <div className={classes.bottomFormPart}>
                            <ButtonBase className={classes.sendButton}
                                disabled={isTrialByEmail ? isEmailSendButtonDisabled() : isSendButtonDisabled()}
                                onClick={handleSendButton}>
                                {isTrial ? 
                                    t("buyTrialSendButton")
                                 : 
                                    t("buySendButton")}    
                                
                            </ButtonBase>
                            <div className={classes.mandatory}>
                                {isTrialByEmail?
                                 t("buyAllMandatory")
                                :
                                 t("buyMandatory")}</div>
                        </div>
                    </div>
                    <SideDescription descriptionsArray={t(descriptionsArrayKey)}/>
                </div>
            </div>
            <LoadingView show={loading} />
            <PrivacyDialog
                open={showPrivacyDialog}
                onOkClick={handlePrivacyDialogClose} />
            <AlertDialog
                open={showFormErrorDialog}
                title={t("buyFormErrorSendTitle")}
                description={t("buyFormErrorSendDescription")}
                cancelButton={t("buyFormErrorSendButton")}
                onCancelClick={() => setShowFormErrorDialog(false)} />
        </Fragment>
    )
}