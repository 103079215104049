/*
 * UT b2b API
 * Urbantracker business 2 business API
 *
 * OpenAPI spec version: 1.2.0
 * Contact: nospam@stetel.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.18
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The UserCredentialsPlain model module.
 * @module model/UserCredentialsPlain
 * @version 1.2.0
 */
export class UserCredentialsPlain {
  /**
   * Constructs a new <code>UserCredentialsPlain</code>.
   * credentials needed to login
   * @alias module:model/UserCredentialsPlain
   * @class
   * @param username {String} 
   * @param secret {String} 
   */
  constructor(username, secret) {
    this.username = username;
    this.secret = secret;
  }

  /**
   * Constructs a <code>UserCredentialsPlain</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UserCredentialsPlain} obj Optional instance to populate.
   * @return {module:model/UserCredentialsPlain} The populated <code>UserCredentialsPlain</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UserCredentialsPlain();
      if (data.hasOwnProperty('username'))
        obj.username = ApiClient.convertToType(data['username'], 'String');
      if (data.hasOwnProperty('secret'))
        obj.secret = ApiClient.convertToType(data['secret'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} username
 */
UserCredentialsPlain.prototype.username = undefined;

/**
 * @member {String} secret
 */
UserCredentialsPlain.prototype.secret = undefined;


