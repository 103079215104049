import React, { Fragment } from 'react'
import { makeStyles} from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { smartphoneBps } from '../../Main'
import {Helmet} from "react-helmet";

const useStyles = makeStyles(theme => ({
    root: {
        alignSelf: 'center',
        width: '80vw',
        marginTop: 34,
        marginBottom: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    title: {
        width: '100vw',
        color: '#3f58b8',
        fontSize: 24,
        textAlign: 'center'
    },
    continents: {
        width: 'fit-content',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: "wrap",
        [smartphoneBps(theme)]: {
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    continent: {
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginLeft: 16,
        marginRight: 16,
        [smartphoneBps(theme)]: {
            justifyContent: 'center',
            marginLeft: 0,
            marginRight: 0
        }
    },
    continentsName: {
        color: '#3f58b8',
        fontSize: 20,
        marginTop: 16,
        [smartphoneBps(theme)]: {
            textAlign: 'center',
        }
    },
    country: {
        fontSize: 16,
        [smartphoneBps(theme)]: {
            textAlign: 'center',
        }
    },
    flexSpacer: {
        visibility: 'hidden',
        flexGrow: 10
    }
}))

export default function CoveragePage(props) {
    const classes = useStyles(props)
    const { t } = useTranslation('coverage')
    /** @type {Array<{name: String, countries: Array<String>}>} */ const continents = t("continents")

    const Continent = props => (
        <Fragment>
            <div className={classes.continentsName}>{props.continent.name}</div>
            { props.continent.countries.map(country => (
                <div key={country} className={classes.country}>{country}</div>
            ))}
        </Fragment>
    )

    return (
        <div className={classes.root}>
        <div className={classes.title}>{t('title')}</div>
        <div className={classes.continents}>
            { continents.map((continent, index) => (
                <Fragment key={index}>
                     <Helmet>
                        <meta name="robots" content="noindex"/>
                        <title>JustFollow.it: Geographic Coverage</title>
                     </Helmet>
                    { continent.countries.length >= 10 ? // if current continent is < 10 do nothing since it was already added together another one
                        <div className={classes.continent}>
                            <Continent continent={continent} />
                            { index+1 < continents.length && continents[index+1].countries.length < 10 ? 
                                <Continent continent={continents[index+1]} /> 
                            : null }
                        </div>
                    : null }
                </Fragment>
            )) }
        </div>
        </div>
    )
}