/*
 * UT b2b API
 * Urbantracker business 2 business API
 *
 * OpenAPI spec version: 1.2.0
 * Contact: nospam@stetel.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.18
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {RolesList} from './RolesList';

/**
 * The AuthInfo model module.
 * @module model/AuthInfo
 * @version 1.2.0
 */
export class AuthInfo {
  /**
   * Constructs a new <code>AuthInfo</code>.
   * Info needed to consume the Apis after authenticated
   * @alias module:model/AuthInfo
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>AuthInfo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AuthInfo} obj Optional instance to populate.
   * @return {module:model/AuthInfo} The populated <code>AuthInfo</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AuthInfo();
      if (data.hasOwnProperty('x-auth'))
        obj.xAuth = ApiClient.convertToType(data['x-auth'], 'String');
      if (data.hasOwnProperty('roles'))
        obj.roles = RolesList.constructFromObject(data['roles']);
    }
    return obj;
  }
}

/**
 * @member {String} xAuth
 */
AuthInfo.prototype.xAuth = undefined;

/**
 * @member {module:model/RolesList} roles
 */
AuthInfo.prototype.roles = undefined;


