import React from 'react'
import { makeStyles} from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { smartphoneBps } from "../../../Main"


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 342,
        display: 'flex',
        flexDirection: 'column',
        color: '#666666',
        font: 'normal normal normal 24px/29px Roboto',
        marginTop: 40,
        [smartphoneBps(theme)]: {
            marginTop: 0,
            marginBottom: 24,
            textAlign: 'center',
            alignSelf: 'center'
        }
    },
    firstItem: {
        fontWeight: 600
    },
    item: {
        marginTop: 16
    }
}))

export default function TrialDescription({descriptionsArray, ...props}) {
    const classes = useStyles(props)
    const { t } = useTranslation()

    return (
        <div className={classes.root}>
            {descriptionsArray.map((item, index) => (
                <div className={index === 0 ? classes.firstItem : classes.item} key={index}>{item}</div>
            ))}
        </div>
    )
}