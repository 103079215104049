/*
 * UT b2b API
 * Urbantracker business 2 business API
 *
 * OpenAPI spec version: 1.2.0
 * Contact: nospam@stetel.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.18
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from "../ApiClient";
import {MessageDetails} from '../model/MessageDetails';

/**
* WebAnonymous service.
* @module api/WebAnonymousApi
* @version 1.2.0
*/
export class WebAnonymousApi {

    /**
    * Constructs a new WebAnonymousApi. 
    * @alias module:api/WebAnonymousApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the forwardmessage operation.
     * @callback module:api/WebAnonymousApi~forwardmessageCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * forwards a message to a specific system or service admin.
     * forwards a message to a specific system or service admin. Nature of the bearer is decided by the system (email, sms etc)
     * @param {Object} opts Optional parameters
     * @param {String} opts.xAuth 
     * @param {module:model/MessageDetails} opts.messagedetails the credentials to access the account
     * @param {module:api/WebAnonymousApi~forwardmessageCallback} callback The callback function, accepting three arguments: error, data, response
     */
    forwardmessage(opts, callback) {
      opts = opts || {};
      let postBody = opts['messagedetails'];


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
        'X-Auth': opts['xAuth']
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/comm/forwardmessage', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }


}
