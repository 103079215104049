import React, { Fragment, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import { BrowserQRCodeReader } from '@zxing/library'
import Keyboard from '../svgIcon/Keyboard'
import QrIcon from '../svgIcon/QrCode'
import QrCodeScan from '../pages/activationPage/components/QrCodeScan'
// eslint-disable-next-line no-unused-vars
import { Subscription, of, asapScheduler } from 'rxjs'
import { delay, subscribeOn } from 'rxjs/operators'
import { rxCameraAvailable } from '../Main'

const useStyles = makeStyles(theme => ({
    textField: {
        margin: "0px 0px 8px 0px ",
        width: "100%",
        padding: "10px 0px",
    },
    FormHelperTextStyle : {
        color: "#e60000"
    }
}))

/** @type {Subscription} */ let helpTextSubscription

// Manual Regex matching "AE99B1D6801EDAEC5AD8EB64510845DE" exactly
const manualTrackerIdRegex = /^([a-zA-Z\d]{32})$/ 
// QR code Regex matching "HW:2.8; SN:AE99B1D6801EDAEC5AD8EB64510845DE" searching for "SN" and allowing different params order
const qrScanTrackerIdRegex = /(?:SN:)([a-zA-Z\d]{32})(?:;|$)/ 

//@param {{onNewTrackerId: function(String):void, disabled: Boolean, props: import('react-router-dom').RouteComponentProps}} param0 
/**
 * 
 * @param {Object} param0
 * @param {function(String):void} [param0.onNewTrackerId]
 * @param {Boolean} [param0.trackerId=null]
 * @param {Boolean} [param0.disabled=false]
 * @param {import('react-router-dom').RouteComponentProps} [param0.props=null]
 */
export default function TrackerIdInput({onNewTrackerId, trackerId = null, disabled = false, ...props}) {
    const classes = useStyles(props)
    const {t} = useTranslation()
    const [scanInProgress, setScanInProgress] = React.useState(false)
    const [allDisabled, setAllDisabled] = React.useState(false)
    const [newTrackerId, setNewTrackerId] = React.useState(null)
    const [trackerIdHelpText, setTrackerIdHelpText] = React.useState(null)
    const [cameraPresent, setCameraPresent] = React.useState(false)

    const codeReaderRef = useRef(new BrowserQRCodeReader())
    useEffect(() => setNewTrackerId(trackerId), [trackerId])

    useEffect(() => {
        setAllDisabled(disabled)
        if (disabled) {
            codeReaderRef.current.reset()
            setScanInProgress(false)
        }
    }, [disabled])

    useEffect(() => {
        const temp = codeReaderRef.current
        rxCameraAvailable().subscribe(available => {
            setCameraPresent(available)
            setScanInProgress(available)
        })
        return () => {
            if (helpTextSubscription) helpTextSubscription.unsubscribe()
            temp.reset()
        }
    }, [])

    const handleManualTextChange = () => event => {
        let manualInputText = event.target.value
        if (helpTextSubscription) helpTextSubscription.unsubscribe()
        setNewTrackerId(manualInputText)
        setTrackerIdHelpText(null)
        if (Boolean(manualInputText)) {
            if (manualTrackerIdRegex.test(manualInputText)) {
                onNewTrackerId(manualInputText)
            } else {
                helpTextSubscription = of(0).pipe(
                        subscribeOn(asapScheduler),
                        delay(3000)
                    ).subscribe(() => {
                        setTrackerIdHelpText(t("trackerIdInputNotValid"))
                    })
                onNewTrackerId(null)
            }
        } else {
            onNewTrackerId(null)
        }
    }

    /**
     * 
     * Tracker ID read from QR code:
     *  HW:2.8; SN:AE99B1D6801EDAEC5AD8EB64510845DE
     * 
     * @param {String} newTrackerId
     */
    const handleQrScanResult = (newTrackerId) => {
        codeReaderRef.current.reset()
        setScanInProgress(false)
        const trackerIdGroups = newTrackerId.match(qrScanTrackerIdRegex)
        if (trackerIdGroups && trackerIdGroups[1]) {
            setNewTrackerId(trackerIdGroups[1])
            setTrackerIdHelpText(null)
            onNewTrackerId(trackerIdGroups[1])
        } else {
            setNewTrackerId(null)
            setTrackerIdHelpText(t("trackerIdInputNotValid"))
            onNewTrackerId(null)
        }
    }

    const toggleManualOrQrScan = () => {
        codeReaderRef.current.reset()
        setScanInProgress(!scanInProgress)
    }

    return (
        <Fragment>
            <FormControl className={classes.textField}>
                <Input
                    disabled={scanInProgress || allDisabled}
                    value={newTrackerId || ""}
                    onChange={handleManualTextChange()}
                    endAdornment={
                        <InputAdornment position="end">
                            <Fragment>
                            {!cameraPresent ||
                                <IconButton onClick={toggleManualOrQrScan} disabled={allDisabled}>
                                    {scanInProgress ? <Keyboard /> : <QrIcon />}
                                </IconButton>
                            }
                            </Fragment>
                        </InputAdornment>
                    }
                />
                <FormHelperText className={classes.FormHelperTextStyle}>{trackerIdHelpText}</FormHelperText>
            </FormControl>
            <QrCodeScan 
                open={scanInProgress && !allDisabled}
                codeReader={codeReaderRef.current} 
                onQrScanResult={handleQrScanResult} />
        </Fragment>
    )
}