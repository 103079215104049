/*
 * UT b2b API
 * Urbantracker business 2 business API
 *
 * OpenAPI spec version: 1.2.0
 * Contact: nospam@stetel.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.18
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The TrackingOptions model module.
 * @module model/TrackingOptions
 * @version 1.2.0
 */
export class TrackingOptions {
  /**
   * Constructs a new <code>TrackingOptions</code>.
   * @alias module:model/TrackingOptions
   * @class
   * @param senderAddress {String} receives an email with the tracking info
   * @param recipientAddress {String} address of the recipient, currently only email addresses are supported, receives an email with the tracking info
   */
  constructor(senderAddress, recipientAddress) {
    this.senderAddress = senderAddress;
    this.recipientAddress = recipientAddress;
  }

  /**
   * Constructs a <code>TrackingOptions</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/TrackingOptions} obj Optional instance to populate.
   * @return {module:model/TrackingOptions} The populated <code>TrackingOptions</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new TrackingOptions();
      if (data.hasOwnProperty('senderAddress'))
        obj.senderAddress = ApiClient.convertToType(data['senderAddress'], 'String');
      if (data.hasOwnProperty('recipientAddress'))
        obj.recipientAddress = ApiClient.convertToType(data['recipientAddress'], 'String');
      if (data.hasOwnProperty('allowSenderOndemand'))
        obj.allowSenderOndemand = ApiClient.convertToType(data['allowSenderOndemand'], 'Boolean');
      if (data.hasOwnProperty('allowRecipientOndemand'))
        obj.allowRecipientOndemand = ApiClient.convertToType(data['allowRecipientOndemand'], 'Boolean');
      if (data.hasOwnProperty('allowRecipient2Deactivate'))
        obj.allowRecipient2Deactivate = ApiClient.convertToType(data['allowRecipient2Deactivate'], 'Boolean');
      if (data.hasOwnProperty('viewerMustAuthenticate'))
        obj.viewerMustAuthenticate = ApiClient.convertToType(data['viewerMustAuthenticate'], 'Boolean');
      if (data.hasOwnProperty('alertSpeedLowerThanKmh'))
        obj.alertSpeedLowerThanKmh = ApiClient.convertToType(data['alertSpeedLowerThanKmh'], 'Number');
      if (data.hasOwnProperty('trackingIntervall'))
        obj.trackingIntervall = ApiClient.convertToType(data['trackingIntervall'], 'Number');
      if (data.hasOwnProperty('freeCustomerID'))
        obj.freeCustomerID = ApiClient.convertToType(data['freeCustomerID'], 'String');
      if (data.hasOwnProperty('freeFromAddress'))
        obj.freeFromAddress = ApiClient.convertToType(data['freeFromAddress'], 'String');
      if (data.hasOwnProperty('freeToAddress'))
        obj.freeToAddress = ApiClient.convertToType(data['freeToAddress'], 'String');
    }
    return obj;
  }
}

/**
 * receives an email with the tracking info
 * @member {String} senderAddress
 */
TrackingOptions.prototype.senderAddress = undefined;

/**
 * address of the recipient, currently only email addresses are supported, receives an email with the tracking info
 * @member {String} recipientAddress
 */
TrackingOptions.prototype.recipientAddress = undefined;

/**
 * if true then the sender can ask for a punctual localization
 * @member {Boolean} allowSenderOndemand
 * @default false
 */
TrackingOptions.prototype.allowSenderOndemand = false;

/**
 * if true then the recipient can ask for a punctual localization
 * @member {Boolean} allowRecipientOndemand
 * @default false
 */
TrackingOptions.prototype.allowRecipientOndemand = false;

/**
 * if true then the recipient can deactivate the tracker
 * @member {Boolean} allowRecipient2Deactivate
 * @default false
 */
TrackingOptions.prototype.allowRecipient2Deactivate = false;

/**
 * if true then the viewer Url can deactivate the tracker
 * @member {Boolean} viewerMustAuthenticate
 * @default false
 */
TrackingOptions.prototype.viewerMustAuthenticate = false;

/**
 * if 0 or higher the system will notifiy if the average speed is below the set value (km/h). -1 means not set
 * @member {Number} alertSpeedLowerThanKmh
 */
TrackingOptions.prototype.alertSpeedLowerThanKmh = undefined;

/**
 * the number of minutes between one point and the next one
 * @member {Number} trackingIntervall
 */
TrackingOptions.prototype.trackingIntervall = undefined;

/**
 * any ID the activator wants to use to refer to this session
 * @member {String} freeCustomerID
 */
TrackingOptions.prototype.freeCustomerID = undefined;

/**
 * any address/place the activator wants to use to remember the starting place
 * @member {String} freeFromAddress
 */
TrackingOptions.prototype.freeFromAddress = undefined;

/**
 * any address/place the activator wants to use to remember the arrival place
 * @member {String} freeToAddress
 */
TrackingOptions.prototype.freeToAddress = undefined;


