import React, { useRef, useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import GoogleMapReact from 'google-map-react'
import { makeStyles } from "@material-ui/core/styles"
import useSupercluster from 'use-supercluster'
import Paper from '@material-ui/core/Paper'
import { smartphoneBps } from '../../../Main'

const useStyles = makeStyles(theme => ({
    mapContainer: {
        overflow: "hidden",
        position: "absolute",
        top: 120, // website's header height
        right: 0,
        bottom: 0,
        left: 0,
        [smartphoneBps(theme)]: {
            top: 70
        }
    },
    clusterMarker: {
        fontSize: "large",
        color: "#fff",
        background: "#3f58b8",
        border: '2px solid #ffffff',
        borderRadius: "50%",
        padding: 7,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    singleMarker: {
        width: 14,
        height: 14,
        background: "#3f58b8",
        borderRadius: "50%",
        marginLeft: -7,
        marginTop: -7,
    },
    locationInfoPaper: {
        position: "relative",
        marginTop: 5,
        padding: 5,
        fontSize: "small",
        width: 140,
        left: -70,
        textAlign: "center",
        zIndex: 100000
    },
    noGeolocations: {
        color: "#3f58b8",
        fontSize: "xx-large",
        textAlign: "center",
        marginTop: 150,
        marginLeft: 10,
        marginRight: 10
    }
}))

const MarkerWrapper = ({ children }) => children;

export default function SessionMap({ geoPath }) {
    const classes = useStyles()
    const {t} = useTranslation()

    // setup map
    const mapRef = useRef();
    const mapsRef = useRef();
    const polylineLastAddedItemIndex = useRef(0)

    // load data
    const points = geoPath ? geoPath.map((item, index) => {
        return {
            type: "Feature",
            properties: {
                itemId: item.id,
                index: index,
                date: item.timeStamp
            },
            geometry: {
                type: 'Point',
                coordinates: [item.geoLong, item.geoLat],
            }
        }
    }) : []
    // get map bounds
    const [bounds, setBounds] = useState(null);
    const [zoom, setZoom] = useState(14);

    // get clusters
    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom,
        options: { radius: 75, maxZoom: 20 }
    });

    const generatePath = () => {
        if (mapRef.current && mapsRef.current && geoPath && geoPath.length > 0) {
            const newGeoPath = geoPath.slice(polylineLastAddedItemIndex.current)
            if (newGeoPath && newGeoPath.length > 0) {
                const polylineCoords = newGeoPath.map(item => {
                    return { lat: item.geoLat, lng: item.geoLong }
                })
                const trackerPath = new mapsRef.current.Polyline({
                    path: polylineCoords,
                    geodesic: true,
                    strokeColor: '#3f58b8',
                    strokeOpacity: 1.0,
                    strokeWeight: 2
                })
                trackerPath.setMap(mapRef.current)
                polylineLastAddedItemIndex.current = geoPath.length
            }
        }
    }

    const GroupMarker = ({ clusterId, pointCount, latitude, longitude}) => {
        const markerSize = 15 + (pointCount / points.length) * 20
        const markerCentering = Math.floor(markerSize / 2) * -1
        return (
            <div
                className={classes.clusterMarker}
                style={{
                    width: markerSize,
                    height: markerSize,
                    marginLeft: markerCentering,
                    marginTop: markerCentering,
                }}
                onClick={() => {
                    const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(clusterId), 20)
                    mapRef.current.setZoom(expansionZoom);
                    mapRef.current.panTo({ lat: latitude, lng: longitude })
                }}>
                {pointCount}
            </div>
        )
    }
    const [locationInfo, setLocationInfo] = useState(null)

    const SingleMarker = ({ index, date }) =>
        <div>
            <div
                className={classes.singleMarker} 
                onMouseEnter={() => setLocationInfo({ 
                    index: index, 
                    dateString: date.toLocaleString() 
                })}
                onMouseLeave={() => setLocationInfo(null)} />

            {locationInfo && locationInfo.index === index ? 
                <Paper elevation={3} className={classes.locationInfoPaper}>
                    <div>{t("viewerMapLocationInfo", {
                        index: locationInfo.index + 1, 
                        date: locationInfo.dateString
                    })}</div>
                </Paper>
            : null}
        </div>

    // return map
    return (
        <div className={classes.mapContainer}>
            { geoPath && geoPath.length > 0 ?
                <Fragment>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyCr9M9yz9Di-u6b5cBIRNFMmm6CEsbbPbI" }}
                        defaultZoom={14}
                        options={{ fullscreenControl: false }}
                        center={{ lat: geoPath[geoPath.length - 1].geoLat, lng: geoPath[geoPath.length - 1].geoLong }}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => {
                            mapRef.current = map
                            mapsRef.current = maps
                            generatePath()
                        }}
                        onChange={({ zoom, bounds }) => {
                            setZoom(zoom);
                            setBounds([
                                bounds.nw.lng,
                                bounds.se.lat,
                                bounds.se.lng,
                                bounds.nw.lat
                            ]);
                        }}>
                        {clusters.map(cluster => {
                            const [longitude, latitude] = cluster.geometry.coordinates;
                            const {
                                cluster: isCluster,
                                point_count: pointCount
                            } = cluster.properties;
                            
                            return isCluster ? (
                                <MarkerWrapper
                                    key={`cluster-${cluster.id}`}
                                    lat={latitude}
                                    lng={longitude}>
                                        <GroupMarker
                                            clusterId={cluster.id}
                                            latitude={latitude}
                                            longitude={longitude}
                                            pointCount={pointCount} />
                                </MarkerWrapper>
                            ) : (
                                <MarkerWrapper
                                    key={`single-${cluster.properties.itemId}`}
                                    lat={latitude}
                                    lng={longitude}>
                                        <SingleMarker 
                                            index={cluster.properties.index} 
                                            date={cluster.properties.date} />
                                </MarkerWrapper>
                            )
                        })}
                    </GoogleMapReact>
                </Fragment>
            :
                <div className={classes.noGeolocations}>
                    {t("viewerMapNoGeolocations")}
                </div>
            }
        </div>
    )
}