import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from "@material-ui/core/styles"
import { useLocation } from "react-router-dom"
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { navigate, RoutePath } from '../../Main'
import { Subscription } from 'rxjs/internal/Subscription'
import LoadingView from '../../components/LoadingView'
import TopLoginCard from './components/top-login-card.png'
import auth, { LoginError } from '../../components/Auth'
import { storageRedirectAfterLoginUrl } from '../loginPage/LoginPage'
import AcceptTermsDialog, { storageTermsAccepted } from '../loginPage/components/AcceptTermsDialog'
import {Helmet} from "react-helmet";

const useStyles = makeStyles(theme => ({
    root: {
        alignSelf: 'center',
        width: '90vw',
        maxWidth: 360,
        marginTop: 16,
        marginLeft: 16,
        marginRight: 16,
        marginBottom: 16,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundImage: `url(${TopLoginCard})`,
        backgroundSize: "360px 64px",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top'
    },
    form: {
        paddingTop: 100,
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 16,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    title: {
        fontSize: 23
    },
    username: {
        marginTop: 14,
        width: "100%"
    },
    password: {
        marginTop: 24,
        width: "100%"
    },
    errorMsg: {
        marginTop: 26,
        fontSize: 12,
        height: 24,
        color: "#ff0000",
        fontWeight: 550
    },
    button: {
        fontWeight: 550,
        fontSize: 14,
        width: 'fit-content',
        marginTop: 8,
        alignSelf: "flex-end"
    }
}))

/** @type {Subscription} */ let loginSubscription

export default function TrialLoginPage(props) {
    const classes = useStyles(props)
    const {t} = useTranslation()
    const [credentials, setCredentials] = useState({username: null, password: null})
    const [errorMsg, setErrorMsg] = useState(null)
    const [loading, setLoading] = useState(false)
    const [acceptTermsDialogOpen, setAcceptTermsDialogOpen] = useState(false)

    // Note: commented because the user could be saved, but get redirected 
    //      to the login page because the token id is expired
    // useEffect(() => {
    //     if (trialLogin.getUser()) {
    //         navigate(props)
    //     }
    // }, [props])

    const isOkButtonDisabled = () => !(Boolean(credentials.username) && Boolean(credentials.password))

    const handleTextChange = event => {
        setCredentials({...credentials, [event.target.name]: event.target.value })
        setErrorMsg(null)
    }

    const handleOkButton = event => {
        event.preventDefault()
        const termsAccepted = localStorage.getItem(storageTermsAccepted) ?? '0'
        if (termsAccepted === '1') {
            handleLogin()
        } else {
            setAcceptTermsDialogOpen(true)
        }
        return true
    }

    const handleTermsAcceptedClick = () => {
        handleLogin()
        localStorage.setItem(storageTermsAccepted, '1')
        setAcceptTermsDialogOpen(true)
    }

    const handleLogin = () => {
        setLoading(true)
        loginSubscription = auth.rxLogin(credentials.username, credentials.password).subscribe(
            user => {
                console.log(`TrialLogin login successful: ${user.displayName}`)
                // let redirectAfterLogin = null
                // const comingFrom = location.state ? location.state.from : null
                // console.log("comingFrom: ", comingFrom)
                // if (comingFrom && !comingFrom.pathname.startsWith(RoutePath.login)) {
                //     redirectAfterLogin = comingFrom.pathname + comingFrom.search
                //     console.log("trialLoginPage, redirecting to ", redirectAfterLogin) 
                // }
                // navigate(props, redirectAfterLogin)
                let redirectAfterLogin = sessionStorage.getItem(storageRedirectAfterLoginUrl)
                if (redirectAfterLogin) {
                    console.log("trialLoginPage, redirecting to ", redirectAfterLogin)
                    sessionStorage.removeItem(storageRedirectAfterLoginUrl)
                } else {
                    console.log("trialLoginPage, redirecting to main page")
                    redirectAfterLogin = RoutePath.root
                }
                navigate(props, redirectAfterLogin)
            }, (/** @type {Error} */ error) => {
                console.log(`TrialLogin login error: ${error}`)
                if (error.message === LoginError.wrongCredentials) {
                    setErrorMsg(t("trialLoginWrongCredentials"))
                } else {
                    setErrorMsg(t("trialLoginGenericError"))
                }
                setLoading(false)
            }
        )
        
        return () => {
            if (loginSubscription) loginSubscription.unsubscribe()
        }
    }

    return (
        <Fragment>
            <Helmet>
                <meta name="robots" content="noindex"/>
                <title>JustFollow.it: Trial Login</title>
            </Helmet>
            <Paper className={classes.root}>
                <form className={classes.form} noValidate autoComplete="on" onSubmit={handleOkButton}>
                    <div className={classes.title}>{t("trialLoginTitle")}</div>
                    <TextField 
                        name="username"
                        className={classes.username}
                        label={t("trialLoginUsername")} 
                        value={credentials.username || ""}
                        onChange={handleTextChange}
                        error={Boolean(errorMsg)}
                        autoComplete='username'/>
                    <TextField 
                        name="password"
                        className={classes.password}
                        label={t("trialLoginPassword")} 
                        value={credentials.password || ""}
                        onChange={handleTextChange}
                        error={Boolean(errorMsg)}
                        type='password'
                        autoComplete='current-password'/>
                    <div className={classes.errorMsg}>{errorMsg}</div>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={isOkButtonDisabled()}
                        onClick={handleOkButton}
                        className={classes.button}
                        type='submit'>
                            {t("trialLoginOkButton")}
                    </Button>
                </form>
            </Paper>
            <LoadingView show={loading} />
            <AcceptTermsDialog 
                open={acceptTermsDialogOpen} 
                onCancelClick={() => setAcceptTermsDialogOpen(false)}
                onOkClick={handleTermsAcceptedClick} />
        </Fragment>
    )
}