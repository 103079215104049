/*
 * UT b2b API
 * Urbantracker business 2 business API
 *
 * OpenAPI spec version: 1.2.0
 * Contact: nospam@stetel.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.18
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {TrackingOptions} from './TrackingOptions';

/**
 * The ActivationStatus model module.
 * @module model/ActivationStatus
 * @version 1.2.0
 */
export class ActivationStatus {
  /**
   * Constructs a new <code>ActivationStatus</code>.
   * @alias module:model/ActivationStatus
   * @class
   * @param status {module:model/ActivationStatus.StatusEnum} the current status
   */
  constructor(status) {
    this.status = status;
  }

  /**
   * Constructs a <code>ActivationStatus</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ActivationStatus} obj Optional instance to populate.
   * @return {module:model/ActivationStatus} The populated <code>ActivationStatus</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ActivationStatus();
      if (data.hasOwnProperty('status'))
        obj.status = ApiClient.convertToType(data['status'], 'String');
      if (data.hasOwnProperty('statusMessage'))
        obj.statusMessage = ApiClient.convertToType(data['statusMessage'], 'String');
      if (data.hasOwnProperty('lastUpdate'))
        obj.lastUpdate = ApiClient.convertToType(data['lastUpdate'], 'Date');
      if (data.hasOwnProperty('retrySeconds'))
        obj.retrySeconds = ApiClient.convertToType(data['retrySeconds'], 'Number');
      if (data.hasOwnProperty('sessionId'))
        obj.sessionId = ApiClient.convertToType(data['sessionId'], 'String');
      if (data.hasOwnProperty('currentConfig'))
        obj.currentConfig = TrackingOptions.constructFromObject(data['currentConfig']);
    }
    return obj;
  }
}

/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
ActivationStatus.StatusEnum = {
  /**
   * value: "notactive"
   * @const
   */
  notactive: "notactive",

  /**
   * value: "activating"
   * @const
   */
  activating: "activating",

  /**
   * value: "active"
   * @const
   */
  active: "active",

  /**
   * value: "deactivating"
   * @const
   */
  deactivating: "deactivating",

  /**
   * value: "deactivated"
   * @const
   */
  deactivated: "deactivated",

  /**
   * value: "error"
   * @const
   */
  error: "error"
};

/**
 * the current status
 * @member {module:model/ActivationStatus.StatusEnum} status
 */
ActivationStatus.prototype.status = undefined;

/**
 * message giving more info about the status. Human readable
 * @member {String} statusMessage
 */
ActivationStatus.prototype.statusMessage = undefined;

/**
 * @member {Date} lastUpdate
 */
ActivationStatus.prototype.lastUpdate = undefined;

/**
 * after how many seconds the client can ask for another activationstatus update
 * @member {Number} retrySeconds
 */
ActivationStatus.prototype.retrySeconds = undefined;

/**
 * the id to be used to refer to this tracking session
 * @member {String} sessionId
 */
ActivationStatus.prototype.sessionId = undefined;

/**
 * @member {module:model/TrackingOptions} currentConfig
 */
ActivationStatus.prototype.currentConfig = undefined;


