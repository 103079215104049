// eslint-disable-next-line no-unused-vars
import { Observable, asapScheduler, Subscriber, throwError } from 'rxjs'
import { catchError, flatMap, map, subscribeOn, tap } from 'rxjs/operators'
import { UserType } from "../Main"
import utb2bApi from '../network/UtB2bApi'
import myFirebase from './Firebase.js'

const storageUser = "user"

/** @type {User} */
let currentUser = null

class Auth {
    isUserLoaded = () => {
        const user = this.getUser()
        return user !== null && Boolean(user.idToken)
    }

    getUser = () => {
        if (currentUser === null) {
            const userJson = localStorage.getItem(storageUser)
            if (userJson) {
                const userObj = JSON.parse(userJson)
                currentUser = new User(
                    userObj.provider,
                    userObj.username,
                    userObj.idToken,
                    userObj.email,
                    userObj.displayName,
                    userObj.photoUrl,
                    userObj.types)
            }
        }
        return currentUser
    }

    /**
     * @returns {Observable<User>} Observable emitting the Firebase User data
     */
    rxGetUser = () => new Observable(emitter => {
        emitter.next(this.getUser())
        emitter.complete()
    })

    /**
     * Login with username and password
     * 
     * @param {String} username
     * @param {String} password
     * @returns {Observable<User>} Observable completing on finish
     */
    rxLogin = (username, password) => utb2bApi.rxTrialLogin(username, password).pipe(
        tap(user => {
            if (user) {
                localStorage.setItem(storageUser, JSON.stringify(user))
            }
            currentUser = user
        }), 
        catchError(error => {
            localStorage.removeItem(storageUser)
            currentUser = null
            if (error && error.statusCode === 401) {
                return throwError(Error(LoginError.wrongCredentials))
            } else {
                return throwError(Error(LoginError.generic))
            }
        })
    )

    /**
     * Login with an OAut provider.
     * 
     * @param {String} provider provider where to perform the login
     * @returns {Observable<void>} Observable completing on finish
     */
    rxOAuthLogin = provider => {
        if (provider === UserProviders.google) {
            const emptyUser = new User(UserProviders.google, null, null, null, null, null, null) 
            localStorage.setItem(storageUser, JSON.stringify(emptyUser))
            return myFirebase.rxLogin()
        } else {
            throwError(Error(LoginError.invalidProvider))
        }
    }

    rxOAuthVerifyAfterRedirect = () => this.rxGetUser().pipe(
        flatMap(user => {
            if (user.provider === UserProviders.google) {
                return myFirebase.rxGetUser()
            } else {
                throw Error(LoginError.invalidProvider)
            }
        }),
        tap(firebaseUser => {
            if (firebaseUser === null) {
                throw Error(LoginError.noFirebaseUser)
            }
        }),
        flatMap(firebaseUser => myFirebase.rxGetIdToken(firebaseUser).pipe(
            map(idToken => new User(UserProviders.google, 
                firebaseUser.uid, 
                idToken, 
                firebaseUser.email,
                firebaseUser.displayName,
                firebaseUser.photoURL, 
                null))
        )),
        flatMap(user => utb2bApi.rxCheckUserAccess(user.idToken).pipe(
            map(userTypes => {
                user.types = userTypes
                return user
            })
        )),
        tap(user => {
            localStorage.setItem(storageUser, JSON.stringify(user))
            currentUser = user
        }), 
        catchError(error => {
            localStorage.removeItem(storageUser)
            currentUser = null
            return throwError(error)
        })
    )

    /**
     * @returns {Observable<void>} Observable completing on finish
     */
    rxLogout = () => this.rxGetUser().pipe(
        tap(() => {
            localStorage.removeItem(storageUser)
            currentUser = null
        }),
        flatMap(user => {
            if (user.provider === UserProviders.custom) {
                return utb2bApi.rxTrialLogout(user)
            } else if (user.provider === UserProviders.google) {
                return myFirebase.rxLogOut()
            } else {
                throw Error(LoginError.invalidProvider)
            }
        }),
        map(() => void(0))
    )

    isWaitingForOAuth = () => {
        const user = this.getUser()
        return user && user.provider === UserProviders.google && user.idToken === null
    }
}

export const UserProviders = {
    custom: 'custom',
    google: 'google'
}

export const LoginError = {
    invalidProvider: 'invalidProvider',
    wrongCredentials: 'wrongCredentials',
    noFirebaseUser: 'noFirebaseUser',
    generic: 'generic'
}

export class User {
    /**
     * @param {String} provider
     * @param {String} username
     * @param {String} idToken
     * @param {String} email
     * @param {String} displayName
     * @param {String} photoUrl
     * @param {Types} types
     */
    constructor(provider, username, idToken, email, displayName, photoUrl, types) {
        this.provider = provider
        this.username = username
        this.idToken = idToken
        this.email = email
        this.displayName = displayName
        this.photoUrl = photoUrl
        this.types = types
    }

    hasAtLeastOneType = () => this.types ? (
        this.types.sender || this.types.receiver || this.types.follower
    ) : false

    hasNoTypes = () => this.types ? (
        !this.types.sender && !this.types.receiver && !this.types.follower
    ) : true

    typesAsArray = () => {
        const arr = []
        if (this.types) {
            if (this.types.sender) {
                arr.push(UserType.sender)
            }
            if (this.types.receiver) {
                arr.push(UserType.recipient)
            }
            if (this.types.follower) {
                arr.push(UserType.viewer)
            }
        }
        return arr
    }
}

export function Types(typeSender, typeReceiver, typeFollower) {
    this.sender = typeSender
    this.receiver = typeReceiver
    this.follower = typeFollower
}

export default new Auth()

