import React from 'react';
import "./loadingView.css"

export default function LoadingView({show}) {

    return show === true ? 
        <div className="dialog-overlay">
            <div className="dialog-aligner">
                <div className="dialog-overlay-waitmessage">
                    <div className="loader" />
                </div>
            </div>
        </div>
    : null
}