import React, { useState } from "react";
import { useTranslation } from 'react-i18next'
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SiteSectionCard from "../../components/SiteSectionCard"
import googleImage from "./components/google-login-card.png"
import auth, { LoginError, UserProviders } from "../../components/Auth"
import { navigate, RoutePath, smartphoneBps } from "../../Main";
import LoadingView from "../../components/LoadingView";
import AlertDialog from "../../components/AlertDialog";
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import ButtonBase from "@material-ui/core/ButtonBase";
import AcceptTermsDialog, { storageTermsAccepted } from "./components/AcceptTermsDialog";
// eslint-disable-next-line no-unused-vars
import { Subscription } from "rxjs/internal/Subscription";
import BackgroundImage from "./components/homepage-background.jpg"
import {Helmet} from "react-helmet"
import { useMediaQuery } from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        backgroundPositionX: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize:"cover",
        width: "100%",
        overflow: "hidden",
        position: "relative",
        [smartphoneBps(theme)]: {
            flexDirection: 'column-reverse',
            backgroundImage: `url(${BackgroundImage})`,
        }
    },

    hdrVideo: {
        objectFit: "cover",
        top: 0,
        position: "absolute",
        left: 0,
        height: "100%",
        width: "100%",
        maxWidth: "100%",
        borderRadius: 0,
        zIndex: "-10",
        [smartphoneBps(theme)]: {
            display: "none",
        }
    },

    discoverBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingLeft: 16,
        [smartphoneBps(theme)]: {
            alignItems: 'center',
        }
    },
    discoverTitle: {
        color: "#ffffff",
        font: "normal normal 400 50px/67px Roboto;",
        fontWeight: 300,
        maxWidth: 400
    },
    discoverButton: {
        width: "100%",
        maxWidth: 335,
        height: 64,
        color: "#ffffff",
        fontSize: 18,
        fontWeight: 600,
        background: "#3F58B8 0% 0% no-repeat padding-box;",
        border: "1px solid #FFFFFF;",
        borderRadius: 16,
        marginTop: 48,
        paddingLeft: 16,
        paddingRight: 16
    },
    discoverTry: {
        color: "#ffffff",
        font: "normal normal 300 20px/24px Roboto;",
        marginTop: 24,
        marginBottom: 24
    },
    discoverTryLink: {
        color: "#FDC600",
        cursor: "pointer",
        textDecoration: "underline",
        textDecorationThickness: "1px",
        marginLeft: 8,
        marginRight: 8
    },
    siteSections: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 16
    },
    trialBox: {
        width: "90vw",
        maxWidth: 360,
        height: 98,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        background: '#576DC2',
        borderRadius: 4,
        color: '#ffffff',
        marginTop: 10,
        marginLeft: 16,
        marginRight: 16,
        paddingTop: 8,
        paddingBottom: 8
    },
    trialInfo: {
        display: 'flex',
        flexDirection: 'row',
        padding: 16
    },
    trialInfoImage: {
        width: 24,
        height: 24
    },
    trialInfoText: {
        marginTop: 2,
        marginLeft: 8,
        fontSize: 16,
        textAlign: 'left'
    },
    trialButton: {
        alignSelf: 'flex-end',
        padding: 8,
        fontSize: 15,
        fontWeight: 600
    }
}))

export const storageRedirectAfterLoginUrl = "redirectAfterLoginUrl"

/** @type {Subscription} */ let loginSubscription

export default function LoginPage({ showTrialLogin = false, ...props }) {
    const classes = useStyles(props);
    const { t } = useTranslation()
    const theme = useTheme()
    const smartphone = useMediaQuery(smartphoneBps(theme))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [acceptTermsDialogOpen, setAcceptTermsDialogOpen] = useState(null)

    React.useEffect(() => {
        if (auth.isWaitingForOAuth()) {
            setLoading(true)
            loginSubscription = auth.rxOAuthVerifyAfterRedirect().subscribe(user => {
                if (user) {
                    let redirectAfterLogin = sessionStorage.getItem(storageRedirectAfterLoginUrl)
                    if (redirectAfterLogin) {
                        console.log("loginPage, redirecting to ", redirectAfterLogin)
                        sessionStorage.removeItem(storageRedirectAfterLoginUrl)
                    } else {
                        console.log("loginPage, redirecting to main page")
                        redirectAfterLogin = RoutePath.root
                    }
                    navigate(props, redirectAfterLogin)
                }
            }, (/** @type {Error} */ error) => {
                console.log(`auth.rxOAuthVerifyAfterRedirect error: ${error}`)
                setError(error.message !== LoginError.noFirebaseUser)
                setLoading(false)
            })
        }

        return () => {
            if (loginSubscription) loginSubscription.unsubscribe()
        }
    }, [])

    const handleButtons = provider => {
        const termsAccepted = localStorage.getItem(storageTermsAccepted) ?? '0'
        if (termsAccepted === '1') {
            handleLogin(provider)
        } else {
            setAcceptTermsDialogOpen(provider)
        }
        return true
    }

    const handleTermsAcceptedClick = () => {
        handleLogin(acceptTermsDialogOpen)
        localStorage.setItem(storageTermsAccepted, '1')
        setAcceptTermsDialogOpen(null)
    }

    const handleLogin = provider => {
        if (provider === UserProviders.google) {
            auth.rxOAuthLogin(UserProviders.google).subscribe()
        } else if (provider === UserProviders.custom) {
            navigate(props, RoutePath.trialLogin)
        } else {
            console.log("Cannot open login flow: bad user provider")
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <meta name="robots" content="index,follow"/>
                <meta name="description" content="Log in into your personal account to monitor and keep your shipments always safe."/>
                <title>JustFollow.it</title>
            </Helmet>
                <div className={classes.root}>
                <video className={classes.hdrVideo} playsinline autoPlay muted loop  src="/images/hdr-bg-video.mp4" type="video/mp4"/> 
                 <div className={classes.discoverBox}>
                        { !smartphone ? 
                            <div className={classes.discoverTitle}>{t("loginDiscoverTitle")}</div>
                        : null }
                        <ButtonBase className={classes.discoverButton}
                            onClick={() => navigate(props, RoutePath.discover)}>
                            {t("loginDiscoverButton")}
                        </ButtonBase>
                        <div className={classes.discoverTry}>
                            {t("loginDiscoverTry")}
                            <span className={classes.discoverTryLink}
                                onClick={() => navigate(props, RoutePath.buyTrial, RoutePath.login)}>
                                {t("loginDiscoverTryLink")}
                            </span>
                        </div>  
                    </div>
                    <div className={classes.siteSections}>
                        <SiteSectionCard
                            image={googleImage}
                            width="360"
                            title={t("loginGoogleCardTitle")}
                            description={t("loginGoogleCardDescription")}
                            button={t("loginGoogleCardButton")}
                            onClick={() => handleButtons(UserProviders.google)} />
                        <ButtonBase
                            className={classes.trialBox}
                            onClick={() => handleButtons(UserProviders.custom)}>
                            <div className={classes.trialInfo}>
                                <NewReleasesIcon className={classes.trialInfoImage} />
                                <div className={classes.trialInfoText}>{t('loginTrialInfo')}</div>
                            </div>
                            <div className={classes.trialButton}>{t('loginTrialButton')}</div>
                        </ButtonBase>
                    </div>
                </div>
                <LoadingView show={loading} />
                <AcceptTermsDialog
                    open={acceptTermsDialogOpen !== null}
                    onCancelClick={() => setAcceptTermsDialogOpen(null)}
                    onOkClick={handleTermsAcceptedClick} />
                <AlertDialog
                    open={error}
                    title={t("oauthLoginErrorDialogTitle")}
                    description={t("oauthLoginErrorDialogDescription")}
                    okButton={t("oauthLoginErrorDialogOkButton")}
                    onOkClick={() => setError(false)} />
            </React.Fragment>
    )
}