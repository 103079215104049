import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { ButtonBase } from '@material-ui/core'
import { navigate, RoutePath } from '../../../Main'


const useStyles = makeStyles(theme => ({
    root: {
        width: 310,
        height: 410,
        marginTop: 48,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    header: {
        width: '100%',
        height: 95,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FDC600'
    },
    headerTitle: {
        color: '#fff',
        font: 'normal normal 300 28px/22px Roboto'
    },
    headerSubtitle: {
        color: '#fff',
        font: 'normal normal normal 16px/21px Roboto'
    },
    content: {
        width: "100%",
        height: "100%",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: '#333333',
        backgroundColor: '#F5F5F5'
    },
    trackerPriceLine: {
        marginTop: 36
    },
    trackerPrice: {
        font: 'normal normal normal 48px/17px Roboto'
    },
    trackerPriceSmall: {
        font: 'normal normal normal 18px/17px Roboto'
    },
    serviceDuration: {
        marginTop: 12,
        font: 'normal normal normal 16px/21px Roboto'
    },
    serviceInfo: {
        marginTop: 7,
        font: 'normal normal normal 16px/21px Roboto'
    },
    setupPriceLine: {
        marginTop: 24
    },
    // setupPrice: {
    //     font: 'normal normal normal 36px/17px Roboto'
    // },
    // setupPriceSmall: {
    //     font: 'normal normal normal 18px/22px Roboto'
    // },
    // setupText: {
    //     marginTop: 5,
    //     font: 'normal normal normal 16px/19px Roboto'
    // },
    divider: {
        width: 282,
        height: 1,
        marginTop: 24,
        backgroundColor: '#707070'
    },
    locationsInfo: {
        marginTop: 24,
        font: 'normal normal normal 18px/22px Roboto'
    },
    button: {
        width: 200,
        height: 80,
        background: '#FDC600',
        border: '1px solid #FFFFFF',
        borderRadius: 16,
        marginTop: -32,
        color: '#fff',
        fontSize: 18,
        fontWeight: 600
    }
}))

export default function SolutionThirdCard(props) {
    const classes = useStyles(props)
    const { t } = useTranslation()

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.headerTitle}>{t("discoverSolutionsThirdCardTitle")}</div>
                <div className={classes.headerSubtitle}>{t("discoverSolutionsThirdCardSubtitle")}</div>
            </div>
            <div className={classes.content}>
                <div className={classes.trackerPriceLine}>
                    <span className={classes.trackerPrice}>{t("discoverSolutionsThirdCardPrice")}</span>
                    <span className={classes.trackerPriceSmall}>{t("discoverSolutionsThirdCardPriceSmall")}</span>
                </div>
                <div className={classes.serviceDuration}>{t("discoverSolutionsThirdCardServiceDuration")}</div>
                <div className={classes.serviceInfo}>{t("discoverSolutionsThirdCardServiceInfo")}</div>
                <div className={classes.setupPriceLine}>
                    {/* <span className={classes.setupPrice}>{t("discoverSolutionsThirdCardSetupPrice")}</span> */}
                    {/* <span className={classes.setupPriceSmall}>{t("discoverSolutionsThirdCardSetupPriceSmall")}</span> */}
                </div>
                {/* <div className={classes.setupText}>{t("discoverSolutionsThirdCardSetupText")}</div> */}
                <div className={classes.divider} />
                <div className={classes.locationsInfo}>{t("discoverSolutionsThirdCardLocationsInfo")}</div>
            </div>
            <ButtonBase className={classes.button}
                onClick={() => navigate(props, RoutePath.buyTrial)}>
                    {t("discoverSolutionsThirdCardButtonText")}
            </ButtonBase>
        </div>
    )
}