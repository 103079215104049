import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.onCancelClick}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{props.description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                { props.cancelButton ?
                    <Button onClick={props.onCancelClick} color="primary">{props.cancelButton}</Button>
                : null}
                { props.okButton ?
                    <Button onClick={props.onOkClick} color="primary">{props.okButton}</Button>
                : null}
            </DialogActions>
        </Dialog>
    );
}