import React from 'react'
import BatteryFullIcon from '@material-ui/icons/BatteryFull' // battery full
import Battery90Icon from '@material-ui/icons/Battery90'    // battery 90
import Battery80Icon from '@material-ui/icons/Battery80'    // battery 80
import Battery60Icon from '@material-ui/icons/Battery60'    // battery 60
import Battery50Icon from '@material-ui/icons/Battery50'    // battery 50
import Battery30Icon from '@material-ui/icons/Battery30'    // battery 30
import Battery20Icon from '@material-ui/icons/Battery20'    // battery 20
import BatteryAlertIcon from '@material-ui/icons/BatteryAlert'  // low battery
import BatteryUnknownIcon from '@material-ui/icons/BatteryUnknown'  // battery unknown
import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles({
    icon: props => ({
        fontSize: props.height ? props.height : 25
    })
})

export default function BatteryIcon({percLevel, ...props}) {
    const classes = useStyles(props)
    if (percLevel === null || percLevel < 0) {
        return <BatteryUnknownIcon className={classes.icon} />
    } else if (percLevel > 90) {
        return <BatteryFullIcon className={classes.icon} />
    } else if (percLevel > 80) {
        return <Battery90Icon className={classes.icon} />
    } else if (percLevel > 60) {
        return <Battery80Icon className={classes.icon} />
    } else if (percLevel > 50) {
        return <Battery60Icon className={classes.icon} />
    } else if (percLevel > 30) {
        return <Battery50Icon className={classes.icon} />
    } else if (percLevel > 20) {
        return <Battery30Icon className={classes.icon} />
    } else if (percLevel > 10) {
        return <Battery20Icon className={classes.icon} />
    } else {
        return <BatteryAlertIcon className={classes.icon} />
    }
}