import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    root: {
        width: "90vw",
        maxWidth: 360,
        marginTop: 16,
        marginLeft: 16,
        marginRight: 16
    },
    media: {
        height: 0,
        paddingTop: 160, //'56.25%', // 16:9
    },
    title: {
        fontSize: 23
    },
    description: {
        fontSize: 16
    },
    cardActions: {
        display: "flex",
        justifyContent: "flex-end"
    }
});

export default function SiteSectionCard(props) {
    const classes = useStyles(props);

    return (
        <Card className={classes.root}>
            <CardActionArea onClick={props.onClick}>
                <CardMedia
                    className={classes.media}
                    image={props.image}
                    title={props.title}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                        {props.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.description}>
                        {props.description}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions className={classes.cardActions}>
                <Button size="large" color="primary"  onClick={props.onClick}>
                    {props.button}
                </Button>
            </CardActions>
        </Card>
    );
}