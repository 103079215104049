import React from 'react'
import { makeStyles} from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import DownloadIcon from '@material-ui/icons/GetApp'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button/Button'
import {Helmet} from "react-helmet"


const useStyles = makeStyles(theme => ({
    root: {
        alignSelf: 'center',
        width: '80vw',
        marginTop: 34,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    title: {
        color: '#3f58b8',
        fontSize: 24
    },
    text: {
        fontSize: 16,
        textAlign: 'justify'
    },
    link: {
        textDecoration: 'none'
    }
}))

export default function HelpPage(props) {
    const classes = useStyles(props)
    const { t } = useTranslation()
    
    return (  
        <div className={classes.root}>
            <Helmet>
                <meta name="robots" content="index,follow"/>
                <meta name="description" content="Read the JustFollow.it User Guide and activate the tracker to follow your parcel in indoor and outdoor environments."/>
                <title>JustFollow.it: User Guide</title>
            </Helmet>
            <div className={classes.title}>{t('guideTitle')}</div>
            <p className={classes.text}>{t('guideText')}</p>
            {t('language') === 'it'?
                <div>
                    <Link to="/GuidaUtente_v1.3.pdf" target="_blank" download className={classes.link}>
                        <Button
                            endIcon={<DownloadIcon/>}>
                            Guidautente_v1.3.pdf
                        </Button>
                    </Link>
                </div>
                : 
                <div>  
                    <Link to="/UserGuide_v1.3.pdf" target="_blank" download className={classes.link}>
                        <Button
                            endIcon={<DownloadIcon/>}>
                            UserGuide_v1.3.pdf
                        </Button>
                    </Link>
                </div>}    
        </div>
    )
}