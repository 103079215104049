import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { ButtonBase } from '@material-ui/core'
import { navigate, RoutePath } from '../../../Main'


const useStyles = makeStyles(theme => ({
    root: {
        width: 310,
        height: 410,
        marginTop: 48,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    header: {
        width: '100%',
        height: 95,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#576DC2'
    },
    headerTitle: {
        color: '#fff',
        font: 'normal normal 300 28px/22px Roboto'
    },
    headerSubtitle: {
        color: '#fff',
        font: 'normal normal medium 18px/22px Roboto'
    },
    content: {
        width: "100%",
        height: "100%",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: '#333333',
        backgroundColor: '#F5F5F5'
    },
    trackerPriceLine: {
        marginTop: 36
    },
    // trackerPrice: {
    //     color: '#b5b5b5',
    //     font: 'normal normal normal 48px/17px Roboto',
    //     textDecoration: 'line-through'
    // },
    // trackerPriceSmall: {
    //     color: '#b5b5b5',
    //     font: 'normal normal normal 16px/21px Roboto',
    //     textDecoration: 'line-through'
    // },
    packPrice: {
        font: 'normal normal normal 48px/19px Roboto'
    },
    packPriceSmall: {
     font: 'normal normal normal 16px/21px Roboto'
     },
    trackerPrice: {
        marginTop: 5,
        font: 'normal normal normal 14px/19px Roboto'
    },
    serviceDuration: {
        marginTop: 12,
        font: 'normal normal normal 16px/21px Roboto'
    },
    setupPriceLine: {
        marginTop: 60
    },
    // setupPrice: {
    //     color: '#b5b5b5',
    //     font: 'normal normal normal 36px/17px Roboto',
    //     textDecoration: 'line-through'
    // },
    setupPriceSmall: {
        color: '#b5b5b5',
        font: 'normal normal normal 16px/21px Roboto',
        textDecoration: 'line-through'
    },
    twoYearsPackPrice: {
        font: 'normal normal normal 36px/17px Roboto',
    },
    twoYearsPackPriceSmall: {
        font: 'normal normal normal 16px/21px Roboto',
    },
    twoYearsDurationText: {
        marginTop: 5,
        font: 'normal normal normal 16px/21px Roboto'
    },
 
    divider: {
        width: 282,
        height: 1,
        marginTop: -100,
        backgroundColor: '#707070'
    },
    locationsInfo: {
        marginTop: 22,
        font: 'normal normal normal 16px/21px Roboto'
    },
    button: {
        width: 200,
        height: 80,
        background: '#576DC2',
        border: '1px solid #FFFFFF',
        borderRadius: 16,
        marginTop: -32,
        color: '#fff',
        fontSize: 18,
        fontWeight: 600
    }
}))

export default function SolutionFirstCard(props) {
    const classes = useStyles(props)
    const { t } = useTranslation()

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.headerTitle}>{t("discoverSolutionsFirstCardTitle")}</div>
                <div className={classes.headerSubtitle}>{t("discoverSolutionsFirstCardSubtitle")}</div>
            </div>
            <div className={classes.content}>
                <div className={classes.trackerPriceLine}>
                    {/*<span className={classes.trackerPrice}>{t("discoverSolutionsFirstCardTrackerPrice")}</span> 
                    <span className={classes.trackerPriceSmall}>{t("discoverSolutionsFirstCardTrackerPriceSmall")}</span>*/}
                    &nbsp;
                    <span className={classes.packPrice}>{t("discoverSolutionsFirstCardPackPrice")}</span>
                    <span className={classes.packPriceSmall}>{t("discoverSolutionsFirstCardPackPriceSmall")}</span>
                </div>
                <div className={classes.trackerPrice}>{t("discoverSolutionsFirstCardTrackerPrice")}</div>
                <div className={classes.serviceDuration}>{t("discoverSolutionsFirstCardServiceDuration")}</div>
                <div className={classes.setupPriceLine}>
                    {/*<span className={classes.setupPrice}>{t("discoverSolutionsFirstCardSetupPrice")}</span>
                    <span className={classes.setupPriceSmall}>{t("discoverSolutionsFirstCardSetupPriceSmall")}</span>*/}
                    &nbsp;
                    <span className={classes.twoYearsPackPrice}>{t("discoverSolutionsFirstCard2yearsPackPrice")}</span>
                    <span className={classes.twoYearsPackPriceSmall}>{t("discoverSolutionsFirstCard2yearsPackPriceSmall")}</span>
                </div>
                <div className={classes.trackerPrice}>{t("discoverSolutionsFirstCard2YearsTrackerPrice")}</div>
                <div className={classes.twoYearsDurationText}>{t("discoverSolutionsFirstCard2yearsDuration")}</div>
                <div className={classes.divider} />
                {/*<div className={classes.locationsInfo}>{t("discoverSolutionsFirstCardLocationsInfo")}</div>*/}
            </div>
            <ButtonBase className={classes.button}
                onClick={() => navigate(props, RoutePath.buyOneOff)}>
                {t("discoverSolutionsFirstCardButtonText")}
            </ButtonBase>
        </div>
    )
}