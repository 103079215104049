import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
    dialog: {
        position: 'relative',
        marginTop: '15vh',
        maxHeight: 490,
        justifySelf: 'center',
        alignSelf: 'center'
    },
    checkBox: {
        marginTop: 24,
        paddingLeft: 24,
        paddingRight: 24,
        fontSize: 14,
        color: '#666666'
    },
    pTitle: {
        width: '100%',
        color: '#636262',
        fontSize: 20,
        textAlign: 'left'
    },
    pText: {
        width: '100%',
        color: '#636262',
        fontSize: 16,
        textAlign: 'justify'
    }
}))

export const storageTermsAccepted = 'termsAccepted'

export default function AcceptTermsDialog(props) {
    const classes = useStyles(props)
    const { t } = useTranslation(['translation', 'terms'])
    const [checkBoxAccepted, setCheckBoxAccepted] = useState(false)
    /** @type {Array<{pTitle: String, pText: String}>} */ const pageBody = t("terms:pageBody")

    return (
        <Dialog
            className={classes.dialog}
            open={props.open}
            onClose={props.onCancelClick}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{t('terms:pageTitle')}</DialogTitle>
            <DialogContent>
                { pageBody.map((section, index) => (
                    <Fragment key={index}>
                        { Boolean(section.pTitle) ? <div className={classes.pTitle}>{section.pTitle}</div> : null }
                        { Boolean(section.pText) ? <div className={classes.pText} dangerouslySetInnerHTML={{__html: section.pText}} /> : null}
                    </Fragment>
                ))}
            </DialogContent>
            <FormControlLabel
                className={classes.checkBox}
                label={t("acceptTermsCheckBoxLabel")}
                labelPlacement="end"
                control={
                    <Checkbox 
                        color="primary"
                        checked={checkBoxAccepted}
                        onChange={event => setCheckBoxAccepted(event.target.checked)} />
                    }/>
            <DialogActions>
                <Button 
                    onClick={props.onCancelClick} 
                    color="primary" >
                        {t('acceptTermsCancelButton')}
                </Button>
                <Button 
                    onClick={props.onOkClick} 
                    color="primary" 
                    disabled={!checkBoxAccepted}>
                        {t('acceptTermsOkButton')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}