import React, { useState, Fragment, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import LoadingView from '../../components/LoadingView'
// eslint-disable-next-line no-unused-vars
import { Subscription } from 'rxjs'
import Fab from '@material-ui/core/Fab'
import queryString from 'query-string'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew' // request tracker deactivation
import AlertDialog from '../../components/AlertDialog'
import DeactivateDialog from './components/DeactivateDialog'
import { RoutePath, navigate, smartphoneBps, redirectToLogin } from '../../Main'
import SessionMap from './components/SessionMap'
import Paper from '@material-ui/core/Paper'
import TrackerItem from '../../components/trackerItems/TrackerItem'
import BatteryItem from '../../components/trackerItems/BatteryItem'
import TrackingIntervalItem from '../../components/trackerItems/TrackingIntervalItem'
import Utb2bApi from '../../network/UtB2bApi'
import { SessionStatus } from '../../network/swaggerApi/model/SessionStatus'
// eslint-disable-next-line no-unused-vars
import HttpError from '../../network/HttpError'
// eslint-disable-next-line no-unused-vars
import auth, { User } from '../../components/Auth'
// eslint-disable-next-line no-unused-vars
import { TrackingOptions } from '../../network/swaggerApi/model/TrackingOptions'
// eslint-disable-next-line no-unused-vars
import { RolesList } from '../../network/swaggerApi/model/RolesList'
import MovementNotificationItem from '../../components/trackerItems/MovementNotificationItem'

const useStyles = makeStyles(theme => ({
    statusItemValueBattery: {
        color: "#3f58b8",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    map: {
        height: "100%",
        width: "100%",
        flexGrow: 2
    },
    firstFloatingButton: {
        position: "fixed",
        top: 140,
        left: 20,
        [smartphoneBps(theme)]: {
            top: 80
        }
    },
    secondFloatingButton: {
        position: "fixed",
        top: 140,
        left: 90,
        [smartphoneBps(theme)]: {
            top: 80
        }
    },
    thirdFloatingButton: {
        position: "fixed",
        top: 140,
        left: 180,
        [smartphoneBps(theme)]: {
            top: 80
        }
    },
    statusBox: {
        position: "fixed",
        top: 220,
        left: 20,
        padding: 10,
        maxWidth: 300,
        maxHeight: 600,
        overflow: "auto",
        [smartphoneBps(theme)]: {
            top: 146,
            maxWidth: 200,
            maxHeight: 400
        }
    },
    statusItem: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: 10
    },
    statusItemName: {
        color: "#999999",
        fontSize: "small"
    },
    statusItemValue: {
        color: "#3f58b8",
    },
}))

/** @type {Subscription} */ let sessionSubscription

/**
 * @param {{ user: User }} props
 */
export default function ViewerPage(props) {
    const classes = useStyles(props)
    const {t} = useTranslation()
    const [loading, setLoading] = useState(true)
    const [showStatusBox, setShowStatusBox] = useState(false)
    const [requestDeactivateDialogOpen, setRequestDeactivateDialogOpen] = useState(false)
    const [requestPositionDialogOpen, setRequestPositionDialogOpen] = useState(false)
    const [loadingErrorDialog, setLoadingErrorDialog] = useState({
        open: false,
        title: null,
        description: null,
        button: null
    })
    const [sessionStatus, setSessionStatus] = useState({
        status: null,
        battery: null,
        lastUpdate: null,
        geoPath: null,
        allowDeactivation: false,
        allowRequestPosition: false,
        trackingIntervalMinutes: null,
        alertSpeedLowerThanKmh: null,
        shipmentId: null,
        shipFromAddress: null,
        shipToAddress: null
    })
    const sessionIdRegex = new RegExp(`${props.match.path}/([0-9a-zA-Z]+)`)
    const sessionIdGroups = props.location.pathname.match(sessionIdRegex)
    const sessionId = sessionIdGroups && sessionIdGroups[1] ? sessionIdGroups[1] : null
    console.log("Session ID:", sessionId)
    const queryParams = queryString.parse(props.location.search)
    const requestAuthentication = queryParams.auth === "true"
    console.log("Request authentication", requestAuthentication)

    const updateUi = useCallback(() => {
        setLoading(true)
        if (sessionSubscription) sessionSubscription.unsubscribe()
        /** @type {SessionStatus} */
        let fullUserSessionStatus = null
        sessionSubscription = Utb2bApi.rxRetrieveSession(sessionId).subscribe((sessionStatus) => {
            console.log("rxRetrieveSession sessionStatus:", sessionStatus)
            if (fullUserSessionStatus === null) {
                fullUserSessionStatus = sessionStatus
            } else if (sessionStatus.geoPath) {
                fullUserSessionStatus.geoPath.push(...sessionStatus.geoPath)
            }
        }, (/** @type {HttpError} */ error) => {
            console.log("rxRetrieveSession error:", error)
            if (error.statusCode === 404) {
                setLoadingErrorDialog({
                    open: true,
                    title: "viewerSessionIdErrorDialogTitle",
                    description: "viewerSessionIdErrorDialogDescription",
                    button: "viewerSessionIdErrorDialogOkButton"
                })
            } else if (error.statusCode === 401) {
                redirectToLogin(props)
            } else if (error.statusCode === 403) {
                setLoadingErrorDialog({
                    open: true,
                    title: "viewerForbiddenErrorDialogTitle",
                    description: "viewerForbiddenErrorDialogDescription",
                    button: "viewerForbiddenErrorDialogOkButton"
                })
            } else {
                setLoadingErrorDialog({
                    open: true,
                    title: "viewerServerErrorDialogTitle",
                    description: "viewerServerErrorDialogDescription",
                    button: "viewerServerErrorDialogOkButton"
                })
            }
            setLoading(false)
        }, () => {
            console.log("fullUserSessionStatus", fullUserSessionStatus)
            /** @type {TrackingOptions} */
            const config = fullUserSessionStatus.currentConfig
            let displayStatus
            switch (fullUserSessionStatus.status) {
                case SessionStatus.StatusEnum.active:
                    displayStatus = t("viewerSessionStatusActive")
                    break;
                case SessionStatus.StatusEnum.notactive:
                    displayStatus = t("viewerSessionStatusNotActive")
                    break;
                default:
                    displayStatus = t("viewerSessionStatusError")
            }
            let allowDeactivation = false
            let allowRequestPosition = false
            let trackingInterval = null
            let alertSpeedLowerThanKmh = null
            let shipmentId = null
            let shipFromAddress = null
            let shipToAddress = null
            if (config) {
                /** @type {RolesList} */
                const callerRoles = fullUserSessionStatus.callerRoles 
                const loggedUserIsSender = callerRoles.webSender
                const loggedUserIsRecipient = callerRoles.webReceiver
                allowDeactivation = fullUserSessionStatus.status === SessionStatus.StatusEnum.active 
                    && (loggedUserIsSender 
                        || (loggedUserIsRecipient && config.allowRecipient2Deactivate))
                allowRequestPosition = fullUserSessionStatus.status === SessionStatus.StatusEnum.active 
                    && ((loggedUserIsSender && config.allowSenderOndemand)
                        || (loggedUserIsRecipient && config.allowRecipientOndemand))
                trackingInterval = config.trackingIntervall
                alertSpeedLowerThanKmh = config.alertSpeedLowerThanKmh
                shipmentId = config.freeCustomerID
                shipFromAddress = config.freeFromAddress
                shipToAddress = config.freeToAddress
            }
            setSessionStatus({
                status: displayStatus,
                battery: fullUserSessionStatus.batteryLevel,
                lastUpdate: fullUserSessionStatus.lastUpdate,
                geoPath: fullUserSessionStatus.geoPath,
                allowDeactivation: allowDeactivation,
                allowRequestPosition: allowRequestPosition,
                trackingIntervalMinutes: trackingInterval,
                alertSpeedLowerThanKmh: alertSpeedLowerThanKmh,
                shipmentId: shipmentId,
                shipFromAddress: shipFromAddress,
                shipToAddress: shipToAddress
            })
            setLoading(false)
            console.log("rxRetrieveSession completed!")
        })
    }, [props, sessionId, t])

    useEffect(() => {
        let getUserSubscription = null
        if (requestAuthentication) {
            getUserSubscription = auth.rxGetUser().subscribe(user => {
                if (user) {
                    updateUi()
                } else {
                    redirectToLogin(props)
                }
            })
        } else if (Boolean(sessionId)) {
            updateUi()
        } else {
            setLoadingErrorDialog({
                open: true,
                title: "viewerSessionIdErrorDialogTitle",
                description: "viewerSessionIdErrorDialogDescription",
                button: "viewerSessionIdErrorDialogOkButton"
            })
            setLoading(false)
        }

        return () => {
            if (getUserSubscription) getUserSubscription.unsubscribe()
            if (sessionSubscription) sessionSubscription.unsubscribe()
        }
    }, [props, requestAuthentication, sessionId, updateUi])
    
    const deactivateDialogClick = () => {
        setRequestDeactivateDialogOpen(false)
        updateUi()
    }

    const loadingErrorDialogOnOkClick = () => {
        setLoadingErrorDialog({
            open: false,
            title: null,
            description: null,
            button: null
        })
        navigate(props, RoutePath.root)
    }

    return (
        <Fragment>
            <div className={classes.map}>
                {loading || loadingErrorDialog.open ? null : <SessionMap geoPath={sessionStatus.geoPath}/> }
            </div>
            <Fab className={classes.firstFloatingButton} 
                color="primary"
                onClick={() => setShowStatusBox(!showStatusBox)}>
                <InfoOutlinedIcon style={{ color: "white" }} />
            </Fab>
            {/* Disabled because the APIs do not support the "Request position" feature yet 
            { sessionStatus.allowRequestPosition ?
                <Fab className={classes.secondFloatingButton} 
                    color="primary"
                    onClick={() => setRequestPositionDialogOpen(true)}>
                    <GpsFixedIcon style={{ color: "white" }} />
                </Fab> 
            : null } */}
            { sessionStatus.allowDeactivation ?
                <Fab 
                    /* The className should change based on the presence of the "Request position" button.
                        Since the feature does not exist yet, it is set manually
                    className={sessionStatus.allowRequestPosition 
                        ? classes.thirdFloatingButton 
                        : classes.secondFloatingButton} */
                    className={classes.secondFloatingButton} 
                    color="secondary" 
                    onClick={() => setRequestDeactivateDialogOpen(true)}>
                    <PowerSettingsNewIcon style={{ color: "white" }} />
                </Fab>
            : null }
            <DeactivateDialog
                open={requestDeactivateDialogOpen}
                onOkClick={deactivateDialogClick}
                onCancelClick={() => setRequestDeactivateDialogOpen(false)}
                sessionId={sessionId} />
            <AlertDialog 
                open={requestPositionDialogOpen}
                title={t("viewerRequestPositionDialogTitle")}
                description={t("viewerRequestPositionDialogDescription")}
                okButton={t("viewerRequestPositionDialogOkButton")}
                onOkClick={() => setRequestPositionDialogOpen(false)} />
            <AlertDialog 
                open={loadingErrorDialog.open}
                title={t(loadingErrorDialog.title)}
                description={t(loadingErrorDialog.description)}
                okButton={t(loadingErrorDialog.button)}
                onOkClick={loadingErrorDialogOnOkClick} />
            <LoadingView show={loading} />
            {showStatusBox ? 
                <Paper className={classes.statusBox} elevation={3}>
                    <TrackerItem name={t("viewerSessionStatus")} value={sessionStatus.status}/>
                    <BatteryItem batteryPerc={sessionStatus.battery}/>
                    <TrackerItem 
                        name={t("viewerSessionLastUpdate")} 
                        value={sessionStatus.lastUpdate ? sessionStatus.lastUpdate.toLocaleString() : null}/>
                    <TrackingIntervalItem 
                        name={t("viewerSessionTrackingInterval")}
                        minutes={sessionStatus.trackingIntervalMinutes} 
                        hideIfMissing={true} />
                    <MovementNotificationItem
                        name={t("viewerSessionMovementNotifications")}
                        options={t("activationStep2NotifyTrackerNotMovingOptions")}
                        speed={sessionStatus.alertSpeedLowerThanKmh}
                        hideIfMissing={true} />
                    <TrackerItem name={t("viewerSessionShipmentId")} value={sessionStatus.shipmentId} hideIfMissing={true}/>
                    <TrackerItem name={t("viewerSessionShipFromAddress")} value={sessionStatus.shipFromAddress} hideIfMissing={true}/>
                    <TrackerItem name={t("viewerSessionShipToAddress")} value={sessionStatus.shipToAddress} hideIfMissing={true}/>
                </Paper>
            : null }
        </Fragment>
    )
}
