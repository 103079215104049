import React, { useState, useEffect, useRef } from "react"
import { makeStyles } from "@material-ui/core/styles"
import SessionCard from "./components/SessionCard"
import { navigate, redirectToLogin, RoutePath, smartphoneBps } from "../../Main"
import Utb2bApi from "../../network/UtB2bApi"
import { useTranslation } from "react-i18next"
import AlertDialog from "../../components/AlertDialog"
// eslint-disable-next-line no-unused-vars
import HttpError from "../../network/HttpError"
// eslint-disable-next-line no-unused-vars
import { Subscription, fromEvent } from "rxjs"
import { debounceTime, filter } from "rxjs/operators"
import { CircularProgress, Fab } from "@material-ui/core"
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import ViewListIcon from '@material-ui/icons/ViewList'
import SessionListItem from "./components/SessionListItem"

const useStyles = makeStyles(theme => ({
    root: {
        alignSelf: "center",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    listContainer: {
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'center',
        flexWrap: "nowrap",
        marginTop: 16
    },
    cardsContainer: {
        width: "100vw",
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'center',
        flexWrap: "wrap"
    },
    noSessions: {
        color: "#3f58b8",
        fontSize: "xx-large",
        textAlign: "center",
        marginTop: 50
    },
    loading: {
        height: 50,
        marginTop:10
    },
    changeViewButton: {
        position: "fixed",
        bottom: 20,
        right: 20,
        [smartphoneBps(theme)]: {
            bottom: 10,
            right: 10
        }
    },
}))

/** @type {Subscription} */ let sessionsListSubscription
/** @type {Subscription} */ let scrollLazyLoadingSubscription

const rxScrollLazyLoading = () => fromEvent(window, 'scroll').pipe( // attach scroll listener
    debounceTime(300), // get last event every 300ms because scroll event is easily spammed
    filter(() => // continue if the page bottom is reached (a little before for better UX)
        window.pageYOffset >= document.body.scrollHeight - document.body.clientHeight - 50), 
    filter(() => // do not proceed if retrieveItems() is currently running
        sessionsListSubscription === null || sessionsListSubscription.closed) 
)

/**
 * 
 * @param {import("react-router-dom").RouteComponentProps} props 
 */
export default function SessionsPage(props) {
    const classes = useStyles(props)
    const {t} = useTranslation()
    const [sessionsList, setSessionsList] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingErrorDialog, setLoadingErrorDialog] = useState({
        open: false,
        title: null,
        description: null,
        button: null
    })
    const [listView, setListView] = useState(true)
    const lastRetrievedSessionIdRef = useRef(null)

    const fetchType = props.location.pathname === RoutePath.sessionsSender ? "sender" :
        (props.location.pathname === RoutePath.sessionsRecipient ? "recipient" : null)

    useEffect(() => {
        // calculate how many items to load based on how many cards fit inside the window
        const getItemsToRetrievePerTime = () => listView ? Math.ceil(window.innerHeight / 100)
            : Math.ceil(window.innerWidth / 400 + window.innerHeight / 400)

        const retrieveItems = () => {
            setLoading(true)
            if (sessionsListSubscription) sessionsListSubscription.unsubscribe()
    
            let retrievedItemsCount = 0
            console.log("lastRetrievedSessionIdRef.current", lastRetrievedSessionIdRef.current)
            sessionsListSubscription = Utb2bApi.rxRetrieveSessionsList(fetchType, lastRetrievedSessionIdRef.current, 
                getItemsToRetrievePerTime()).subscribe(sessionsListPart => {
                    console.log("rxRetrieveSessionsList", sessionsListPart)
                    setSessionsList(sessionsList => ([...sessionsList, ...sessionsListPart]))
                    retrievedItemsCount = sessionsListPart ? sessionsListPart.length : 0
                    // Get the last Session ID for pagination purpose
                    lastRetrievedSessionIdRef.current = retrievedItemsCount > 0 
                        ? sessionsListPart[retrievedItemsCount - 1].sessionId : null
                }, (/** @type {HttpError} */ error) => {
                    console.log("rxRetrieveSessionsList error:", error)
                    if (error.statusCode === 401) {
                        redirectToLogin(props)
                    } else if (error.statusCode === 403) {
                        setLoadingErrorDialog({
                            open: true,
                            title: "sessionsForbiddenErrorDialogTitle",
                            description: "sessionsForbiddenErrorDialogDescription",
                            button: "sessionsForbiddenErrorDialogOkButton"
                        }) 
                    }else {
                        setLoadingErrorDialog({
                            open: true,
                            title: "sessionsServerErrorDialogTitle",
                            description: "sessionsServerErrorDialogDescription",
                            button: "sessionsServerErrorDialogOkButton"
                        })
                    }
                    setLoading(false)
                }, () => {
                    // if all the items are retrieved, detach the scroll listener to avoid making useless net requests
                    console.log("Items count ", retrievedItemsCount + "/" + getItemsToRetrievePerTime())
                    if (retrievedItemsCount < getItemsToRetrievePerTime() && scrollLazyLoadingSubscription) {
                        scrollLazyLoadingSubscription.unsubscribe()
                    }
                    setLoading(false)
                    console.log("rxRetrieveSessionsList completed!")
                })
        }

        scrollLazyLoadingSubscription = rxScrollLazyLoading().subscribe(() => {
            console.log("Scrolled to bottom!")
            retrieveItems()
        })
        retrieveItems()

        return () => {
            if (scrollLazyLoadingSubscription) scrollLazyLoadingSubscription.unsubscribe()
            if (sessionsListSubscription) sessionsListSubscription.unsubscribe()
        }
    }, [fetchType, listView, props])

    const handleSessionClick = sessionId => {
        const sessionUrl = RoutePath.viewer + `/${sessionId}`
        navigate(props, sessionUrl, fetchType === 'sender' ? RoutePath.sessionsSender : RoutePath.sessionsRecipient)
    }

    const handleLoadingErrorDialogClick = () => {
        setLoadingErrorDialog({
            open: false,
            title: null,
            description: null,
            button: null
        })
        navigate(props, RoutePath.root)
    }

    const handleChangeViewButton = () => {
        if (listView) {

        } else {
            
        }
        setListView(!listView)
    }

    return (
            <div className={classes.root}>
                <div className={listView ? classes.listContainer : classes.cardsContainer}>
                    { sessionsList && sessionsList.length > 0 ?
                        sessionsList.map(sessionStatus => 
                            listView ?
                                <SessionListItem 
                                    key={sessionStatus.sessionId} 
                                    sessionStatus={sessionStatus} 
                                    onClick={handleSessionClick}
                                    isSender={fetchType === "sender"}/>
                            : 
                                <SessionCard 
                                    key={sessionStatus.sessionId} 
                                    sessionStatus={sessionStatus} 
                                    onClick={handleSessionClick} />
                        )
                    : !loadingErrorDialog.open && !loading ?
                        <div className={classes.noSessions}>
                            {t("sessionsNoItems")}
                        </div>
                    : null
                    }
                </div>
                <div className={classes.loading}>
                    {!loading || <CircularProgress/>}
                </div>
                <Fab className={classes.changeViewButton} 
                    color="primary"
                    onClick={handleChangeViewButton}>
                        { listView ? <ViewModuleIcon style={{ color: "white" }} />
                        : <ViewListIcon style={{ color: "white" }} />}
                    
                </Fab>
                <AlertDialog 
                    open={loadingErrorDialog.open}
                    title={t(loadingErrorDialog.title)}
                    description={t(loadingErrorDialog.description)}
                    okButton={t(loadingErrorDialog.button)}
                    onOkClick={handleLoadingErrorDialogClick} />
            </div>
    )
}