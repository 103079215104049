import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
    statusItem: props => ({
        display: "flex",
        flexDirection: props.flexDirection ? props.flexDirection : "column",
        justifyContent: props.flexDirection === "row" ? "flex-start" : "center",
        alignItems: "center",
        margin: 10
    }),
    statusItemName: props => ({
        color: "#999999",
        fontSize: props.fontSize ? props.fontSize : "small",
        marginLeft: 10,
        marginRight: 10
    }),
    statusItemValue: props => ({
        color: props.grayedOut ? "a1a1a1" : "#3f58b8",
        marginLeft: props.valuePositionOffset ? props.valuePositionOffset + 10 : 10,
        marginRight: 10,
        textAlign: 'center'
    }),
    leftIcon: {
        marginRight: 5
    },
    rightIcon: {
        marginLeft: 5
    }
})

export default function TrackerItem({name, value, hideIfMissing = false, leftIcon = null, 
        rightIcon = null, ...props}) {
    const classes = useStyles(props)
    const {t} = useTranslation()
    
    return value === null && hideIfMissing === true ? null : (
        <div className={classes.statusItem}>
            <div className={classes.statusItemName}>{name}</div>
            <div className={classes.statusItemValue}>
                {leftIcon ? <img className={classes.leftIcon} src={leftIcon} alt="" /> : null}
                {value === null ? t("viewerSessionMissingValue") : value}
                {rightIcon ? <img className={classes.rightIcon} src={rightIcon} alt="" /> : null}
            </div>
        </div>
    )
}