/*
 * UT b2b API
 * Urbantracker business 2 business API
 *
 * OpenAPI spec version: 1.2.0
 * Contact: nospam@stetel.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.18
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The GeoPoint model module.
 * @module model/GeoPoint
 * @version 1.2.0
 */
export class GeoPoint {
  /**
   * Constructs a new <code>GeoPoint</code>.
   * @alias module:model/GeoPoint
   * @class
   * @param id {Number} 
   * @param pointType {module:model/GeoPoint.PointTypeEnum} 
   * @param timeStamp {Date} 
   */
  constructor(id, pointType, timeStamp) {
    this.id = id;
    this.pointType = pointType;
    this.timeStamp = timeStamp;
  }

  /**
   * Constructs a <code>GeoPoint</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/GeoPoint} obj Optional instance to populate.
   * @return {module:model/GeoPoint} The populated <code>GeoPoint</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new GeoPoint();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('pointType'))
        obj.pointType = ApiClient.convertToType(data['pointType'], 'String');
      if (data.hasOwnProperty('geoLat'))
        obj.geoLat = ApiClient.convertToType(data['geoLat'], 'Number');
      if (data.hasOwnProperty('geoLong'))
        obj.geoLong = ApiClient.convertToType(data['geoLong'], 'Number');
      if (data.hasOwnProperty('geoHeight'))
        obj.geoHeight = ApiClient.convertToType(data['geoHeight'], 'Number');
      if (data.hasOwnProperty('accuracyMeters'))
        obj.accuracyMeters = ApiClient.convertToType(data['accuracyMeters'], 'Number');
      if (data.hasOwnProperty('timeStamp'))
        obj.timeStamp = ApiClient.convertToType(data['timeStamp'], 'Date');
      if (data.hasOwnProperty('label'))
        obj.label = ApiClient.convertToType(data['label'], 'String');
      if (data.hasOwnProperty('addressLine1'))
        obj.addressLine1 = ApiClient.convertToType(data['addressLine1'], 'String');
      if (data.hasOwnProperty('addressLine2'))
        obj.addressLine2 = ApiClient.convertToType(data['addressLine2'], 'String');
      if (data.hasOwnProperty('addressLine3'))
        obj.addressLine3 = ApiClient.convertToType(data['addressLine3'], 'String');
      if (data.hasOwnProperty('addressCountry'))
        obj.addressCountry = ApiClient.convertToType(data['addressCountry'], 'String');
      if (data.hasOwnProperty('batteryLevel'))
        obj.batteryLevel = ApiClient.convertToType(data['batteryLevel'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
GeoPoint.prototype.id = undefined;

/**
 * Allowed values for the <code>pointType</code> property.
 * @enum {String}
 * @readonly
 */
GeoPoint.PointTypeEnum = {
  /**
   * value: "unknown"
   * @const
   */
  unknown: "unknown",

  /**
   * value: "localization"
   * @const
   */
  localization: "localization",

  /**
   * value: "tracking"
   * @const
   */
  tracking: "tracking",

  /**
   * value: "alarm"
   * @const
   */
  alarm: "alarm"
};

/**
 * @member {module:model/GeoPoint.PointTypeEnum} pointType
 */
GeoPoint.prototype.pointType = undefined;

/**
 * @member {Number} geoLat
 */
GeoPoint.prototype.geoLat = undefined;

/**
 * @member {Number} geoLong
 */
GeoPoint.prototype.geoLong = undefined;

/**
 * @member {Number} geoHeight
 */
GeoPoint.prototype.geoHeight = undefined;

/**
 * @member {Number} accuracyMeters
 */
GeoPoint.prototype.accuracyMeters = undefined;

/**
 * @member {Date} timeStamp
 */
GeoPoint.prototype.timeStamp = undefined;

/**
 * @member {String} label
 */
GeoPoint.prototype.label = undefined;

/**
 * @member {String} addressLine1
 */
GeoPoint.prototype.addressLine1 = undefined;

/**
 * @member {String} addressLine2
 */
GeoPoint.prototype.addressLine2 = undefined;

/**
 * @member {String} addressLine3
 */
GeoPoint.prototype.addressLine3 = undefined;

/**
 * @member {String} addressCountry
 */
GeoPoint.prototype.addressCountry = undefined;

/**
 * @member {Number} batteryLevel
 */
GeoPoint.prototype.batteryLevel = undefined;


