import React from 'react'
import { makeStyles} from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { navigate, RoutePath, smartphoneBps, smallDesktopBps } from '../../Main'
import Feature from './components/Feature'
import SolutionFirstCard from './components/SolutionFirstCard'
import SolutionSecondCard from './components/SolutionSecondCard'
import SolutionThirdCard from './components/SolutionThirdCard'
import DescriptionBackground from './components/discover-background.jpg'
import SolutionsBackground from './components/pricelist-background.jpg'
import FirstFeatureImage from './components/indoor-icon.png'
import SecondFeatureImage from './components/everywhere-icon.png'
import ThirdFeatureImage from './components/made-in-italy-icon.png'
import Poster from './components/jfvideo-placeholder.png'
import GuideImage from './components/dwnlguide-bg.png'
import {Helmet} from "react-helmet"


const useStyles = makeStyles(theme => ({
    root: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: "100%"
    },
    descriptionBox: {
        width: "100%",
        paddingTop: 84,
        paddingBottom: 48,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        backgroundColor: '#19274e',
        backgroundImage: `url(${DescriptionBackground})`,
        backgroundSize:"cover",
        [smartphoneBps(theme)]: {
            paddingTop: 54,
            alignItems: 'center'
        }
    },
    descriptionInfo: {
        width: "80vw",
        alignSelf: 'center',
        color: '#ffffff',
        textAlign: 'center',
        font: 'normal normal 300 32px/43px Roboto',
        fontSize: 32,
        marginBottom: 48,
        [smartphoneBps(theme)]: {
            width: "95%",
            font: 'normal normal 300 28px/37px Roboto',
            fontSize: 28,
        }
    },

    ctactions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        [smartphoneBps(theme)]: {
            paddingTop: 24,
            paddingBottom: 48,
            flexDirection: 'column-reverse',
            justifyContent: 'flex-start',


        }
    },
    
    boxGuideAndLink: {
        backgroundColor: 'none',
        display: 'inline-block',
        margin: '16px 0px 0px 0px',
        padding: '0px',
        height: 64,
        opacity: 1,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        [smartphoneBps(theme)]: { 
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center', 
            marginRight: 0, 
        } 
    },

    descriptionUserGuide: {
        font: 'normal normal 500 20px/26px Roboto',
        fontSize: 20,
        fontWeight: 500,
        color: '#FDC600',
        backgroundImage: `url(${GuideImage})`,
	    background: 'transparent left top no-repeat',
        textDecoration: 'none',
        display: 'inline-block',
        textAlign:"center",
        marginRight: 16, 
        paddingTop: 18,
        paddingRight: 24,
        paddingBottom: 18,
        paddingLeft: 74,
        height: 64,
        borderRadius: 16,
        border: '1px solid #FDC600',
        boxSizing: 'border-box',
        cursor: 'pointer',
        [smartphoneBps(theme)]: { 
            marginRight: 0, 
        } 
    },

    descriptionTry: {
        backgroundColor: 'none',
        display: 'inline-block',
        margin: '16px 0px 0px 0px',
        padding: 0,
        height: 64,
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [smartphoneBps(theme)]: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            marginTop: 16,
            marginRight:0,
        }
    },

    descriptionTryLink: {
        font: 'normal normal 500 20px/26px Roboto',
        fontSize: 20,
        fontWeight: 500,
        color: '#0B1434',
        backgroundColor: '#fdc600',
        textDecoration: 'none',
        display: 'inline-block',
        margin: 0,
        marginRight: 48,
        paddingTop: 18,
        paddingRight: 24,
        paddingBottom: 18,
        paddingLeft: 24,
        height: 64,
        borderRadius: 16,
        border: "1px solid #FFFFFF",
        boxSizing: 'border-box',
        cursor: 'pointer',
        [smartphoneBps(theme)]: {
            marginRight:0,
        }
    },

    module: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'e7e7e7',
        [smartphoneBps(theme)]: {
            flexDirection: 'column',
        }
     },

    jfvideoWrapper: {
        width: '50%',
        paddingTop: 32,
        paddingBottom: 32,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        [smartphoneBps(theme)]: {
            paddingTop: 16,
            paddingBottom: 16,
            width: '100%',
        } 
    },

    jfvideo:{
        width:  280,
        height: 280,
        [smartphoneBps(theme)]: {
            width: '100%',
            height: 'auto',
        } 
    },

    slogan :{
         width: "50%",
         font: 'normal normal 300 22px/33px Roboto',
         fontSize: 22,
         fontWeight: 300,
         color: '#707070',
         padding: '0px 32px',
         [smartphoneBps(theme)]: {
             paddingTop: 16,
             paddingBottom: 16,
             paddingLeft:0,
             paddingRight:0,
             width: "80vw",
         } 
     },

    featuresBox: {
        width: "100%",
        paddingTop: 56,
        paddingBottom: 52,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        background: '#fff',
        [smartphoneBps(theme)]: {
            paddingTop: 24,
            paddingBottom: 48,
            flexDirection: 'column',
            justifyContent: 'flex-start'
        }
    },

    solutionsBox: {
        width: "100%",
        paddingTop: 48,
        paddingBottom: 48,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        backgroundColor: '#19274e',
        backgroundImage: `url(${SolutionsBackground})`
    },
    solutionsTitle: {
        color: '#fff',
        font: "normal normal 300 32px/38px Roboto",
        textAlign: 'center'
    },
    solutionsCardsBox: {
        width: "100%",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        [smallDesktopBps(theme)]: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center'
        }
    },
    solutionsPriceInfo: {
        marginTop: 56,
        color: '#f5f5f5',
        font: "normal normal normal 16px/19px Roboto"
    },

    reasonsWrapper: {
        width:"100%",
        background: '#ffffff', 
        padding: 16,
        boxSizing: "border-box",
        opacity: 1
    },

    reasonsTitle: {
        font: "normal normal 400 32px/46px Roboto",
        color: '#576dc2',
        margin: "0 auto",
        marginTop: 28,
        marginBottom: 32,
        boxSizing: "border-box",
        textAlign: "center"
    },

    reasons: {
        
        maxWidth: 1112,
        margin: "0 auto",
        marginBottom: 46,
        padding: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "row",
        boxSizing: "border-box",
        [smallDesktopBps(theme)]: {
            justifyContent: "flex-start",
	        flexDirection: "column",
        }
    },

    divReason: {
        padding: 16,
        color: '#707070',
        textAlign: "center",
        flexBasis: "100%"
    },

    nr: {
        padding: "0 0 0 0",
        font: "normal normal bold 26px/0px Roboto",
        width: 40,
        height: 40,
        margin: "0 auto",
        borderRadius: 20,
        display: "inline-block",
        overflow: "hidden",
        backgroundColor: '#e7e7e7'
        
    },

    nrP: {
        marginTop: 20,
        textAlign: "center"   
    },
    
    nrSpan: {
        color: "#E08900",
        margin: 0
    }
}))

export default function DiscoverPage(props) {
    const classes = useStyles(props)
    const { t } = useTranslation()

    return (          
            <div className={classes.root}>
                <Helmet>
                    <meta name="robots" content="index,follow"/>
                    <meta name="description" content="JustFollow.it allows you to track parcels and shipments worldwide, it also works in indoor environments. Simply activate our tracker device and insert it in the package to follow its progress on a real time map."/>
                    <title>JustFollow.it: parcel tracking both indoor and outdoor.</title>
                </Helmet>
                <div className={classes.descriptionBox}>
                    <div className={classes.descriptionInfo} dangerouslySetInnerHTML={{__html: t("discoverDescriptionInfo")}} />
                        <div className={classes.ctactions}>
                            <div className={classes.boxGuideAndLink} > 
                                <span className={classes.descriptionUserGuide} 
                                    onClick={() => navigate(props, RoutePath.help)}> 
                                    {t("discoverGuideDownload")}
                                </span>
                            </div>
                            <div className={classes.descriptionTry}> 
                                <span className={classes.descriptionTryLink} 
                                    onClick={() => navigate(props, RoutePath.buyTrial)}>
                                    {t("discoverDescriptionTryLink")}
                                </span>
                            </div>
                    </div>
                </div>
                <div className={classes.module}>
                    <div className={classes.jfvideoWrapper}>
                        <video className={classes.jfvideo} controls src="/images/jfvideo.mp4" type="video/mp4" poster= {Poster} />
                    </div>
                    <div className= {classes.slogan}>{t("discoverVideoSlogan")}</div>          
                </div>

                <div className={classes.featuresBox}>
                        <Feature image={FirstFeatureImage} text={t("discoverFeaturesFirstText")} textAlt={t("discoverFeaturesFirstAltText")}/>
                        <Feature image={SecondFeatureImage} text={t("discoverFeaturesSecondText")} textAlt={t("discoverFeaturesSecondAltText")} />
                        <Feature image={ThirdFeatureImage} text={t("discoverFeaturesThirdText")} textAlt={t("discoverFeaturesThirdAltText")} />
                    </div>
                <div className={classes.solutionsBox}>
                        <div className={classes.solutionsTitle}>{t("discoverSolutionsTitle")}</div>
                        <div className={classes.solutionsCardsBox}>
                            <SolutionFirstCard {...props} />
                            <SolutionSecondCard {...props} />
                            <SolutionThirdCard {...props} />
                    </div>
                    <div className={classes.solutionsPriceInfo}>{t("discoverSolutionsPriceInfo")}</div>
                </div>
                <div className={classes.reasonsWrapper}>
                    
                        <h2 className={classes.reasonsTitle} >{t("discoverFourReasonTitle")}</h2> 
                
                    <div className={classes.reasons}>
                        <div className={classes.divReason}>
			                <div className={classes.nr}><p className={classes.nrP}><span className={classes.nrSpan}>1</span>.</p></div>
                            <h3>{t("discoverFirstReasonTitle")}</h3>
			                <p className={classes.nrP}>{t("discoverFistReasonBody")}</p>            
		                </div>

                        <div className={classes.divReason}>
			                <div className={classes.nr}><p className={classes.nrP}><span className={classes.nrSpan}>2</span>.</p></div>
                            <h3>{t("discoverSecondReasonTitle")}</h3>
			                <p className={classes.nrP}>{t("discoverSecondReasonBody")}</p>            
		                </div>

                        <div className={classes.divReason}>
			                <div className={classes.nr}><p className={classes.nrP}><span className={classes.nrSpan}>3</span>.</p></div>
                            <h3>{t("discoverThirdReasonTitle")}</h3>
			                <p className={classes.nrP}>{t("discoverThirdReasonBody")}</p>             
		                </div>

                        <div className={classes.divReason}>
			                <div className={classes.nr}><p className={classes.nrP}><span className={classes.nrSpan}>4</span>.</p></div>
                            <h3>{t("discoverFourthReasonTitle")}</h3>
			                <p className={classes.nrP}>{t("discoverFourthReasonBody")}</p>            
		                </div>
                    </div>

                </div>
            </div>
    )
}