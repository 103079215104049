/*
 * UT b2b API
 * Urbantracker business 2 business API
 *
 * OpenAPI spec version: 1.2.0
 * Contact: nospam@stetel.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.18
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {GeoPoint} from './GeoPoint';
import {RolesList} from './RolesList';
import {TrackingOptions} from './TrackingOptions';

/**
 * The SessionStatus model module.
 * @module model/SessionStatus
 * @version 1.2.0
 */
export class SessionStatus {
  /**
   * Constructs a new <code>SessionStatus</code>.
   * @alias module:model/SessionStatus
   * @class
   * @param sessionId {String} The sessionId to verify
   * @param status {module:model/SessionStatus.StatusEnum} the current status
   * @param lastUpdate {Date} 
   * @param trackingMode {module:model/SessionStatus.TrackingModeEnum} 
   * @param callerRoles {module:model/RolesList} 
   */
  constructor(sessionId, status, lastUpdate, trackingMode, callerRoles) {
    this.sessionId = sessionId;
    this.status = status;
    this.lastUpdate = lastUpdate;
    this.trackingMode = trackingMode;
    this.callerRoles = callerRoles;
  }

  /**
   * Constructs a <code>SessionStatus</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SessionStatus} obj Optional instance to populate.
   * @return {module:model/SessionStatus} The populated <code>SessionStatus</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SessionStatus();
      if (data.hasOwnProperty('sessionId'))
        obj.sessionId = ApiClient.convertToType(data['sessionId'], 'String');
      if (data.hasOwnProperty('status'))
        obj.status = ApiClient.convertToType(data['status'], 'String');
      if (data.hasOwnProperty('statusMessage'))
        obj.statusMessage = ApiClient.convertToType(data['statusMessage'], 'String');
      if (data.hasOwnProperty('batteryLevel'))
        obj.batteryLevel = ApiClient.convertToType(data['batteryLevel'], 'Number');
      if (data.hasOwnProperty('lastUpdate'))
        obj.lastUpdate = ApiClient.convertToType(data['lastUpdate'], 'Date');
      if (data.hasOwnProperty('trackingMode'))
        obj.trackingMode = ApiClient.convertToType(data['trackingMode'], 'String');
      if (data.hasOwnProperty('callerRoles'))
        obj.callerRoles = RolesList.constructFromObject(data['callerRoles']);
      if (data.hasOwnProperty('currentConfig'))
        obj.currentConfig = TrackingOptions.constructFromObject(data['currentConfig']);
      if (data.hasOwnProperty('geoPath'))
        obj.geoPath = ApiClient.convertToType(data['geoPath'], [GeoPoint]);
    }
    return obj;
  }
}

/**
 * The sessionId to verify
 * @member {String} sessionId
 */
SessionStatus.prototype.sessionId = undefined;

/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
SessionStatus.StatusEnum = {
  /**
   * value: "notactive"
   * @const
   */
  notactive: "notactive",

  /**
   * value: "activating"
   * @const
   */
  activating: "activating",

  /**
   * value: "active"
   * @const
   */
  active: "active",

  /**
   * value: "deactivating"
   * @const
   */
  deactivating: "deactivating",

  /**
   * value: "deactivated"
   * @const
   */
  deactivated: "deactivated",

  /**
   * value: "error"
   * @const
   */
  error: "error"
};

/**
 * the current status
 * @member {module:model/SessionStatus.StatusEnum} status
 */
SessionStatus.prototype.status = undefined;

/**
 * message giving more info about the status. Human readable
 * @member {String} statusMessage
 */
SessionStatus.prototype.statusMessage = undefined;

/**
 * @member {Number} batteryLevel
 */
SessionStatus.prototype.batteryLevel = undefined;

/**
 * @member {Date} lastUpdate
 */
SessionStatus.prototype.lastUpdate = undefined;

/**
 * Allowed values for the <code>trackingMode</code> property.
 * @enum {String}
 * @readonly
 */
SessionStatus.TrackingModeEnum = {
  /**
   * value: "periodic"
   * @const
   */
  periodic: "periodic",

  /**
   * value: "alarm"
   * @const
   */
  alarm: "alarm",

  /**
   * value: "ready"
   * @const
   */
  ready: "ready",

  /**
   * value: "off"
   * @const
   */
  off: "off",

  /**
   * value: "flightmode"
   * @const
   */
  flightmode: "flightmode"
};

/**
 * @member {module:model/SessionStatus.TrackingModeEnum} trackingMode
 */
SessionStatus.prototype.trackingMode = undefined;

/**
 * @member {module:model/RolesList} callerRoles
 */
SessionStatus.prototype.callerRoles = undefined;

/**
 * @member {module:model/TrackingOptions} currentConfig
 */
SessionStatus.prototype.currentConfig = undefined;

/**
 * @member {Array.<module:model/GeoPoint>} geoPath
 */
SessionStatus.prototype.geoPath = undefined;


