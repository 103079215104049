import React from 'react'
import { makeStyles} from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import ThanksImage from './components/thanks.png'
import { RoutePath } from "../../Main"
import {Helmet} from "react-helmet"


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    image: {
        width: 166,
        height: 166,
        marginTop: 32
    },
    message: {
        marginTop: 32,
        marginBottom: 100,
        font: 'normal normal 300 32px/38px Roboto',
        color: "#666666",
        textAlign: 'center'
    }
}))

export default function BuyConfirmationPage(props) {
    const classes = useStyles(props)
    const { t } = useTranslation()


    const isOneOff = props.location.pathname === RoutePath.buyOneOffConfirmation
    const isGold = props.location.pathname === RoutePath.buyGoldConfirmation
    const isTrial = props.location.pathname === RoutePath.buyTrialConfirmation
    const isEmailTrial = props.location.pathname === RoutePath.buyEmailTrialConfirmation
    
    
    const message = isOneOff ? t("buyConfirmationMessageOneOff") : isGold ? t("buyConfirmationMessageGold") : isTrial ? t("buyConfirmationMessageTrial") : t("buyConfirmationMessageEmailTrial")

    return (
        <div className={classes.root}>
            <Helmet>
                <meta name="robots" content="noindex"/>
                <title>JustFollow.it: Thank you</title>
            </Helmet>
            <img className={classes.image} src={ThanksImage} alt="" />
            <div className={classes.message}>{message}</div>
        </div>
    )
}