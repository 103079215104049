import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Button, LinearProgress } from "@material-ui/core"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { useState } from 'react'
// eslint-disable-next-line no-unused-vars
import { Subscription } from 'rxjs'
import TrackerIdInput from '../../../components/TrackerIdInput'
import Utb2bApi from '../../../network/UtB2bApi'
import { rxCameraAvailable } from '../../../Main'

const useStyles = makeStyles(theme => ({
    dataOverlay: {
        margin: "10px 0px"
    },
    paraOverlay: {
        color: "#666",
        fontSize: "smaller"
    },
    valueOverlay: {
        wordWrap: "break-word"
    },
    resultBox: {
        display: 'flex',
        flexDirection: 'column'
    },
    resultMessage: {
        height: 40,
        fontWeight: 'bold',
        color: 'rgba(63,88,184,1)',
        textAlign: 'center'
    },
    errorResultMessage: {
        height: 40,
        fontWeight: 'bold',
        color: 'rgba(221,0,0,1)',
        textAlign: 'center'
    },
    loadingProgress: {
        visibility: 'visible'
        
    },
    hiddenLoadingProgress: {
        visibility: 'hidden'
    },
    notice: {
        fontStyle: "italic",
        paddingTop: 8
    }
}))

/** @type {Subscription} */ let cameraAvailableSubscription
/** @type {Subscription} */ let deactivateSubscription

export default function DeactivateDialog(props) {
    const dialogModeType = {
        confirm: 1,
        loading: 2,
        error: 3,
        completed: 4
    }
    const classes = useStyles(props)
    const theme = useTheme()
    const {t} = useTranslation()
    const fullScreen = useMediaQuery(theme.breakpoints.down(350))
    const [dialogMode, setDialogMode] = useState(dialogModeType.confirm)
    const [trackerId, setTrackerId] = React.useState(null)
    const [cameraAvailable, setCameraAvailable] = React.useState(false)

    useEffect(() => {
        cameraAvailableSubscription = rxCameraAvailable().subscribe(available => setCameraAvailable(available))
        return () => {
            if (cameraAvailableSubscription) cameraAvailableSubscription.unsubscribe()
            if (deactivateSubscription) deactivateSubscription.unsubscribe()
        }
    }, [])

    useEffect(() => setTrackerId(props.trackerId), [props.trackerId])

    const handleCancelButton = () => {
        if (deactivateSubscription) deactivateSubscription.unsubscribe()
        setTrackerId(null)
        props.onCancelClick()
    }

    const handleOkButton = () => {
        switch (dialogMode) {
            default: // dialogModeType.confirm && dialogModeType.error
                setDialogMode(dialogModeType.loading)
                deactivateSubscription = Utb2bApi.rxDeactivateTracker(trackerId, props.sessionId)
                    .subscribe((activationStatus) => {
                        console.log("Deactivation status ", activationStatus)
                        setDialogMode(dialogModeType.completed)
                    }, (error) => {
                        console.log("Deactivation error ", error)
                        setDialogMode(dialogModeType.error)
                    })
                break
            case dialogModeType.loading:
                // do nothing (button should be disabled in this mode)
                break
            case dialogModeType.completed:
                props.onOkClick()
                break
        }
    }

    const getOkButtonText = () => {
        switch (dialogMode) {
            default: // dialogModeType.confirm && dialogModeType.loading
                return t("viewerDeactivateDialogConfirmButton")
            case dialogModeType.error:
                return t("viewerDeactivateDialogErrorButton")
            case dialogModeType.completed:
                return t("viewerDeactivateDialogCloseButton")
        }
    }

    const getResultMessageText = () => {
        switch (dialogMode) {
            default: // dialogModeType.confirm
                return null
            case dialogModeType.loading:
                return t("viewerDeactivateDialogWaitMessage")
            case dialogModeType.error:
                return t("viewerDeactivateDialogErrorMessage")
            case dialogModeType.completed:
                return t("viewerDeactivateDialogCompletedMessage")
        }
    }

    /**
     * @param {String} newTrackerId
     */
    const handleNewTrackerId = newTrackerId => {
        setTrackerId(newTrackerId)
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.open || false}>
            
            <DialogTitle>{t("viewerDeactivateDialogTitle")}</DialogTitle>
            <DialogContent>
                <div>{t(cameraAvailable ? "viewerDeactivateDialogDescription" : "viewerDeactivateDialogDescriptionNoCamera")}</div>
                <TrackerIdInput onNewTrackerId={handleNewTrackerId} disabled={dialogMode === dialogModeType.loading} />
                <div className={classes.notice}>
                    {t("viewerDeactivateDialogNotice")}
                </div>
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={handleCancelButton} 
                    color="primary"
                    disabled={dialogMode === dialogModeType.completed || dialogMode === dialogModeType.loading}>
                    {t("viewerDeactivateDialogCancelButton")}
                </Button>
                <Button 
                    onClick={handleOkButton} 
                    color="primary"
                    disabled={!Boolean(trackerId) || dialogMode === dialogModeType.loading}>
                    {getOkButtonText()}
                </Button>
            </DialogActions>
            <div className={classes.resultBox}>
                <div className={dialogMode === dialogModeType.error ? classes.errorResultMessage : classes.resultMessage}>
                    {getResultMessageText()}
                </div>
                <LinearProgress className={dialogMode === dialogModeType.loading ? classes.loadingProgress : classes.hiddenLoadingProgress } />
            </div>
        </Dialog>
    )
}
