import React, { useState, useEffect } from "react"
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import Header from "./components/header/Header"
import GenericPage from "./pages/genericPage/GenericPage"
import ActivationPage from "./pages/activationPage/ActivationPage"
import ViewerPage from "./pages/viewerPage/ViewerPage"
import LoginPage, { storageRedirectAfterLoginUrl } from "./pages/loginPage/LoginPage.js"
import packageJson from "../package.json"
import SessionsPage from "./pages/sessionsPage/SessionsPage.js"
import { BrowserQRCodeReader } from "@zxing/library"
// eslint-disable-next-line no-unused-vars
import { from, Observable, Subscription } from "rxjs"
import { flatMap, map } from "rxjs/operators"
import { useTranslation } from "react-i18next"
import { makeStyles } from '@material-ui/core/styles'
import AlertDialog from "./components/AlertDialog.js"
import Button from "@material-ui/core/Button"
import Footer from "./components/footer/Footer.js"
import CoveragePage from "./pages/coveragePage/CoveragePage.js"
import HelpPage from "./pages/helpPage/HelpPage.js"
import auth from "./components/Auth"
import TrialLoginPage from "./pages/trialLoginPage/TrialLoginPage.js"
import utb2bApi from './network/UtB2bApi'
// eslint-disable-next-line no-unused-vars
import HttpError from "./network/HttpError"
import LoadingView from "./components/LoadingView"
import LegalPage from "./pages/legalPage/legalPage.js"
import DiscoverPage from "./pages/discoverPage/DiscoverPage"
import BuyPage from "./pages/buyPage/BuyPage"
import BuyConfirmationPage from "./pages/buyConfirmationPage/BuyConfirmationPage"
import VideoPage from "./pages/videoPage/VideoPage"

const useStyles = makeStyles(theme => ({
    page: {
        width: '100%',
        alignSelf: 'center',
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        minHeight: '100%',
        whiteSpace: "pre-line"
    },
    header: {
        flexShrink: 0,
        width: '100%'
    },
    content: {
        width: '100%',
        flexGrow: 1,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
    },
    footer: {
        width: '100%',
        flexShrink: 0,
        alignSelf: 'center',
        display: "flex",
        flexDirection: "column",
        alignItems: 'center'
    },
    simpleWarning: {
        paddingLeft: 20,
        paddingRight: 20,
        color: "#3f58b8",
        textAlign: "center"
    }
}))

export const RoutePath = {
    root: "/",
    activation: "/activation",
    viewer: "/viewer",
    login: "/login",
    trialLogin: "/login/trial",
    sessionsSender: "/sessions/sender",
    sessionsRecipient: "/sessions/recipient",
    help: "/help",
    coverage: "/coverage",
    terms: "/terms",
    privacy: "/privacy",
    discover: "/discover",
    buyTrial: "/buy/trial",
    buyTrialConfirmation: "/buy/trial/confirmation",
    buyOneOff: "/buy/oneoff",
    buyOneOffConfirmation: "/buy/oneoff/confirmation",
    buyGold: "/buy/gold",
    buyGoldConfirmation: "/buy/gold/confirmation",
    buyTrialByEmail: "/buy/trial-kit",
    buyEmailTrialConfirmation: "/buy/trial-kit/confirmation",
    video: "/video"
    
}

export const UserType = {
    /**
     * value: "none"
     * @const
     */
    none: "none",
    /**
     * value: "any"
     * @const
     */
    any: "any",
    /**
     * value: "sender"
     * @const
     */
    sender: "sender",
    /**
     * value: "recipient"
     * @const
     */
    recipient: "recipient",
    /**
     * value: "viewer"
     * @const
     */
    viewer: "viewer"
}

/**
 * Navigate to URL.
 * 
 * @param {import("react-router-dom").RouteComponentProps} props 
 * @param {String} url Url to navigate to
 * @param {String} backUrl Url to navigate to when pressing the page header's back button. 
 *                      If missing, it will navigate to the specified fromPath in the site Router
 */
export const navigate = (props, url, backUrl = null) => {
    console.log(`Navigating to ${url} (back URL: ${backUrl})`)
    const searchPos = url.indexOf("?")
    const pathname = searchPos > 0 ? url.substring(0, searchPos) : url
    const search = searchPos > 0 ? url.substring(searchPos) : null
    props.history.push({
        pathname: pathname,
        search: search,
        state: { from: backUrl }
    })
}

/**
 * 
 * @param {import("react-router-dom").RouteComponentProps} props 
 */
export const redirectToLogin = props => {
    const goToLogin = () => {
        const comingFromUrl = props.location.pathname + props.location.search
        console.log("redirectToLogin, saving comingFromUrl", comingFromUrl)
        sessionStorage.setItem(storageRedirectAfterLoginUrl, comingFromUrl)
        navigate(props, RoutePath.login)
    }
    auth.rxLogout().subscribe(() => {
        goToLogin()
    }, error => {
        goToLogin()
        console.log(`Error during logout: ${error}`)
    })
}

/**
 * Get the theme-breakpoints for adding specific rules to CSS on smaller screens.
 * 
 * To be used with the CCS Json in makeStyles or to be passed to useMediaQuery
 * 
 * @param {*} theme Theme passed to makeStyles or retrieved with useTheme
 */
export const smartphoneBps = theme => theme.breakpoints.down('xs')

/**
 * Get the theme-breakpoints for adding specific rules to CSS on smaller screens.
 * 
 * To be used with the CCS Json in makeStyles or to be passed to useMediaQuery
 * 
 * @param {*} theme Theme passed to makeStyles or retrieved with useTheme
 */
export const smallDesktopBps = theme => theme.breakpoints.down('sm')

/**
 * Check if the camera is available in the computer/device
 * 
 * @returns {Observable<Boolean>}
 */
export const rxCameraAvailable = () => from(new BrowserQRCodeReader().listVideoInputDevices()).pipe(
    map(videoInputDevices => videoInputDevices ? videoInputDevices.length > 0 : false)
)

/** @type {Subscription} */ let checkIdTokenSubscription

export default function Main(props) {
    console.log(`Urban Tracker for Business, version ${packageJson.version}`)
    const { t } = useTranslation()
    const classes = useStyles(props)
    const [userError, setUserError] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (auth.getUser()) {
            if (auth.getUser().hasAtLeastOneType()) {
                console.log(`Logged in as ${auth.getUser().email || auth.getUser().username} [${auth.getUser().typesAsArray()}]`)
            } else {
                console.log(`User not authorized [${auth.getUser().username}]`)
            }
        } else {
            console.log("Not logged in")
        }
    })

    useEffect(() => {
        // checks if the user's token is expired when entering the main/activation page for the first time
        if (auth.getUser() !== null &&
            (window.location.pathname === RoutePath.root ||
                window.location.pathname === RoutePath.activation)) {
            setLoading(true)
            checkIdTokenSubscription = auth.rxGetUser().pipe(
                flatMap(user => utb2bApi.rxCheckUserAccess(user.idToken))
            ).subscribe(() => {
                console.log("User's ID Token still valid")
                setLoading(false)
            }, (/** @type {HttpError} */ error) => {
                if (error.statusCode === 401) {
                    console.log("User's ID Token is expired, need login")
                    // using direct JS method instead of navigate, because of missing router component
                    window.location.href = RoutePath.login
                } else {
                    console.log(`Error while checking id token: ${error}`)
                    setUserError(true)
                }
            })
        }

        return () => {
            if (checkIdTokenSubscription) checkIdTokenSubscription.unsubscribe()
        }
    }, [])

    /**
     * @param {import("react-router-dom").RouteComponentProps} routerProps
     */
    const navigateBack = routerProps => {
        const comingFrom = routerProps.location.state ? routerProps.location.state.from : null
        if (Boolean(comingFrom)) {
            console.log("Navigating back (custom): ", comingFrom)
            routerProps.history.push(comingFrom)
        } else {
            console.log("Navigating back (standard): ", routerProps.fromPath)
            routerProps.history.push(routerProps.fromPath)
        }
    }

    const logout = () => {
        setLoading(true)
        auth.rxLogout().subscribe(
            () => window.location.reload()
            , error => {
                console.log(`Error during logout: ${error}`)
                setUserError(true)
                setLoading(false)
            })
    }

    const Page = ({ component: Component, ...props }) => (
        <div className={classes.page}>
            <div className={classes.header}>
                <Header {...props}
                    fromPath={props.fromPath}
                    handleNavigationClick={routerProps => navigateBack(routerProps)}
                    handleLogoutClick={logout}
                    user={auth.getUser()}
                    showUserBox={props.showUserBox ?? false} />
            </div>
            <div className={classes.content}>
                <Component {...props}
                    user={auth.getUser()} />
            </div>
            { (props.showFooter ?? true) ?
                <div className={classes.footer}>
                    <Footer {...props} />
                </div>
                : null}
            <LoadingView show={loading} />
        </div>
    )

    const UnauthorizedPage = () => (
        <div className={classes.simpleWarning}>
            <h1>{t("mainUnauthorizedTitle")}</h1>
            <p>{t("mainUnauthorizedDescription")}</p>
        </div>
    )

    const NotFoundPage = props => (
        <div className={classes.simpleWarning}>
            <h1>{t("mainNotFoundTitle")}</h1>
            <p>{t("mainNotFoundDescription")}</p>
            <p><Button color="primary" onClick={() => navigate(props, RoutePath.root)}>{t("mainNotFoundGoToLink")}</Button></p>
        </div>
    )

    const SmartRoute = ({ component: Component, ...rest }) =>
        <Route {...rest} render={props => {
            window.scrollTo(0, 0) // reset scroll position when loading a new page
            if (userError) {
                return <AlertDialog
                    open={userError}
                    title={t("mainUserErrorDialogTitle")}
                    description={t("mainUserErrorDialogDescription")}
                    okButton={t("mainUserErrorDialogOkButton")}
                    onOkClick={() => window.location.reload()} />
            } else if ((rest.path === RoutePath.login || rest.path === RoutePath.trialLogin) && auth.isUserLoaded()) {
                console.log("Login page requested, but already logged in")
                return <Redirect to={{ pathname: RoutePath.root, state: { from: props.location } }} />
            } else if (isUserAllowed(rest.allowedUserType)) {
                return <Page component={Component} {...rest} {...props} />
            } else if (auth.isUserLoaded()) {
                return <Page component={UnauthorizedPage} {...rest} {...props} />
            } else {
                return <Redirect to={{ pathname: RoutePath.login, state: { from: props.location } }} />
            }
        }} />

    /**
     * Check if the current loaded user has access to the requested page.
     * The page should provide the allowed user types, if missing it is consider
     * as accessible by everyone.
     * 
     * @param {String | String[]} allowedUserType A single UserType supported by the 
     *  page (can be "any" and "none") or an array of specific user types (cannot contain "any" or "none" types)
     */
    const isUserAllowed = (allowedUserType = UserType.none) => {
        const userTypes = auth.getUser() ? auth.getUser().typesAsArray() : []
        // if allowedUserType is an array, it allows multiple types of users
        if (Array.isArray(allowedUserType)) {
            for (const allowed of allowedUserType) {
                // check if the user has at least one of the required type 
                if (userTypes.indexOf(allowed) >= 0) {
                    return true
                }
            }
            return false
        } else {
            // return true if:
            // - page not requiring a specific type
            // - page requiring at least one type, which one doesn't matter
            // - the user as the required type
            return allowedUserType === UserType.none || // not requiring a specific type
                (allowedUserType === UserType.any && userTypes.length > 0) ||
                userTypes.indexOf(allowedUserType) >= 0
        }
    }

    return (
        <Router>
            <Switch>
                <SmartRoute
                    path={RoutePath.activation}
                    component={ActivationPage}
                    allowedUserType={UserType.sender}
                    fromPath={RoutePath.root}
                    exact
                    showUserBox={true}
                    showFooter={true} />
                <SmartRoute
                    path={RoutePath.viewer}
                    component={ViewerPage}
                    allowedUserType={UserType.none}
                    fromPath={RoutePath.root}
                    showUserBox={true}
                    showFooter={false} />
                <SmartRoute
                    path={RoutePath.sessionsSender}
                    component={SessionsPage}
                    allowedUserType={UserType.sender}
                    fromPath={RoutePath.root}
                    exact
                    showUserBox={true}
                    showFooter={false} />
                <SmartRoute
                    path={RoutePath.sessionsRecipient}
                    component={SessionsPage}
                    allowedUserType={UserType.recipient}
                    fromPath={RoutePath.root}
                    exact
                    showUserBox={true}
                    showFooter={false} />
                <SmartRoute
                    path={RoutePath.login}
                    component={LoginPage}
                    allowedUserType={UserType.none}
                    exact
                    showUserBox={false}
                    showFooter={true} />
                <SmartRoute
                    path={RoutePath.trialLogin}
                    component={TrialLoginPage}
                    fromPath={RoutePath.login}
                    showTrialLogin={true}
                    allowedUserType={UserType.none}
                    exact
                    showUserBox={false}
                    showFooter={true} />
                <SmartRoute
                    path={RoutePath.help}
                    component={HelpPage}
                    fromPath={RoutePath.root}
                    allowedUserType={UserType.none}
                    exact
                    showUserBox={true}
                    showFooter={true} />
                <SmartRoute
                    path={RoutePath.coverage}
                    component={CoveragePage}
                    fromPath={RoutePath.root}
                    allowedUserType={UserType.none}
                    exact
                    showUserBox={true}
                    showFooter={true} />
                <SmartRoute
                    path={RoutePath.terms}
                    component={LegalPage}
                    translationName="terms"
                    fromPath={RoutePath.root}
                    allowedUserType={UserType.none}
                    exact
                    showUserBox={true}
                    showFooter={true} />
                <SmartRoute
                    path={RoutePath.privacy}
                    component={LegalPage}
                    translationName="privacy"
                    fromPath={RoutePath.root}
                    allowedUserType={UserType.none}
                    exact
                    showUserBox={true}
                    showFooter={true} />
                 <SmartRoute
                    path={RoutePath.discover}
                    component={DiscoverPage}
                    fromPath={RoutePath.login}
                    allowedUserType={UserType.none}
                    exact
                    showUserBox={false}
                    showFooter={true} />
                <SmartRoute
                    path={RoutePath.buyOneOff}
                    component={BuyPage}
                    fromPath={RoutePath.discover}
                    allowedUserType={UserType.none}
                    exact
                    showUserBox={false}
                    showFooter={true} />
                <SmartRoute
                    path={RoutePath.buyOneOffConfirmation}
                    component={BuyConfirmationPage}
                    fromPath={RoutePath.root}
                    allowedUserType={UserType.none}
                    exact
                    showUserBox={false}
                    showFooter={true} />
                <SmartRoute
                    path={RoutePath.buyGold}
                    component={BuyPage}
                    fromPath={RoutePath.discover}
                    allowedUserType={UserType.none}
                    exact
                    showUserBox={false}
                    showFooter={true} />
                <SmartRoute
                    path={RoutePath.buyGoldConfirmation}
                    component={BuyConfirmationPage}
                    fromPath={RoutePath.root}
                    allowedUserType={UserType.none}
                    exact
                    showUserBox={false}
                 showFooter={true} /> 
                <SmartRoute
                    path={RoutePath.buyTrial}
                    component={BuyPage}
                    fromPath={RoutePath.discover}
                    //fromPath={RoutePath.root}
                    allowedUserType={UserType.none}
                    exact
                    showUserBox={false}
                    showFooter={true} />  
                <SmartRoute
                    path={RoutePath.buyTrialConfirmation}
                    component={BuyConfirmationPage}
                    fromPath={RoutePath.root}
                    allowedUserType={UserType.none}
                    exact
                    showUserBox={false}
                    showFooter={true} />
                  <SmartRoute
                    path={RoutePath.buyTrialByEmail}
                    component={BuyPage}
                    fromPath={RoutePath.discover}
                    allowedUserType={UserType.none}
                    exact
                    showUserBox={false}
                    showFooter={true} />
                <SmartRoute
                    path={RoutePath.buyEmailTrialConfirmation}
                    component={BuyConfirmationPage}
                    fromPath={RoutePath.root}
                    allowedUserType={UserType.none}
                    exact
                    showUserBox={false}
                    showFooter={true} />
                <SmartRoute
                    path={RoutePath.video}
                    component={VideoPage}
                    fromPath={RoutePath.discover}
                    showTrialLogin={true}
                    allowedUserType={UserType.none}
                    exact
                    showUserBox={false}
                    showFooter={true} />
                <SmartRoute
                    path={RoutePath.root}
                    component={GenericPage}
                    allowedUserType={[UserType.sender, UserType.recipient]}
                    exact
                    showUserBox={true}
                    showFooter={true} />
                <SmartRoute
                    component={NotFoundPage}
                    showUserBox={true}
                    showFooter={true} />
            </Switch>
        </Router>
    )
}
