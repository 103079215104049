import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
    dialog: {
        position: 'relative',
        marginTop: '15vh',
        maxHeight: 490,
        justifySelf: 'center',
        alignSelf: 'center'
    },
    pTitle: {
        width: '100%',
        font: 'normal normal normal 16px/24px Roboto',
        color: '#000000DE',
        textAlign: 'left'
    },
    pText: {
        width: '100%',
        font: 'normal normal normal 14px/20px Roboto',
        color: '#00000099',
        textAlign: 'justify'
    }
}))

export default function PrivacyDialog(props) {
    const classes = useStyles(props)
    const { t } = useTranslation(['translation', 'privacy'])
    /** @type {Array<{pTitle: String, pText: String}>} */ const pageBody = t("privacy:pageBody")

    return (
        <Dialog
            className={classes.dialog}
            open={props.open}
            onClose={props.onOkClick}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{t('privacy:pageTitle')}</DialogTitle>
            <DialogContent>
                { pageBody.map((section, index) => (
                    <Fragment key={index}>
                        { Boolean(section.pTitle) ? <div className={classes.pTitle}>{section.pTitle}</div> : null }
                        { Boolean(section.pText) ? <div className={classes.pText} dangerouslySetInnerHTML={{__html: section.pText}} /> : null}
                    </Fragment>
                ))}
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={props.onOkClick} 
                    color="primary" >
                        {t('buyPrivacyDialogOkButton')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}