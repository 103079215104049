import React from 'react'
import { makeStyles} from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import {Helmet} from "react-helmet"

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 34,
        marginBottom:34,
        display: 'flex',
        flexDirection: 'column',
    },

    valignedC: {
        justifyContent: 'center',
    },

    videoWrapper: {
        width: '100%',
        maxWidth: 912,
        margin: '0 auto',
        padding: 0,
        boxSizing: 'border-box'
    },

    mediaContainer: {
        position: 'relative',
        paddingBottom: '56.25%',
        height: 0,
        overflow: 'hidden',
        width: '100%',
        boxSizing: 'border-box',
    },

    mediaContainerIframe:{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#E7E7E7',
        boxSizing: 'border-box',
    },

    
}))

export default function VideoPage(props) {
    const classes = useStyles(props)
    const { t } = useTranslation()
    
    return (  
         <div className={classes.root}>
            <Helmet>
                <meta name="robots" content="noindex"/>
                <title>JustFollow.it: video</title>
            </Helmet>
            <div className= {classes.valignedC}>
                <div className= {classes.videoWrapper}>     
                    <div className={classes.mediaContainer}>
                    {t('language') === 'it'?
                        <iframe className={classes.mediaContainerIframe} width="auto" height="auto" src="https://www.youtube.com/embed/TiRR9RuDezA?autoplay=1&mute=1&rel=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>
                        :
                        <iframe className={classes.mediaContainerIframe} width="auto" height="auto" src="https://www.youtube.com/embed/ACKq2C4L4vI?autoplay=1&mute=1&rel=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>
                    }
                    </div>
                </div>
            </div>
         </div>
    )
}