import React from "react";
import { SvgIcon } from "@material-ui/core";

export default function Keyboard(props) {
  //Path taken from the svg file in the same dir
  return (
    <SvgIcon onClick = {props.onClick} >
      <rect width="24" height="24" fill="none"/><circle cx="15" cy="21" r="3" fill="red"/>
      <path d="M19.44,6.8h-5L15.7,4.6a1,1,0,0,0-.38-1.37A1,1,0,0,0,14,3.6L12.49,6.17l-.92-5.08a1,1,0,0,0-2,.36l1,5.35h-6A4.56,4.56,0,0,0,0,11.36v5.28A4.56,4.56,0,0,0,4.56,21.2H19.44A4.56,4.56,0,0,0,24,16.64V11.36A4.56,4.56,0,0,0,19.44,6.8Z" fill="#828282"/>
      <path d="M18.41,14l1.3-1.29a1,1,0,0,0-1.42-1.42L17,12.59l-1.29-1.3a1,1,0,0,0-1.42,1.42L15.59,14l-1.3,1.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L17,15.41l1.29,1.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" fill="#fff"/>
      <path d="M9.71,11.29a1,1,0,0,0-1.42,0L7,12.59l-1.29-1.3a1,1,0,0,0-1.42,1.42L5.59,14l-1.3,1.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L7,15.41l1.29,1.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L8.41,14l1.3-1.29A1,1,0,0,0,9.71,11.29Z" fill="#fff"/>
    </SvgIcon>
  );
}