/*
 * UT b2b API
 * Urbantracker business 2 business API
 *
 * OpenAPI spec version: 1.2.0
 * Contact: nospam@stetel.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.18
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The MessageDetails model module.
 * @module model/MessageDetails
 * @version 1.2.0
 */
export class MessageDetails {
  /**
   * Constructs a new <code>MessageDetails</code>.
   * details of the message that should be sent to the system
   * @alias module:model/MessageDetails
   * @class
   * @param body {String} actual content of the message to forward in plain text utf8
   */
  constructor(body) {
    this.body = body;
  }

  /**
   * Constructs a <code>MessageDetails</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MessageDetails} obj Optional instance to populate.
   * @return {module:model/MessageDetails} The populated <code>MessageDetails</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MessageDetails();
      if (data.hasOwnProperty('body'))
        obj.body = ApiClient.convertToType(data['body'], 'String');
      if (data.hasOwnProperty('subject'))
        obj.subject = ApiClient.convertToType(data['subject'], 'String');
      if (data.hasOwnProperty('origin'))
        obj.origin = ApiClient.convertToType(data['origin'], 'String');
    }
    return obj;
  }
}

/**
 * actual content of the message to forward in plain text utf8
 * @member {String} body
 */
MessageDetails.prototype.body = undefined;

/**
 * what the message is about
 * @member {String} subject
 */
MessageDetails.prototype.subject = undefined;

/**
 * Allowed values for the <code>origin</code> property.
 * @enum {String}
 * @readonly
 */
MessageDetails.OriginEnum = {
  /**
   * value: "webinfoform"
   * @const
   */
  webinfoform: "webinfoform",

  /**
   * value: "webhelpform"
   * @const
   */
  webhelpform: "webhelpform"
};

/**
 * @member {module:model/MessageDetails.OriginEnum} origin
 */
MessageDetails.prototype.origin = undefined;


