import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { smartphoneBps } from '../../../Main'


const useStyles = makeStyles(theme => ({
    root: {
        width: 260,
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [smartphoneBps(theme)]: {
            marginTop: 24,
            alignItems: 'center'
        }
    },
    image: {
        width: 115,
        height: 115,
        [smartphoneBps(theme)]: {
            width: 67,
            height: 67,
        }
    },
    text: {
        color: "#707070",
        font: "normal normal normal 18px/22px Roboto",
        textAlign: "center",
        marginTop: 24
    },
    textAlt: {
        color: "#707070",
        font: "normal normal normal 18px/22px Roboto",
        textAlign: "center",
        marginTop: 24
    }
}))

export default function Feature({ image, text, textAlt, ...props }) {
    const classes = useStyles(props)

    return (
        <div className={classes.root}>
            <img className={classes.image} src={image} alt={textAlt}/>
            <div className={classes.text}>{text}</div>
        </div>
    )
}