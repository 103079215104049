/*
 * UT b2b API
 * Urbantracker business 2 business API
 *
 * OpenAPI spec version: 1.2.0
 * Contact: nospam@stetel.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.18
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The RolesList model module.
 * @module model/RolesList
 * @version 1.2.0
 */
export class RolesList {
  /**
   * Constructs a new <code>RolesList</code>.
   * List of Roles
   * @alias module:model/RolesList
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>RolesList</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RolesList} obj Optional instance to populate.
   * @return {module:model/RolesList} The populated <code>RolesList</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RolesList();
      if (data.hasOwnProperty('web-sender'))
        obj.webSender = ApiClient.convertToType(data['web-sender'], 'Boolean');
      if (data.hasOwnProperty('web-receiver'))
        obj.webReceiver = ApiClient.convertToType(data['web-receiver'], 'Boolean');
      if (data.hasOwnProperty('web-follower'))
        obj.webFollower = ApiClient.convertToType(data['web-follower'], 'Boolean');
    }
    return obj;
  }
}

/**
 * @member {Boolean} webSender
 */
RolesList.prototype.webSender = undefined;

/**
 * @member {Boolean} webReceiver
 */
RolesList.prototype.webReceiver = undefined;

/**
 * @member {Boolean} webFollower
 */
RolesList.prototype.webFollower = undefined;


