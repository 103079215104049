import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import BatteryIcon from './BatteryIcon'

const useStyles = makeStyles({
    statusItem: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: 10
    },
    statusItemName: props => ({
        color: "#999999",
        fontSize: props.fontSize ? props.fontSize : "small"
    }),
    statusItemValue: {
        color: "#3f58b8",
    },
    statusItemValueBattery: {
        color: "#3f58b8",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    }
})

export default function BatteryItem({batteryPerc, hideIfMissing = false, ...props}) {
    const classes = useStyles(props)
    const {t} = useTranslation()
    
    return batteryPerc === null && hideIfMissing === true ? null : (
        <div className={classes.statusItem}>
            <div className={classes.statusItemName}>{t("viewerSessionBattery")}</div>
            <div className={classes.statusItemValueBattery}>
                <BatteryIcon percLevel={batteryPerc} />
                <div>
                    {batteryPerc === null || batteryPerc < 0
                        ? t("viewerSessionMissingValue")
                        : t("viewerSessionBatteryLevel", { percLevel: batteryPerc})
                    }
                </div>
            </div>
        </div>
    )
}