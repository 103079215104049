/*
 * UT b2b API
 * Urbantracker business 2 business API
 *
 * OpenAPI spec version: 1.2.0
 * Contact: nospam@stetel.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.18
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {TrackingOptions} from './TrackingOptions';

/**
 * The ActivationConfig model module.
 * @module model/ActivationConfig
 * @version 1.2.0
 */
export class ActivationConfig {
  /**
   * Constructs a new <code>ActivationConfig</code>.
   * @alias module:model/ActivationConfig
   * @class
   * @param accountId {String} account associated to the sender for billing purposes
   * @param trackingOptions {module:model/TrackingOptions} 
   */
  constructor(accountId, trackingOptions) {
    this.accountId = accountId;
    this.trackingOptions = trackingOptions;
  }

  /**
   * Constructs a <code>ActivationConfig</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ActivationConfig} obj Optional instance to populate.
   * @return {module:model/ActivationConfig} The populated <code>ActivationConfig</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ActivationConfig();
      if (data.hasOwnProperty('accountId'))
        obj.accountId = ApiClient.convertToType(data['accountId'], 'String');
      if (data.hasOwnProperty('trackingOptions'))
        obj.trackingOptions = TrackingOptions.constructFromObject(data['trackingOptions']);
    }
    return obj;
  }
}

/**
 * account associated to the sender for billing purposes
 * @member {String} accountId
 */
ActivationConfig.prototype.accountId = undefined;

/**
 * @member {module:model/TrackingOptions} trackingOptions
 */
ActivationConfig.prototype.trackingOptions = undefined;


