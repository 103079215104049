import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import TrackerItem from '../../../components/trackerItems/TrackerItem';
import BatteryItem from '../../../components/trackerItems/BatteryItem';
import TrackingIntervalItem from '../../../components/trackerItems/TrackingIntervalItem';
import { SessionStatus } from '../../../network/swaggerApi/model/SessionStatus';
import GenericMap from "./generic-map.png"
import activeIcon from "./active.gif"
import { smartphoneBps } from '../../../Main';
import MovementNotificationItem from '../../../components/trackerItems/MovementNotificationItem';

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
        maxWidth: 360,
        marginTop: 16,
        marginLeft: 8,
        marginRight: 8,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        [smartphoneBps(theme)]: {
            marginLeft: 0,
            marginRight: 0,
        }
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    area: {
        marginTop: -20,
        marginBottom: 0
    },
    title: {
        textAlign: "center",
        height: 30,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    cardBody: {
        marginTop: -10,
        marginBottom: -20
    },
    cardActions: {
        height: 20,
        display: "flex",
        justifyContent: "flex-end"
    },
    rowTrackerItems: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around"
    }
}))

const getMapImage = ({lat, lng}) => `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyCr9M9yz9Di-u6b5cBIRNFMmm6CEsbbPbI&size=600x600&format=jpg&markers=color:0x3f58b8%7C${lat},${lng}`

/**
 * 
 * @param {Object} param0
 * @param {SessionStatus} [param0.sessionStatus]
 * @param {function(String):void} [param0.onClick]
 * @param {import('react-router-dom').RouteComponentProps} [param0.props=null]
 */
export default function SessionCard({/** @type {SessionStatus} */ sessionStatus, onClick, ...props}) {
    const classes = useStyles(props)
    const {t} = useTranslation()

    let status
    let showActiveLed = false
    switch (sessionStatus.status) {
        case SessionStatus.StatusEnum.notactive:
        case SessionStatus.StatusEnum.active:
            status = t("sessionsCardStatusActive")
            showActiveLed = true
            break
        case SessionStatus.StatusEnum.deactivated:
            status = t("sessionsCardStatusNotActive")
            break
        case SessionStatus.StatusEnum.activating:
            status = t("sessionsCardStatusActivating")
            break
        case SessionStatus.StatusEnum.deactivating: // deactivating session
            status = t("sessionsCardStatusDeactivating")
            break
        default:
            status = t("sessionsCardStatusError")
    }

    let title = null
    let trackingIntervalMinutes = null
    let alertSpeedLowerThanKmh = null
    let shipFromAddress = null
    let shipToAddress = null
    if (sessionStatus.currentConfig) {
        title = sessionStatus.currentConfig.freeCustomerID
        trackingIntervalMinutes = sessionStatus.currentConfig.trackingIntervall
        alertSpeedLowerThanKmh = sessionStatus.currentConfig.alertSpeedLowerThanKmh
        shipFromAddress = sessionStatus.currentConfig.freeFromAddress
        shipToAddress = sessionStatus.currentConfig.freeToAddress
    }
    let geoLastLocation = null
    if (sessionStatus.geoPath && sessionStatus.geoPath.length > 0) {
        geoLastLocation = { lat: sessionStatus.geoPath[0].geoLat, lng: sessionStatus.geoPath[0].geoLong }
    }

    const handleClick = () => onClick(sessionStatus.sessionId)

    return (
        <Card className={classes.root}>
            <CardActionArea onClick={handleClick} className={classes.area}>
                <CardMedia
                    className={classes.media}
                    image={geoLastLocation ? getMapImage(geoLastLocation) : GenericMap}
                    title={title}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2" color="textSecondary" className={classes.title}>
                        {title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="div" className={classes.cardBody}>
                        <div className={classes.rowTrackerItems}>
                            <TrackerItem name={t("sessionsCardStatus")} 
                                value={status} 
                                rightIcon={showActiveLed ? activeIcon : null}
                                valuePositionOffset={showActiveLed ? 18 : 0}
                                grayedOut={!showActiveLed} />
                            <BatteryItem batteryPerc={sessionStatus.batteryLevel}/>
                        </div>
                        <TrackerItem 
                            name={t("sessionsCardLastUpdate")} 
                            value={sessionStatus.lastUpdate ? sessionStatus.lastUpdate.toLocaleString() : null}
                            flexDirection="row" />
                        <TrackingIntervalItem 
                            name={t("sessionsCardTrackingInterval")}
                            minutes={trackingIntervalMinutes}
                            flexDirection="row"  />
                        <MovementNotificationItem 
                            name={t("sessionsCardMovementNotifications")}
                            options={t("sessionsCardMovementNotificationsOptions")}
                            speed={alertSpeedLowerThanKmh}
                            flexDirection="row"  />
                        <TrackerItem name={t("sessionsCardShipFromAddress")} 
                            value={shipFromAddress}
                            flexDirection="row"/>
                        <TrackerItem name={t("sessionsCardShipToAddress")}
                            value={shipToAddress}
                            flexDirection="row"/>
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions className={classes.cardActions}>
                <Button size="large" color="primary"  onClick={handleClick}>
                    {t("sessionsCardViewButton")}
                </Button>
            </CardActions>
        </Card>
    )
}