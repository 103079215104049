import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    camContainer: {
        width: "100%",
        position: "relative"
    },
    scanHowto: {
        zIndex: 10,
        position: "absolute",
        top: 0,
        width: "100%",
        color: "#FFFFFF",
        backgroundColor: "rgba(0,0,0,0.65)",
        padding: 8,
        fontSize: 16,
        boxSizing: "border-box"
    }

}));

 /**
 * 
 * @param {Object} param0
 * @param {Boolean} [param0.open]
 * @param {import('@zxing/library').BrowserQRCodeReader} [param0.codeReader]
 * @param {function(String):void} [param0.onQrScanResult]
 * @param {import('react-router-dom').RouteComponentProps} [param0.props=null]
 */
export default function QrCodeScan({open, codeReader, onQrScanResult, ...props}) {
    const classes = useStyles(props);
    const {t} = useTranslation()

    useEffect(() => {
        if (open) {
            codeReader.decodeOnceFromVideoDevice(undefined, 'video')
                .then(result => {
                    onQrScanResult(result.getText())
                })
                .catch(err => {
                    console.error(err)
                    // props.fromScan("ERROR")
                })
        } else {
            codeReader.reset()
        }
    }, [codeReader, open, onQrScanResult])

    return open ? (
        <div className={classes.camContainer}>
            <video
                id="video"
                width="100%"
                height="100%" >
            </video>
            <div className={classes.scanHowto}>{t("activationStep1QrCodeHowTo")}</div>
        </div>
    ) : null
}