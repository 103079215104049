import firebase from 'firebase/app'
import "firebase/auth";
// eslint-disable-next-line no-unused-vars
import { Observable, asapScheduler, Subscriber } from 'rxjs'
import { subscribeOn } from 'rxjs/operators'

const config = {
    apiKey: "AIzaSyDUe-80lkuk890PoIebMXo3jLWMx1Y7A7E", // Auth / General Use
    authDomain: "ut-assets-journey.web.app", // Auth with popup/redirect
    databaseURL: "https://ut-assets-journey.firebaseapp.com", // Realtime Database
    storageBucket: "ut-assets-journey.firebaseapp.com", // Storage
    messagingSenderId: "645905084196" // Cloud Messaging
};

// MyFirebase private vars
const firebaseApp = !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();
const firebaseProvider = new firebase.auth.GoogleAuthProvider()

firebaseProvider.setCustomParameters({ prompt: "select_account" }) // avoid Chrome auto-login

class MyFirebase {
    /**
     * @returns {Observable<firebase.User>} Observable emitting the Firebase User data
     */
    rxGetUser() {
        return new Observable((/** @type {Subscriber} */ emitter) => {
            if (firebaseApp.auth().currentUser) {
                emitter.next(firebaseApp.auth().currentUser)
                emitter.complete()
            } else {
                firebaseApp.auth().onAuthStateChanged(user => {
                    emitter.next(user)
                    emitter.complete()
                })
            }
        }).pipe(subscribeOn(asapScheduler))
    }

    /**
     * @param {firebase.User} user Firebase user to retrieve the token for
     * @returns {Observable<String>} Observable emitting the Firebase User ID token
     */
    rxGetIdToken(user) {
        return new Observable((/** @type {Subscriber} */ emitter) => {
            user.getIdToken().then(idToken => {
                emitter.next(idToken)
                emitter.complete()
            }).catch(error => emitter.error(error))
        }).pipe(subscribeOn(asapScheduler))
    }

    /**
     * @returns {Observable<void>} Observable completing on finish
     */
    rxLogin() {
        return new Observable((/** @type {Subscriber} */ emitter) => {
            firebaseApp.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
                .then(() => firebaseApp.auth().signInWithRedirect(firebaseProvider))
                .then(() => {
                    emitter.next()
                    emitter.complete()
                })
                .catch(error => emitter.error(error))
        }).pipe(subscribeOn(asapScheduler))
    }

    /**
     * @returns {Observable<void>} Observable completing on finish
     */
    rxLogOut() {
        return new Observable((/** @type {Subscriber} */ emitter) => {
            firebaseApp.auth().signOut().then(() => {
                emitter.next()
                emitter.complete()
            })
            .catch(error => emitter.error(error))
        }).pipe(subscribeOn(asapScheduler))
    }
} 

export default new MyFirebase()

