import React from 'react'
import { useTranslation } from 'react-i18next'
import TrackerItem from './TrackerItem'
import Utb2bApi from '../../network/UtB2bApi'

export default function MovementNotificationItem({name, options, speed, ...props}) {
    const {t} = useTranslation()
    
    let value = options[0] 
    if (speed !== null) {
        const notMovingIndex = Utb2bApi.notifyTrackerNotMovingOptions.indexOf(speed)
        if (notMovingIndex !== null ) {
            value = options[notMovingIndex] 
        }
    }
    return <TrackerItem name={name} value={value} {...props} />
}