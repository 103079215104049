import React, { Fragment } from "react";
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import logoImage from "./logo.png"
import logoutImage from "./logout.png"
import loginImage from "./login.png"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AlertDialog from "../AlertDialog";
import { RoutePath, navigate, smartphoneBps, redirectToLogin } from "../../Main";
import { useMediaQuery, useTheme } from "@material-ui/core";
// eslint-disable-next-line no-unused-vars
import { User } from "../Auth"
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    rootBox: {
        display: 'flex',
        background: 'rgba(63,88,184,1)',
        height: 120,
        minHeight: 120,
        [smartphoneBps(theme)]: {
            height: 70,
            minHeight: 70,
        }
    },
    navigation: {
        flex: '1',
        display: 'flex',
        alignItems: 'flex-end',
        padding: 10
    },
    logo: {
        display: "block",
        flex: '1',
        alignSelf: 'center',
        textAlign: 'center',
        padding: 10,
        width: "auto",
        height: "auto",
        maxWidth: 197,
        maxHeight: 38,
        [smartphoneBps(theme)]: {
            maxWidth: 96,
            maxHeight: 19,
        },
        cursor: 'pointer'
    },
    userBox: {
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        padding: 10,
        [smartphoneBps(theme)]: {
            fontSize: "small"
        }
    },
    navigationBackImage: {
        cursor: 'pointer'
    },
    welcomeUser: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        color: 'white',
        fontWeight: 'bold',
        [smartphoneBps(theme)]: {
            fontWeight: "normal"
        }
    },
    userName: {
        textAlign: "end",
        whiteSpace: "nowrap",
        overflow: "hidden",
        [smartphoneBps(theme)]: {
            whiteSpace: "normal",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical"
        }
    },
    userAvatar: {
        border: '2px solid #ffffff',
        marginLeft: 20,
        [smartphoneBps(theme)]: {
            display: "none"
        }
    },
    loginLogoutBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        cursor: 'pointer',
        paddingTop: 5
    },
    loginLogoutText: {
        color: 'white',
        [smartphoneBps(theme)]: {
            fontSize: "x-small"
        }
    },
    loginLogoutImage: {
        width: 20,
        height: 20,
        paddingLeft: 5,
        [smartphoneBps(theme)]: {
            width: 14,
            height: 14
        }
    },
}))

/**
 * @param {{ fromPath: String; 
 *           user: User; 
 *           handleNavigationClick: function(import("react-router-dom").RouteComponentProps):void;  
 *           handleLogoutClick: function():void; }} props
 */
export default function Header(props) {
    const classes = useStyles(props);
    const {t} = useTranslation()
    const location = useLocation()
    const [logoutDialogOpen, setLogoutDialogOpen] = React.useState(false);
    const theme = useTheme()
    const smartphone = useMediaQuery(smartphoneBps(theme))
    
    const handleLoginLogoutClick = () => {
        if (props.user) {
            setLogoutDialogOpen(true)
        } else {
            redirectToLogin(props)
        }
    }
    
    const logoutCancelClick = () => {
        setLogoutDialogOpen(false)
    }

    const logoutOkClick = () => {
        setLogoutDialogOpen(false)
        props.handleLogoutClick()
    }

    return (
        <React.Fragment>
            <div className={classes.rootBox}>
                <div className={classes.navigation}>
                    {props.fromPath && location.pathname !== RoutePath.root ? 
                        <ArrowBackIosIcon 
                            className={classes.navigationBackImage} 
                            htmlColor="white" 
                            onClick={() => props.handleNavigationClick(props)} />
                    : null}
                </div>
                <img className={classes.logo} 
                    src={logoImage} 
                    alt="" 
                    onClick={() => navigate(props, RoutePath.root)} />
                <div className={classes.userBox}>
                    {   props.showUserBox ?
                        <Fragment>
                            <div className={classes.welcomeUser}>
                                {props.user ? 
                                    <Fragment>
                                        <div>
                                            { smartphone || t("headerGreeting")}
                                            <div className={classes.userName}>
                                                {Boolean(props.user.displayName) ? props.user.displayName : props.user.username}
                                            </div>
                                        </div>
                                        <Avatar className={classes.userAvatar} src={props.user.photoUrl} alt="" />
                                    </Fragment>
                                : null}
                            </div>
                            <div 
                                className={classes.loginLogoutBox}
                                onClick={handleLoginLogoutClick}>
                                    <div className={classes.loginLogoutText}>{t(props.user ? "headerLogout" : "headerLogin")}</div>
                                    <img 
                                        className={classes.loginLogoutImage} 
                                        src={props.user ? logoutImage : loginImage} 
                                        alt="Logout" />
                            </div>
                        </Fragment>
                    : null }
                </div>
            </div>
            <AlertDialog
                open={logoutDialogOpen}
                title={t("headerLogoutDialogTitle")}
                description={t("headerLogoutDialogDescription")}
                okButton={t("headerLogoutDialogOkButton")}
                onOkClick={logoutOkClick}
                cancelButton={t("headerLogoutDialogCancelButton")}
                onCancelClick={logoutCancelClick} />
        </React.Fragment>
    );
}
