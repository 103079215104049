import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Divider from '@material-ui/core/Divider'
import { navigate, RoutePath, smartphoneBps } from '../../Main'
import SupportIcon from './support-box.png'
import Feature from '../../pages/discoverPage/components/Feature'
import FbImage from './fb-ico-32.png'
import BlImage from './bl-ico-32.png'
import IsImage from './is-ico-32.png'
import LiImage from './li-ico-32.png'
import TwImage from './tw-ico-32.png'

const useStyles = makeStyles(theme => ({
    root: {
        width: "80%",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    divider: {
        width: "100%",
        [smartphoneBps(theme)]: {
            width: 0
        }
    },
    navLinks: {
        width: "100%",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: 8,
        [smartphoneBps(theme)]: {
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    navLink: {
        color: '#666666',
        margin: 16,
        fontSize: 16,
        textDecoration: 'none',
        cursor: 'pointer'
    },
    helpBox: {
        width: "100%",
        maxWidth: 360,
        marginTop: 24,
        marginBottom: 24,
        marginLeft: 16,
        marginRight: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: 19,
        background: '#efefef',
        padding: 16,
        backgroundImage: `url(${SupportIcon})`,
        backgroundSize: "20px 20px",
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 12,
        backgroundPositionY: 12
    },
    helpTitle: {
        color: '#666666',
        fontSize: 14,
        fontWeight: 550
    },
    helpMail: {
        marginTop: 8,
        fontSize: 14
    },
    helpInfo: {
        marginTop: 8,
        color: '#666666',
        // fontSize: 14,
        font: 'normal normal normal 14px/22px Roboto',
        textAlign: 'center'
    },

    socialBar: {
        margin: '0px 0px 32px 0px',
        textAlign: 'center',
        font: 'normal normal 400 14px/14px Roboto',
        fontSize: 14,
        fontWeight: 400,
        color: '#666666',
        width: 152,
        [smartphoneBps(theme)]: {
            width: 224,
            justifyContent: 'flex-start'
        }
    },

    socialIcons:  {
        marginTop: 12,
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'center',
        justifyContent: 'space-between'
        
    },

    socialIconsImg: {
        width: 24,
        height: 24,
        [smartphoneBps(theme)]: {
            width: 32,
            height: 32,
        }
    }
}))

export default function Footer(props) {
    const classes = useStyles(props)
    const {t} = useTranslation()

    return (
        <div className={classes.root}>
            <Divider className={classes.divider} />
            <div className={classes.navLinks}>
                <div className={classes.navLink} 
                    onClick={() => navigate(props, RoutePath.help)}>
                        {t('footerNavLinkGuide')}
                </div>
                <div className={classes.navLink} 
                    onClick={() => navigate(props, RoutePath.coverage)}>
                        {t('footerNavLinkCoverage')}
                </div>
                <div className={classes.navLink} 
                    onClick={() => navigate(props, RoutePath.terms)}>
                        {t('footerNavLinkTerms')}
                </div>
                <div className={classes.navLink} 
                    onClick={() => navigate(props, RoutePath.privacy)}>
                        {t('footerNavLinkPrivacy')}
                </div>
                <div className={classes.navLink} 
                    onClick={() => navigate(props, RoutePath.root)}>
                        {t('footerNavLinkHome')}
                </div>
            </div>
            <div className={classes.helpBox}>
                <div className={classes.helpTitle}>{t('footerHelpTitle')}</div>
                <div className={classes.helpMail}><a href={"mailto:" + t('footerHelpEmail')}>{t('footerHelpEmail')}</a></div>
                <div className={classes.helpInfo}>{t('footerHelpInfo')}</div>
            </div>
            <div className={classes.socialBar}>
                <div>{t('footerWebSite')} <a href="https://www.stetel.com" target="_blank" rel="noopener noreferrer" title="Stetel">Stetel Srl</a></div>
                <div className={classes.socialIcons}>
				        <a href="https://www.facebook.com/StetelMobile/" target="_blank" rel="noopener noreferrer" title="Stetel Facebook"><img className={classes.socialIconsImg} src={FbImage} alt="Stetel Facebook"/></a>
				        <a href="https://www.linkedin.com/company/9501392/" target="_blank" rel="noopener noreferrer" title="Stetel Linkedin"><img className={classes.socialIconsImg} src={LiImage} alt="Stetel Linkedin"/></a>
				        <a href="https://twitter.com/StetelSrl" target="_blank" rel="noopener noreferrer" title="Stetel Twitter"><img className={classes.socialIconsImg} src={TwImage} alt="Stetel Twitter"/></a>
				        <a href="https://www.instagram.com/stetelsrl" target="_blank" rel="noopener noreferrer" title="Stetel Instagram"><img className={classes.socialIconsImg} src={IsImage} alt="Stetel Instagram"/></a>
				        <a href="https://www.stetel.com/category/www-justfollow-it/" target="_blank" rel="noopener noreferrer" title="JustFollow.it blog"><img className={classes.socialIconsImg} src={BlImage} alt="JustFollow.it blog"/></a>
			    </div>

            </div>
        </div>
    )
}