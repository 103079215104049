// eslint-disable-next-line no-unused-vars
import { Observable, asyncScheduler, of, pipe, timer, throwError, from } from "rxjs"
import { subscribeOn, tap, flatMap, map, retryWhen, takeWhile, repeat, bufferCount } from "rxjs/operators"
import RxNetApi from "./RxNetApi"
import { ActivationStatus } from "./swaggerApi/model/ActivationStatus"
import { SessionStatus } from "./swaggerApi/model/SessionStatus"
import auth, { Types, User, UserProviders } from "../components/Auth"
import { MessageDetails } from "./swaggerApi/model/MessageDetails"

const activationStatusPollingOperators = pipe(
    tap((/** @type {ActivationStatus} */ activationStatus) => {
        if (activationStatus.status === ActivationStatus.StatusEnum.active ||
            activationStatus.status === ActivationStatus.StatusEnum.deactivated) {
            // UrbanTracker has completed activation/deactivation! (note: must be checked before retrySeconds)
            return
        } else if (activationStatus.retrySeconds === null) { 
            // stop polling if retrySeconds is null
            throw new ServerTimeoutError()
        } else if (activationStatus.status === ActivationStatus.StatusEnum.activating || 
            activationStatus.status === ActivationStatus.StatusEnum.deactivating) { 
            // wait and continue polling if activating/deactivating (note: must be checked after retrySeconds)
            throw new WaitingForStatusError(activationStatus.retrySeconds)
        } else { 
            // error if status is not activating/active/deactivating/deactive
            throw new BadStatusError()
        }
    }),
    retryWhen(retryObservable => retryObservable.pipe( // repeat the observable if waiting for activation (polling)
        flatMap(error => error instanceof WaitingForStatusError 
            ? timer(error.retryInterval) // delay for time specified by server before repeating the whole observable
            : throwError(error) // other errors are re-thrown and exit the polling
        )
    ))
)

class Utb2bApi {
    trackingIntervalOptions = [
        30,     // 30 minutes
        60,     // 1 hour
        180,    // 3 hours
        360,    // 6 hours
    ]
    notifyTrackerNotMovingOptions = [
        -1,
        40,
        0
    ]

    /**
     * Activate a tracker / session. Will continue to poll the server asking for 
     * the tracker's status and the observable emits when it is activated.
     * 
     * @param {String} trackerId 
     * @param {Object} trackingOptions 
     * @returns {Observable<ActivationStatus>}
     */
    rxActivateTracker = (trackerId, trackingOptions) => {
        /** @type {String} */ let sessionId // session ID received in the Activate response
        return auth.rxGetUser().pipe( // retrieve user
            subscribeOn(asyncScheduler), // set async
            tap(user => {
                if (user === null) {
                    throw new SecurityException("Activate Tracker requires user to be logged in")
                }
            }),
            flatMap((/** @type {User} */ user) => (sessionId 
                ? RxNetApi.rxActivationStatus( // execute activationStatus during polling (sessionId is present)...
                    user.idToken, 
                    sessionId)
                : RxNetApi.rxActivate(  // ... or execute activate only the first time
                    user,
                    trackerId, 
                    trackingOptions.senderAddress, 
                    trackingOptions.recipientAddress,
                    this.trackingIntervalOptions[trackingOptions.trackingInterval],
                    this.notifyTrackerNotMovingOptions[trackingOptions.notifyTrackerNotMoving],
                    trackingOptions.allowRecipientOndemand,
                    trackingOptions.allowRecipient2Deactivate,
                    trackingOptions.allowAnonymousUsers,
                    trackingOptions.shipmentId,
                    trackingOptions.shipFromAddress,
                    trackingOptions.shipToAddress)
                ).pipe(
                    // saving sessionId to perform activationStatus during polling
                    tap((/** @type {ActivationStatus} */ activationStatus) => sessionId = activationStatus.sessionId)
                )
            ),
            activationStatusPollingOperators
        )
    }

    /**
     * Deactivate a tracker / session. Will continue to poll the server asking for 
     * the tracker's status and the observable emits when it is deactivated.
     * 
     * @param {String} trackerId 
     * @param {String} sessionId session ID received in the Activate response
     * @returns {Observable<ActivationStatus>}
     */
    rxDeactivateTracker = (trackerId, sessionId) => {
        let deactivating = false
        return auth.rxGetUser().pipe( // retrieve user
            subscribeOn(asyncScheduler), // set async
            tap(user => {
                if (user === null) {
                    throw new SecurityException("Deactivate Tracker requires user to be logged in")
                }
            }),
            flatMap((/** @type {User} */ user) => (deactivating 
                ? RxNetApi.rxActivationStatus( // execute activationStatus during polling (status deactivating)...
                    user.idToken, 
                    sessionId)
                : RxNetApi.rxDeactivate(  // ... or execute deactivate only the first time
                    user.idToken,
                    trackerId, 
                    sessionId)
                ).pipe(
                    // saving sessionId to perform activationStatus during polling
                    tap((/** @type {ActivationStatus} */ activationStatus) => 
                        deactivating = activationStatus.status === ActivationStatus.StatusEnum.deactivating)
                )
            ),
            activationStatusPollingOperators
        )
    }

    /**
     * Fully retrieve a session containing all the geo location's data.
     * The observable emits a SessionStatus every 100 geoPaths.
     * 
     * @param {String} sessionId session ID used to load the webpage
     * @returns {Observable<SessionStatus>}
     */
    rxRetrieveSession = sessionId => {
        const itemsPerRequest = 100
        let startingFromId = null

        return auth.rxGetUser().pipe( // retrieve user
            subscribeOn(asyncScheduler), // set async
            map(user => user ? user.idToken : null),
            flatMap(idToken => RxNetApi.rxStatus(idToken, sessionId, startingFromId, itemsPerRequest)),
            tap(sessionStatus => {
                if (sessionId !== sessionStatus.sessionId) {
                    throw new SecurityException("Session ID does not match")
                }
            }),
            repeat(), // repeat the observable to retrieve all the data
            takeWhile(sessionStatus => {
                const retrievedPointsCount = sessionStatus.geoPath ? sessionStatus.geoPath.length : 0
                if (retrievedPointsCount < itemsPerRequest) {
                    // less items than requested, data retrieval complete
                    return false // will trigger complete, finishing the source observable
                } else {
                    // same amount of items as requested, means more items awaiting. 
                    // Repeat the request starting from the last ID
                    startingFromId = sessionStatus.geoPath[retrievedPointsCount - 1].id
                    return true // will trigger next, so repeating the source observable
                }
            }, true)
        )
    }

    /**
     * Retrieve a list of sessions, containing first the Activated items and last the Deactivated ones.
     * It supports pagination by passing the last retrieved Session ID and the number of items to get each time.
     * 
     * @param {String} fetchSenderOrRecipient Include shipments where the user is the "sender" or the "recipient"
     * @param {String} startingFromId Retrieve items starting from the specified ID (pagination purposes). Default to null, so starting from the beginning.
     * @param {Number} itemsPerRequest Number of items to retrieve (pagination purposes). Default to 100 items
     * @returns {Observable<SessionStatus[]>}
     */
    rxRetrieveSessionsList = (fetchSenderOrRecipient, startingFromId = null, itemsPerRequest = 100) => {
        const fetchStatus = [SessionStatus.StatusEnum.active, SessionStatus.StatusEnum.deactivated]
        let fetchStatusIndex = 0
        let direction
        let retrievedItemsCount = 0
        if (fetchSenderOrRecipient === "sender") {
            direction = "sent"
        } else if (fetchSenderOrRecipient === "recipient") {
            direction = "receiving"
        } else {
            return throwError(new InvalidParamException("includeSenderOrRecipient must be \"sender\" or \"recipient\""))
        }

        return auth.rxGetUser().pipe( // retrieve user
            subscribeOn(asyncScheduler), // set async
            tap(user => {
                if (user === null) {
                    throw new SecurityException("Sessions List requires user to be logged in")
                }
            }),
            flatMap((/** @type {User} */ user) => RxNetApi.rxSessionslist(user.idToken, direction, 
                fetchStatus[fetchStatusIndex], startingFromId, itemsPerRequest - retrievedItemsCount)),
            repeat(),   // repeat the request until we have fetched all the data
            takeWhile(sessionStatusList => { 
                retrievedItemsCount += sessionStatusList ? sessionStatusList.length : 0
                if (retrievedItemsCount < itemsPerRequest) {
                    // less items than requested, check next type of activationStatus to fetch
                    if (++fetchStatusIndex >= fetchStatus.length) {
                        // fetched all types, completing
                        return false
                    } else {
                        // start again and fetch the next activationStatus type
                        if (retrievedItemsCount > 0) {
                            startingFromId = null
                        }
                        return true
                    }
                } else {
                    // same amount of items as requested, so don't fetch other statuses and return
                    return false
                }
            }, true),
            flatMap(sessionStatusList => from(sessionStatusList)), // items from the array are emitted individually
            bufferCount(itemsPerRequest) // build a new array containing the results of all the requests made with different statuses
        )
    }

    /**
     * Check if the user is a specific type to allow certain section of the website
     * 
     * @returns {Observable<Types>}
     */
    rxCheckUserAccess = (idToken) => {
        if (idToken) {
            return RxNetApi.rxMyRoles(idToken).pipe(
                subscribeOn(asyncScheduler), // set async
                map(rolesList => new Types(rolesList.webSender, 
                    rolesList.webReceiver, 
                    rolesList.webFollower))
            )
        } else {
            return of(new Types(false, false, false))
        }
    }

    /**
     * Login the user with the trial kit
     * 
     * @param {String} username 
     * @param {String} password 
     * @returns {Observable<User>} Observable emitting the user's data
     */
    rxTrialLogin = (username, password) => RxNetApi.rxTrialLogin(username, password).pipe(
        subscribeOn(asyncScheduler), // set async
        map((authInfo) => {
            if (authInfo) {
                return new User(
                    UserProviders.custom,
                    username, 
                    authInfo.xAuth, 
                    null,
                    null,
                    null,
                    new Types(authInfo.roles.webSender, 
                        authInfo.roles.webReceiver,
                        authInfo.roles.webFollower)
                )
            } else {
                throw new Error("UtbtbApi.rxTrialLogin authInfo = null")
            }
        })
    )

    /**
     * Logout a user with the trial kit
     * 
     * @param {User} user User to logout
     */
    rxTrialLogout = user => {
        return RxNetApi.rxTrialLogout(user.idToken).pipe(
            subscribeOn(asyncScheduler), // set async
        )
    }

    /**
     * Send a message for requesting information about the tracker's solutions
     * 
     * @param {*} subject 
     * @param {*} body 
     * @returns 
     */
    rxSendRequestInfoMessage = (subject, body) => {
        const messageDetails = new MessageDetails(body)
        messageDetails.subject = subject
        messageDetails.origin = MessageDetails.OriginEnum.webinfoform
        return RxNetApi.rxForwardMessage(null, messageDetails).pipe(
            subscribeOn(asyncScheduler), // set async
        )
    }

}

export class BadStatusError extends Error {
    constructor() {
        super("Bad status")
    }
}

export class ServerTimeoutError extends Error {
    constructor() {
        super("Activation/Deactivation timed out on the server")
    }
}

export class WaitingForStatusError extends Error {
    retryInterval = 0

    /**
     * @param {number} [retryIntervalSeconds]
     */
    constructor(retryIntervalSeconds) {
        super("Waiting for the tracker to become active/deactive")
        this.retryInterval = retryIntervalSeconds * 1000
    }
}

export class SecurityException extends Error {
    constructor(message = "Security error") {
        super(message)
    }
}

export class InvalidParamException extends Error {
    constructor(message = "Invalid parameter") {
        super(message)
    }
}

export class MoreItemsLeftException extends Error {
    constructor(message = "More items left") {
        super(message)
    }
}



export default new Utb2bApi()