import React from 'react'
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { smartphoneBps, smallDesktopBps } from "../../../Main";
import { useMediaQuery } from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        paddingTop: 54,
        paddingBottom: 54,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        backgroundColor: '#010B31'
    },
    info: {
        marginLeft: 16,
        marginRight: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        color: '#fff'
    },
    infoTitle: {
        font: 'normal normal 300 32px/38px Roboto',
        marginBottom: 32,
        [smartphoneBps(theme)]: {
            textAlign: 'center'
        }
    },
    infoNote: {
        font: 'normal normal normal 16px/19px Roboto',
        marginTop: 20,
        marginLeft: 24,
        [smartphoneBps(theme)]: {
            marginLeft: 0,
            textAlign: 'center',
            alignSelf: 'center'
        }
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    listItemDot: {
        minWidth: 16,
        minHeight: 16,
        marginTop: 7,
        borderRadius: '50%',
        backgroundColor: '#FDC600'
    },
    listItemText: {
        marginLeft: 8,
        color: '#fff',
        font: 'normal normal normal 24px/29px Roboto'
    },
    image: {
        width: 388,
        height: 280,
        marginTop: 32,
        zIndex: 100
    },
}))

export default function Header({title, listItems, note, image, ...props}) {
    const classes = useStyles(props)
    const theme = useTheme()
    const smallDesktop = useMediaQuery(smallDesktopBps(theme))

    return (
        <div className={classes.root}>
            <div className={classes.info}>
                <div className={classes.infoTitle}>{title}</div>
                {listItems.map((item, index) => (
                    <div className={classes.listItem} key={index}>
                        <div className={classes.listItemDot} />
                        <div className={classes.listItemText}>{item}</div>
                    </div>
                ))}
                <div className={classes.infoNote}>{note}</div>
            </div>
            { !smallDesktop ?
                <img className={classes.image} src={image} alt="" />
            : null}
        </div>
    )
}