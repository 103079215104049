import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TrackerIdInput from '../../../components/TrackerIdInput'
import { rxCameraAvailable } from '../../../Main'

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: theme.spacing(1),
        marginRight: 0,
        float: "right",
        fontWeight: 550
    },
    stepStyle: {
        paddingBottom: '3%',
        paddingTop: '3%'
    }
}))

export default function Step1(props) {
    const classes = useStyles(props)
    const {t} = useTranslation()
    const [trackerId, setTrackerId] = useState(null)
    const [cameraAvailable, setCameraAvailable] = useState(false)
    
    useEffect(() => setTrackerId(props.trackerId), [props.trackerId])

    useEffect(() => {
        rxCameraAvailable().subscribe(available => setCameraAvailable(available))
    }, [])

    /**
     * @param {String} newTrackerId
     */
    const handleNewTrackerId = newTrackerId => {
        setTrackerId(newTrackerId)
    }

    return (
        <div id="step2" className={classes.stepStyle}>
            <Typography>{t(cameraAvailable ? "activationStep1Description" : "activationStep1DescriptionNoCamera")}</Typography>
            <TrackerIdInput onNewTrackerId={handleNewTrackerId} trackerId={trackerId} />
            <Button
                disabled={!Boolean(trackerId)}
                color="primary"
                onClick={() => props.handleNext(trackerId)}
                className={classes.button}>
                {t("activationStep1NextButton")}
            </Button>
        </div>
    )
}