import React from 'react'
import { useTranslation } from 'react-i18next'
import TrackerItem from './TrackerItem'
import Utb2bApi from '../../network/UtB2bApi'

export default function TrackingIntervalItem({name, minutes, ...props}) {
    const {t} = useTranslation()
    
    let value = null
    if (minutes !== null) {
        const trackingIntervalIndex = Utb2bApi.trackingIntervalOptions.indexOf(minutes)
        value = trackingIntervalIndex === null 
            ? t("viewerSessionTrackingIntervalCustomMins", {minutes: minutes})
            : t("activationStep2TrackingIntervalOptions")[trackingIntervalIndex] 
    }
    return <TrackerItem name={name} value={value} {...props} />
}