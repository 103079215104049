import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { SessionStatus } from '../../../network/swaggerApi/model/SessionStatus'
import { ButtonBase, Divider, useTheme, useMediaQuery } from '@material-ui/core'
import incomingIcon from "./incoming.png"
import incomingDisabledIcon from "./incoming-disabled.png"
import outgoingIcon from "./outgoing.png"
import outgoingDisabledIcon from "./outgoing-disabled.png"
import activeIcon from "./active.gif"
import BatteryIcon from '../../../components/trackerItems/BatteryIcon'
import { smartphoneBps } from '../../../Main'

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 0
    },
    content: {
        padding: 16,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexWrap: "nowrap",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        background: "#fff",
        "&:hover": {
            background: "#f0f0f0"
        }
    },
    icon: {
        width: 96,
        height: "auto",
        [smartphoneBps(theme)]: {
            width: 48
        }
    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    centralTexts: {
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        marginLeft: 16,
        marginRight: 16
    },
    centralTextsStatus: {
        color: "#3f58b8",
        fontSize: 14,
        [smartphoneBps(theme)]: {
            fontSize: 10
        }
    },
    centralTextsStatusDisabled: {
        color: "#a1a1a1",
        fontSize: 14,
        [smartphoneBps(theme)]: {
            fontSize: 10
        }
    },
    centralTextsTitle: {
        width: "50vw",
        fontSize: 20,
        marginTop: 10,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        [smartphoneBps(theme)]: {
            fontSize: 16
        }
    },
    centralTextsUpdate: {
        width: "50vw",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        fontSize: 16,
        marginTop: 10,
        [smartphoneBps(theme)]: {
            fontSize: 12
        }
    },
    centralTextsUpdateLabel: {
        marginRight: 4
    },
    centralTextsUpdateValue: {
        color: "#3f58b8",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        overflow: "hidden"
    },
    batteryBox: {
        fontSize: 14,
        color: "#3f58b8",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        [smartphoneBps(theme)]: {
            fontSize: 10
        }
    }
}))

/**
 * 
 * @param {Object} param0
 * @param {SessionStatus} [param0.sessionStatus]
 * @param {function(String):void} [param0.onClick]
 * @param {boolean} [param0.isSender]
 * @param {import('react-router-dom').RouteComponentProps} [param0.props=null]
 */
export default function SessionListItem({/** @type {SessionStatus} */ sessionStatus, onClick, isSender, ...props}) {
    const classes = useStyles(props)
    const {t} = useTranslation()
    const theme = useTheme()
    const smartphone = useMediaQuery(smartphoneBps(theme))

    let status
    let statusClass
    let icon
    let showActiveLed = false
    switch (sessionStatus.status) {
        case SessionStatus.StatusEnum.notactive: // active session, but tracker turned off
        case SessionStatus.StatusEnum.active:   // active session
            status = t("sessionsListStatusActive")
            statusClass = classes.centralTextsStatus
            icon = isSender ? outgoingIcon : incomingIcon
            showActiveLed = true
            break
        case SessionStatus.StatusEnum.deactivated: // deactive session
            status = t("sessionsListStatusNotActive")
            statusClass = classes.centralTextsStatusDisabled
            icon = isSender ? outgoingDisabledIcon : incomingDisabledIcon
            break
        case SessionStatus.StatusEnum.activating: // activating session
            status = t("sessionsListStatusActivating")
            statusClass = classes.centralTextsStatusDisabled
            icon = isSender ? outgoingDisabledIcon : incomingDisabledIcon
            break
        case SessionStatus.StatusEnum.deactivating: // deactivating session
            status = t("sessionsListStatusDeactivating")
            statusClass = classes.centralTextsStatusDisabled
            icon = isSender ? outgoingDisabledIcon : incomingDisabledIcon
            break
        default:
            status = t("sessionsListStatusError")
            statusClass = classes.centralTextsStatusDisabled
            icon = isSender ? outgoingDisabledIcon : incomingDisabledIcon
            break
    }

    let title = null
    if (sessionStatus.currentConfig) {
        title = sessionStatus.currentConfig.freeCustomerID
    }

    const handleClick = () => onClick(sessionStatus.sessionId)

    return (
        <div>
            <ButtonBase 
                focusRipple 
                onClick={handleClick} 
                className={classes.content}>
                    <div>
                        <img className={classes.icon} src={icon} alt='' />
                    </div>
                    <div>
                        <div className={classes.centralTexts}>
                            <div className={classes.header}>
                                <div className={statusClass}>
                                    {status} {showActiveLed ? <img src={activeIcon} alt="" /> : null}
                                </div>
                                {sessionStatus.batteryLevel !== null && sessionStatus.batteryLevel >= 0 ?
                                    <div className={classes.batteryBox}>
                                        <BatteryIcon percLevel={sessionStatus.batteryLevel} height={smartphone ? 16 : 20} />
                                        <div>{t("sessionsListBatteryLevel", { percLevel: sessionStatus.batteryLevel})}</div>
                                    </div>
                                : null}
                            </div>
                            <div className={classes.centralTextsTitle}>
                                {title}
                            </div>
                            <div className={classes.centralTextsUpdate}>
                                <div className={classes.centralTextsUpdateLabel}>{t("sessionsListLastUpdate")}</div>
                                <div className={classes.centralTextsUpdateValue}>{sessionStatus.lastUpdate ? sessionStatus.lastUpdate.toLocaleString() : null}</div>
                            </div>
                        </div>
                    </div>
            </ButtonBase>
            <Divider />
        </div>
    )
}