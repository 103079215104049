import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Button from '@material-ui/core/Button'
import StepConnector from '@material-ui/core/StepConnector'
import StepButton from '@material-ui/core/StepButton'
import Step0 from './components/Step0'
import Step1 from './components/Step1'
import Step2 from './components/Step2'
import ConfirmDialog from './components/ConfirmDialog'
import { RoutePath, navigate, rxCameraAvailable } from '../../Main'
import { useEffect } from 'react'
import { User, UserProviders } from '../../components/Auth'


const useStyles = makeStyles(theme => ({
    root: {
        width: "90%",
        maxWidth: "750px",
        marginBottom: 16
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: "right",
        fontWeight: 550
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    label: {
        color: "#3f51b5",
        fontWeight: 550
    },
    stepContent: {
        flexDirection: "row",
        flexWrap: "wrap",
        borderLeft: 0,
        marginLeft: 0,
        marginTop: 0,
        paddingLeft: "3%",
        paddingRight: "3%"
    },
    stepper: {
        background: "rgb(231,231,231)",
        marginTop: "6%",
        padding: 0,
        paddingTop: 21
    },
    infoStep: {
        width: "90%",
        maxWidth: "700px",
    },
    stepStyle: {
        background: '#FFFFFF',
        paddingTop: '3%',
        paddingBottom: '3%',
        paddingLeft: '3%',
        paddingRight: '3%'
    },
    logo: {
        display: "flex",
        justifyContent: "center",
        padding: "5%"
    },
    page: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column"
    }
}))

const QontoConnector = withStyles({
    lineVertical: {
        minHeight: 0
    }
})(StepConnector)



/**
 * @param {{ user: User }} props
 */
export default function ActivationPage(props) {
    const classes = useStyles(props)
    const {t} = useTranslation()
    const defaultTrackingOptions = {
        senderAddress: props.user.email,
        recipientAddress: null,
        trackingInterval: 2, // index, corresponding to 3 hours
        notifyTrackerNotMoving: 0, // index, corresponding to -1 Km/h
        allowRecipientOndemand: false,
        allowRecipient2Deactivate: false,
        allowAnonymousUsers: props.user.provider === UserProviders.custom, // if trial account, enable by default
        shipmentId: null,
        shipFromAddress: null,
        shipToAddress: null
    }
    const [activeStep, setActiveStep] = useState(0)
    const [trackerId, setTrackerId] = useState(null)
    const [trackingOptions, setTrackingOptions] = useState({...defaultTrackingOptions})
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [cameraAvailable, setCameraAvailable] = useState(false)

    useEffect(() => {
        rxCameraAvailable().subscribe(available => setCameraAvailable(available))
    }, [])

    const handleStep0Next = () => setActiveStep(1)

    /**
     * @param {String} newTrackerId
     */
    const handleStep1Next = (newTrackerId) => {
        setActiveStep(2)
        setTrackerId(newTrackerId)
    }

    /**
     * @param {{senderAddress: String; 
     *          recipientAddress: String; 
     *          trackingInterval: Number;
     *          allowRecipientOndemand: Boolean;
     *          allowRecipient2Deactivate: Boolean}} newTrackingOptions
     */
    const handleStep2Next = newTrackingOptions => {
        setTrackingOptions(newTrackingOptions)
        setConfirmDialogOpen(true)
    }

    const confirmDialogOkClick = () => {
        setConfirmDialogOpen(false)
        navigate(props, RoutePath.root)
    }

    const handleReset = () => {
        setActiveStep(0)
        setTrackerId(null)
        setTrackingOptions({...defaultTrackingOptions})
    }

    const getStepTitles = cameraAvailable => [
        t("activationStep0Title"), 
        t(cameraAvailable ? "activationStep1Title" : "activationStep1TitleNoCamera"), 
        t("activationStep2Title")
    ]

    const stepsJsx = [
        <Step0 handleNext={handleStep0Next} user={props.user} {...props} />,
        <Step1 handleNext={handleStep1Next} user={props.user} trackerId={trackerId} {...props} />,
        <Step2 handleNext={handleStep2Next} user={props.user} trackerId={trackerId} trackingOptions={trackingOptions} {...props} />
    ]

    return (
        <div className={classes.page}>
            <div className={classes.root}>
                <Stepper 
                    activeStep={activeStep} 
                    orientation="vertical" 
                    className={classes.stepper} 
                    connector={<QontoConnector />}>
                        
                    {getStepTitles(cameraAvailable).map((title, index) => (
                        <Step key={index} className={classes.stepStyle}>
                            <StepButton onClick={() => setActiveStep(index)}>
                                <StepLabel>
                                    <div className={classes.label}>{title}</div> 
                                </StepLabel>
                            </StepButton>
                            <StepContent className={classes.stepContent} >
                                { stepsJsx[index] }
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                <Button disabled={activeStep <= 0} onClick={handleReset} className={classes.button}>{t("activationResetButton")}</Button>
            </div>
            <ConfirmDialog
                open={confirmDialogOpen}
                onOkClick={confirmDialogOkClick}
                onCancelClick={() => setConfirmDialogOpen(false)}
                trackerId={trackerId}
                trackingOptions={trackingOptions} 
                {...props} />
        </div>
    )
}
