import React, { Fragment, useEffect } from 'react'
import { RoutePath} from "../../Main"
import { makeStyles} from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import {Helmet} from "react-helmet"

const useStyles = makeStyles(theme => ({
    root: {
        alignSelf: 'center',
        width: '80vw',
        marginTop: 34,
        marginBottom: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    title: {
        width: '100%',
        color: '#3f58b8',
        fontSize: 24,
        textAlign: 'left'
    },
    pTitle: {
        width: '100%',
        color: '#3f58b8',
        fontSize: 20,
        textAlign: 'left'
    },
    pText: {
        width: '100%',
        fontSize: 16,
        textAlign: 'justify'
    }
}))

export default function LegalPage({translationName, ...props}) {
    const classes = useStyles(props)
    const isTerms = props.location.pathname === RoutePath.terms
    var {t}  = useTranslation(['terms', 'privacy'])
    /** @type {Array<{pTitle: String, pText: String}>} */ const pageBody = t(`${translationName}:pageBody`)
    return (
        <div className={classes.root}>
            <div className={classes.title}>{t(`${translationName}:pageTitle`)}</div>
            
            { pageBody.map((section, index) => (
                <Fragment key={index}>
                    { Boolean(section.pTitle) ? <div className={classes.pTitle}>{section.pTitle} </div> : null }
                    { Boolean(section.pText) ? <div className={classes.pText} dangerouslySetInnerHTML={{__html: section.pText}} /> : null}
                    {isTerms ? 
                        <Helmet>
                            <meta name="robots" content="index,follow"/>
                            <meta name="description" content="JustFollow.it is a service powered by Stetel, designed and developed entirely in Italy."/>
                            <title>JustFollow.it: Terms and Conditions</title>
                        </Helmet> 
                    : 
                        <Helmet>
                            <meta name="robots" content="noindex"/>
                            <title>JustFollow.it: Privacy Policy</title>
                        </Helmet>
                        }
                </Fragment>
            ))}
        </div>
    )
}