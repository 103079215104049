import React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import blinkingLedAnim from './blinking-leds.gif'

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: theme.spacing(1),
        marginRight: 0,
        float: "right",
        fontWeight: 550
    },
    stepStyle: {
        paddingBottom: '3%',
        paddingTop: '3%'
    },
    blinkingLedAnim: {
        marginTop: 16,
        marginBottom: 16
    }
}))
export default function Step0(props) {
    const classes = useStyles(props)
    const {t} = useTranslation()

    return(
        <div id="step0" className = {classes.stepStyle}>
            <Typography>{t("activationStep0Description")}</Typography>
            <img
                className={classes.blinkingLedAnim}
                src={blinkingLedAnim}
                alt=""
                style={{ width: "100%" }}/>
            <Typography>{t("activationStep0Note")}</Typography>
            <Button
                color="primary"
                onClick={props.handleNext}
                className={classes.button}>
                {t("activationStep0NextButton")}
            </Button>
        </div>
    )
}