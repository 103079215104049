import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import Main from './Main'
import * as serviceWorker from './serviceWorker'
import './components/i18n'
import LoadingView from './components/LoadingView'

ReactDOM.render(
    <Suspense fallback={<LoadingView show={true} />}>
        <Main />
    </Suspense>, 
    document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
