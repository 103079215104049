import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Button, LinearProgress } from "@material-ui/core"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { useState } from 'react'
// eslint-disable-next-line no-unused-vars
import { Subscription } from 'rxjs'
import Utb2bApi from '../../../network/UtB2bApi'
// eslint-disable-next-line no-unused-vars
import HttpError from '../../../network/HttpError'
import { redirectToLogin } from '../../../Main'

const useStyles = makeStyles(theme => ({
    dataOverlay: {
        margin: "10px 0px"
    },
    paraOverlay: {
        color: "#666",
        fontSize: "smaller"
    },
    valueOverlay: {
        wordWrap: "break-word"
    },
    resultBox: {
        display: 'flex',
        flexDirection: 'column'
    },
    resultMessage: {
        height: 40,
        fontWeight: 'bold',
        color: 'rgba(63,88,184,1)',
        textAlign: 'center'
    },
    errorResultMessage: {
        height: 40,
        fontWeight: 'bold',
        color: 'rgba(221,0,0,1)',
        textAlign: 'center'
    },
    loadingProgress: {
        visibility: 'visible'
        
    },
    hiddenLoadingProgress: {
        visibility: 'hidden'
    },
    notice: {
        fontStyle: "italic",
        paddingTop: 20
    }
}))

/** @type {Subscription} */ let activateSubscription

export default function ConfirmDialog(props) {
    const dialogModeType = {
        confirm: 1,
        loading: 2,
        error: 3,
        completed: 4,
        alreadyActive: 5,
        noCredits: 6
    }
    const classes = useStyles(props)
    const theme = useTheme()
    const {t} = useTranslation()
    const fullScreen = useMediaQuery(theme.breakpoints.down(350))
    const [dialogMode, setDialogMode] = useState(dialogModeType.confirm)

    useEffect(() => {
        return () => {
            if (activateSubscription) activateSubscription.unsubscribe()
        }
    }, [])

    const handleCancelButton = () => {
        // TODO 
        // should we call rxDeactivate if the rxActivate already completed?
        // check emitter.add inside rxActivate if it can be used to launch rxDeactivate on unsubscribe
        if (activateSubscription) activateSubscription.unsubscribe()
        setDialogMode(dialogModeType.confirm)
        props.onCancelClick()
    }

    const handleOkButton = () => {
        switch (dialogMode) {
            default: // dialogModeType.confirm && dialogModeType.error
                setDialogMode(dialogModeType.loading)
                activateSubscription = Utb2bApi.rxActivateTracker(props.trackerId, props.trackingOptions)
                    .subscribe((activationStatus) => {
                        console.log("Activation status ", activationStatus)
                        setDialogMode(dialogModeType.completed)
                    }, (/** @type {HttpError} */ error) => {
                        console.log("Activation error ", error)
                        if (error.statusCode === 409) {
                            setDialogMode(dialogModeType.alreadyActive)
                        } else if (error.statusCode === 429) {
                            setDialogMode(dialogModeType.noCredits)
                        } else if (error.statusCode === 401) {
                            redirectToLogin(props)
                        } else {
                            setDialogMode(dialogModeType.error)
                        }
                    })
                break
            case dialogModeType.loading:
                // do nothing (button should be disabled in this mode)
                break
            case dialogModeType.noCredits:
            case dialogModeType.alreadyActive:
                handleCancelButton()
                break
            case dialogModeType.completed:
                props.onOkClick()
                break
        }
    }

    const getOkButtonText = () => {
        switch (dialogMode) {
            default: // dialogModeType.confirm && dialogModeType.loading
                return t("activationStep2ConfirmDialogConfirmButton")
            case dialogModeType.error:
                return t("activationStep2ConfirmDialogErrorButton")
            case dialogModeType.completed:
            case dialogModeType.noCredits:
            case dialogModeType.alreadyActive:
                return t("activationStep2ConfirmDialogCloseButton")
        }
    }

    const getResultMessageText = () => {
        switch (dialogMode) {
            default: // dialogModeType.confirm
                return null
            case dialogModeType.loading:
                return t("activationStep2ConfirmDialogWaitMessage")
            case dialogModeType.error:
                return t("activationStep2ConfirmDialogErrorMessage")
            case dialogModeType.completed:
                return t("activationStep2ConfirmDialogCompletedMessage")
            case dialogModeType.noCredits:
                return t("activationStep2ConfirmDialogNoCreditsMessage")
            case dialogModeType.alreadyActive:
                return t("activationStep2ConfirmDialogAlreadyActiveMessage")
        }
    }

    const TextDataRow = props => 
        <div className={classes.dataOverlay}>
            <div className={classes.paraOverlay}>{props.text}</div>
            <div className={classes.valueOverlay}>{props.value}</div>
        </div>

    const CheckBoxDataRow = props => 
        <div className={classes.dataOverlay}>
            <div className={classes.paraOverlay}>{props.text}</div>
            <div className={classes.valueOverlay}>
                {props.value ? 
                    t("activationStep2ConfirmDialogCheckBoxEnabled") 
                : 
                    t("activationStep2ConfirmDialogCheckBoxDisabled")
                }
            </div>
        </div>

    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.open || false}>
            
            <DialogTitle>{t("activationStep2ConfirmDialogTitle")}</DialogTitle>
            <DialogContent>
                <TextDataRow 
                    text={t("activationStep2ConfirmDialogTrackerId")} 
                    value={props.trackerId} />
                <TextDataRow 
                    text={t("activationStep2SenderAddress")} 
                    value={props.trackingOptions.senderAddress} />
                <TextDataRow 
                    text={t("activationStep2RecipientAddress")} 
                    value={props.trackingOptions.recipientAddress} />
                <TextDataRow 
                    text={t("activationStep2TrackingInterval")} 
                    value={t("activationStep2TrackingIntervalOptions")[props.trackingOptions.trackingInterval]} />
                {props.trackingOptions.notifyTrackerNotMoving ? 
                    <TextDataRow 
                        text={t("activationStep2NotifyTrackerNotMoving")} 
                        value={t("activationStep2NotifyTrackerNotMovingOptions")[props.trackingOptions.notifyTrackerNotMoving]} />
                : null}
                {props.trackingOptions.shipmentId ? 
                    <TextDataRow 
                        text={t("activationStep2ShipmentId")} 
                        value={props.trackingOptions.shipmentId} />
                : null}
                {props.trackingOptions.shipFromAddress ? 
                    <TextDataRow 
                        text={t("activationStep2ShipFromAddress")} 
                        value={props.trackingOptions.shipFromAddress} />
                : null}
                {props.trackingOptions.shipToAddress ?
                    <TextDataRow 
                        text={t("activationStep2ShipToAddress")} 
                        value={props.trackingOptions.shipToAddress} />
                : null }
                {/* <CheckBoxDataRow 
                    text={t("activationStep2AllowAnonymousUsers")} 
                    value={props.trackingOptions.allowAnonymousUsers} />
                <CheckBoxDataRow 
                    text={t("activationStep2AllowRecipient2Deactivate")} 
                    value={props.trackingOptions.allowRecipient2Deactivate} /> */}
                {/* Disabled because the APIs do not support the "Request position" feature yet
                <CheckBoxDataRow 
                    text={t("activationStep2AllowRecipientOndemand")} 
                    value={props.trackingOptions.allowRecipientOndemand} /> */}
                <div className={classes.notice}>
                    {t("viewerDeactivateDialogNotice")}
                </div>
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={handleCancelButton} 
                    color="primary"
                    disabled={dialogMode === dialogModeType.completed 
                        || dialogMode === dialogModeType.loading 
                        || dialogMode === dialogModeType.noCredits 
                        || dialogMode === dialogModeType.alreadyActive }>
                    {t("activationStep2ConfirmDialogCancelButton")}
                </Button>
                <Button 
                    onClick={handleOkButton} 
                    color="primary"
                    disabled={dialogMode === dialogModeType.loading}>
                    {getOkButtonText()}
                </Button>
            </DialogActions>
            <div className={classes.resultBox}>
                <div className={dialogMode === dialogModeType.error
                        || dialogMode === dialogModeType.alreadyActive  
                        || dialogMode === dialogModeType.noCredits 
                    ? classes.errorResultMessage : classes.resultMessage}>
                    {getResultMessageText()}
                </div>
                <LinearProgress className={dialogMode === dialogModeType.loading ? classes.loadingProgress : classes.hiddenLoadingProgress } />
            </div>
        </Dialog>
    )
}
