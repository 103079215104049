import React from 'react'
import { useTranslation } from 'react-i18next'
import TextField from "@material-ui/core/TextField"
import { makeStyles, Button } from "@material-ui/core"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { UserProviders } from '../../../components/Auth'

const useStyles = makeStyles(theme => ({
    textField: {
        marginTop: 0,
        marginBottom: 16,
        width: "100%",
        '& p': {
            color: "#e60000"
        }
    },
    buttonWrapper: {
        display: "flex",
        justifyContent: "center",
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: "right",
        fontWeight: 550,
        width: '42%'
    },
    stepStyle: {
        paddingBottom: '3%',
        paddingTop: '3%'
    }
}))

const trackerIntervalDisabledFromIndex = 2 // check UtB2bApi.trackingIntervalOptions to decide the starting index 
const trackerIntervalDefaultWhenDisabled = 1 // check UtB2bApi.trackingIntervalOptions to decide the starting index 

export default function Step2(props) {
    const classes = useStyles(props)
    const {t} = useTranslation()
    let [trackingOptions, setTrackingOptions] = React.useState(props.trackingOptions)
    let [helperTexts, setHelperTexts] = React.useState({senderAddress: null, recipientAddress: null})

    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

    const handleTextChange = event => {
        setTrackingOptions({...trackingOptions, [event.target.name]: event.target.value })
        setHelperTexts({senderAddress: null, recipientAddress: null})
    }

    const handleTrackerNotMovingChange = event => {
        if (event.target.value > 0 && trackingOptions.trackingInterval >= trackerIntervalDisabledFromIndex) {
            trackingOptions.trackingInterval = trackerIntervalDefaultWhenDisabled
        }
        handleTextChange(event)
    }

    const handleCheckBoxChange = event => {
        setTrackingOptions({...trackingOptions, [event.target.name]: event.target.checked })
    }

    const isNextButtonDisabled = () => !Boolean(trackingOptions.senderAddress?.length)
        || !Boolean(trackingOptions.recipientAddress?.length)
        || !Boolean(trackingOptions.shipmentId?.length)

    const handleNext = () => {
        let notValidCount = 0
        let helperTextsTemp = {}
        if (!emailRegex.test(trackingOptions.senderAddress)) {
            helperTextsTemp.senderAddress = t("activationStep2SenderAddressNotValid")
            notValidCount++
        }
        if (!emailRegex.test(trackingOptions.recipientAddress)) {
            helperTextsTemp.recipientAddress = t("activationStep2RecipientAddressNotValid")
            notValidCount++
        }
        if (notValidCount > 0) {
            setHelperTexts(helperTextsTemp)
        } else {
            props.handleNext(trackingOptions)
        }
    }

    const isTrackingIntervalOptionsDisabled = index => {
        return trackingOptions.notifyTrackerNotMoving > 0 && index >= trackerIntervalDisabledFromIndex
    }

    return (
        <div>
            <form className={classes.stepStyle} noValidate autoComplete="off">
                <TextField 
                    name="senderAddress"
                    className={classes.textField}
                    label={t("activationStep2SenderAddress")} 
                    value={trackingOptions.senderAddress || props.user.email || ""}
                    helperText={helperTexts.senderAddress}
                    onChange={handleTextChange}
                    autoComplete="email"
                    disabled={props.user.provider === UserProviders.google} />
                <TextField 
                    name="recipientAddress"
                    className={classes.textField}
                    label={t("activationStep2RecipientAddress")} 
                    value={trackingOptions.recipientAddress || ""}
                    helperText={helperTexts.recipientAddress}
                    onChange={handleTextChange}
                    autoComplete="email" />
                <FormControl className={classes.textField}>
                    <InputLabel htmlFor="type-helper">
                        {trackingOptions.notifyTrackerNotMoving > 0 ? 
                            t("activationStep2TrackingIntervalMovementNotifications") 
                            : t("activationStep2TrackingInterval")
                        }
                    </InputLabel>
                    <Select
                        name="trackingInterval"
                        value={trackingOptions.trackingInterval}
                        onChange={handleTextChange}>
                        {t("activationStep2TrackingIntervalOptions").map((option, index) => (
                            <MenuItem 
                                value={index} 
                                key={index}
                                disabled={isTrackingIntervalOptionsDisabled(index)}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                
                {/*<FormControl className={classes.textField} disabled>  Disabled since it's not implemented yet on the tracker's firmware  
                    <InputLabel htmlFor="type-helper">{t("activationStep2NotifyTrackerNotMoving")}</InputLabel>
                    <Select
                        name="notifyTrackerNotMoving"
                        value={trackingOptions.notifyTrackerNotMoving}
                        onChange={handleTrackerNotMovingChange}>
                        {t("activationStep2NotifyTrackerNotMovingOptions").map((option, index) => (
                            <MenuItem value={index} key={index}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>*/}
                <TextField 
                    name="shipmentId"
                    className={classes.textField}
                    label={t("activationStep2ShipmentId")} 
                    value={trackingOptions.shipmentId || ""}
                    onChange={handleTextChange} />
                <TextField 
                    name="shipFromAddress"
                    className={classes.textField}
                    label={t("activationStep2ShipFromAddress")} 
                    value={trackingOptions.shipFromAddress || ""}
                    onChange={handleTextChange}
                    autoComplete="street-address" />
                <TextField 
                    name="shipToAddress"
                    className={classes.textField}
                    label={t("activationStep2ShipToAddress")} 
                    value={trackingOptions.shipToAddress || ""}
                    onChange={handleTextChange}
                    autoComplete="street-address" />
                {/* <FormControlLabel
                    label={t("activationStep2AllowAnonymousUsers")}
                    labelPlacement="end"
                    control={
                        <Checkbox 
                            name="allowAnonymousUsers"
                            color="primary" 
                            checked={trackingOptions.allowAnonymousUsers}
                            onChange={handleCheckBoxChange} />
                        }
                    disabled={props.user.provider === UserProviders.custom}
                /> */}
                {/* <FormControlLabel
                    label={t("activationStep2AllowRecipient2Deactivate")}
                    labelPlacement="end"
                    control={
                        <Checkbox 
                            name="allowRecipient2Deactivate"
                            color="primary" 
                            checked={trackingOptions.allowRecipient2Deactivate}
                            onChange={handleCheckBoxChange} />
                        }
                    disabled={props.user.provider === UserProviders.custom}
                /> */}
                {/* // Disabled because the APIs do not support the "Request position" feature yet
                <FormControlLabel
                    label={t("activationStep2AllowRecipientOndemand")}
                    labelPlacement="end"
                    control={
                        <Checkbox 
                            name="allowRecipientOndemand"
                            color="primary" 
                            checked={trackingOptions.allowRecipientOndemand}
                            onChange={handleCheckBoxChange} />
                        }
                /> */}
                <div className={classes.buttonWrapper}>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={isNextButtonDisabled()}
                        onClick={handleNext}
                        className={classes.button}>
                            {t("activationStep2NextButton")}
                    </Button>
                </div>
            </form>
        </div>
    )
}