import { ApiClient } from "./swaggerApi/ApiClient"
import { TrackingOptions } from "./swaggerApi/model/TrackingOptions"
import { ActivationConfig } from "./swaggerApi/model/ActivationConfig"
import { WebSenderApi } from "./swaggerApi/api/WebSenderApi"
import { WebAnonymousApi } from "./swaggerApi/api/WebAnonymousApi"
// eslint-disable-next-line no-unused-vars
import { Observable, Subscriber } from 'rxjs'
// eslint-disable-next-line no-unused-vars
import { ActivationStatus } from "./swaggerApi/model/ActivationStatus"
// eslint-disable-next-line no-unused-vars
import { SessionStatus } from "./swaggerApi/model/SessionStatus"
import HttpError from "./HttpError"
// eslint-disable-next-line no-unused-vars
import { RolesList } from "./swaggerApi/model/RolesList"
import { UserCredentialsPlain } from "./swaggerApi/model/UserCredentialsPlain"

const processResponse = (emitter, error, data, response) => {
    if (error) {
        emitter.error(response 
            ? new HttpError(response.statusCode, response.statusText)
            : new HttpError(-1, "Request has been terminated"))
    } else {
        emitter.next(data)
        emitter.complete()
    }
}

// const apiClient = new ApiClient()
// if (process.env.NODE_ENV) {
//     // DEVELOPMENT SERVER
//     apiClient.basePath = "https://2-dot-utm2mdev.appspot.com/stetel/UTb2b/1.0.0"
// } else {
//     // PRODUCTION SERVER
//     apiClient.basePath = "https://utm2mdev.appspot.com/stetel/UTb2b/1.0.0"
// }
// apiClient.timeout = 120000

const apiClient = new ApiClient()
    apiClient.basePath = "https://2-dot-utm2mdev.appspot.com/stetel/UTb2b/1.0.0"
apiClient.timeout = 120000

class RxNetApi {

    /**
     * Activate a UrbanTracker.
     * 
     * Since the request requires to communicate with the device, you must call the 
     * activationStatus API to check if and when the tracker has completed the activation.
     * 
     * @param {User} user User data
     * @param {String} trackerId UrbanTracker serial ID
     * @param {String} senderAddress Address of the sender (usually email)
     * @param {String} recipientAddress Address of the recipient (usually email)
     * @param {Number} trackingInterval Index related to the desired interval: minutes [5, 30, 60, 180, 360]
     * @param {Number} alertSpeedLowerThanKmh Speed which can trigger the notifications [-1, 40, 0]
     * @param {Boolean} allowRecipientOndemand Allow recipient to request geolocation manually
     * @param {Boolean} allowRecipient2Deactivate Allow recipient to deactivate UrbanTracker via the web panel
     * @param {Boolean} allowAnonymousUsers Allow anonymous user to track the shipment
     * @param {String} shipmentId Metadata for the ID of the Order/Shipment
     * @param {String} shipFromAddress Metadata containing the address where the shipment will depart
     * @param {String} shipToAddress Metadata containing the address where the shipment will arrive
     * 
     * @returns {Observable<ActivationStatus>} Observable containing the Activation Status response data
     */
    rxActivate = (user, trackerId, senderAddress, recipientAddress, trackingInterval, alertSpeedLowerThanKmh, 
        allowRecipientOndemand, allowRecipient2Deactivate, allowAnonymousUsers, shipmentId,
        shipFromAddress, shipToAddress) => new Observable((/** @type {Subscriber} */ emitter) => {
            const trackingOptions = new TrackingOptions(senderAddress, recipientAddress)
            trackingOptions.allowSenderOndemand = true
            trackingOptions.viewerMustAuthenticate = !allowAnonymousUsers
            trackingOptions.trackingIntervall = trackingInterval
            trackingOptions.alertSpeedLowerThanKmh = alertSpeedLowerThanKmh
            trackingOptions.allowRecipientOndemand = allowRecipientOndemand
            trackingOptions.allowRecipient2Deactivate = allowRecipient2Deactivate
            trackingOptions.freeCustomerID = shipmentId
            trackingOptions.freeFromAddress = shipFromAddress
            trackingOptions.freeToAddress = shipToAddress
            const activationConfig = new ActivationConfig(user.username, trackingOptions)
            console.log("WebSenderApi Activate Request idToken", user.idToken, 
                "\ntrackerId ", trackerId, 
                "\nactivationConfig ", activationConfig)
            const httpRequest = new WebSenderApi(apiClient).activate(user.idToken,
                trackerId,
                { activationconfig: activationConfig}, 
                (/** @type {Error} */ error, /** @type {ActivationStatus} */ data, response) => {
                    console.log("WebSenderApi Activate", response, error)
                    processResponse(emitter, error, data, response)
                })
            return () => httpRequest.abort()
        })

    /**
     * Check the current UrbatTracker status.
     * 
     * @param {String} idToken User ID token
     * @param {String} sessionId Session ID retrieved with the Activate request
     * 
     * @returns {Observable<ActivationStatus>} Observable containing the Activation Status response data
     */
    rxActivationStatus = (idToken, sessionId) => new Observable((/** @type {Subscriber} */ emitter) => {
        console.log("WebSenderApi Activate Request idToken", idToken, 
            "\nsessionId ", sessionId)
        const httpRequest = new WebSenderApi(apiClient).activationstatus(idToken, sessionId, 
            (/** @type {Error} */ error, /** @type {ActivationStatus} */ data, response) => {
                console.log("WebSenderApi ActivationStatus", response, error)
                processResponse(emitter, error, data, response)
            }
        )
        return () => httpRequest.abort()
    })

    /**
     * Deactivate a UrbanTracker.
     * 
     * Since the request requires to communicate with the device, you must call the 
     * activationStatus API to check if and when the tracker has completed the deactivation.
     * 
     * @param {String} idToken User ID token
     * @param {String} trackerId UrbanTracker serial ID
     * @param {String} sessionId Session ID retrieved with the Activate request
     * 
     * @returns {Observable<ActivationStatus>} Observable containing the Activation Status response data
     */
    rxDeactivate = (idToken, trackerId, sessionId) => new Observable((/** @type {Subscriber} */ emitter) => {
        const httpRequest = new WebSenderApi(apiClient).deactivate(idToken, trackerId, sessionId, 
            (/** @type {Error} */ error, /** @type {ActivationStatus} */ data, response) => {
                console.log("WebSenderApi Deactivate", response, error)
                processResponse(emitter, error, data, response)
            }
        )
        return () => httpRequest.abort()
    })

    /**
     * Get the session status of the UrbanTracker which contains various device's information
     * and the list of the acquired geolocations based on the tracking interval.
     * 
     * @param {String} idToken User ID token
     * @param {String} sessionId Session ID retrieved with the Activate request
     * @param {Number?} startingFromId Retrieve geolocations starting from the specified ID
     * @param {Number?} maxPoints Retrieve the selected number of geolocations
     * 
     * @returns {Observable<SessionStatus>} Observable containing the Session Status response data
     */
    rxStatus = (idToken, sessionId, startingFromId, maxPoints) => 
        new Observable((/** @type {Subscriber} */ emitter) => {
        const opts = {
            xAuth: idToken,
            startingfromid: startingFromId,
            maxpoints: maxPoints
        }
        const httpRequest = new WebSenderApi(apiClient).status(sessionId, opts, 
            (/** @type {Error} */ error, /** @type {SessionStatus} */ data, response) => {
                console.log("WebSenderApi Status", response, error)
                processResponse(emitter, error, data, response)
            }
        )
        return () => httpRequest.abort()
    })

    /**
     * Get the sessions list of all the UrbanTrackers which contains various devices' information.
     * Must specify direction and status.
     * 
     * @param {String} idToken User ID token
     * @param {String} direction Filter sessions by tracker's being "sent" or being "receiving", related to the passed User ID
     * @param {SessionStatus.StatusEnum} status Filter sessions by tracker's status
     * @param {String?} startingFromSessionId Retrieve geolocations starting from the specified ID
     * @param {Number?} maxSessions Retrieve the selected number of geolocations
     * 
     * @returns {Observable<SessionStatus[]>} Observable containing an array of Session Status response data
     */
    rxSessionslist = (idToken, direction, status, startingFromSessionId, maxSessions) => 
        new Observable((/** @type {Subscriber} */ emitter) => {
            const opts = {
                startingfromsessionId: startingFromSessionId,
                maxsessions: maxSessions
            }
            const httpRequest = new WebSenderApi(apiClient).sessionslist(idToken, direction, status, opts,
                (/** @type {Error} */ error, /** @type {SessionStatus[]} */ data, response) => {
                    console.log("WebSenderApi Sessions List", response, error)
                    processResponse(emitter, error, data, response)
                }
            )
            return () => httpRequest.abort()
        })
    
    /**
     * Get the roles list related to the user.
     * 
     * @param {String} idToken User ID token
     * 
     * @returns {Observable<RolesList>} Observable containing the roles
     */
    rxMyRoles = (idToken) => new Observable((/** @type {Subscriber} */ emitter) => {
        const opts = {
            xAuth: idToken
        }
        const httpRequest = new WebSenderApi(apiClient).myroles(opts, 
            (/** @type {Error} */ error, /** @type {RolesList} */ data, response) => {
                console.log("WebSenderApi My Roles", response, error)
                processResponse(emitter, error, data, response)
            }
        )
        return () => httpRequest.abort()
    })
    
    /**
     * Login the user using the trial kit.
     * Get the trial ID token and the roles list related to the user.
     * 
     * @param {String} username Username
     * @param {String} password Password
     * 
     * @returns {Observable<AuthInfo>} Observable containing the ID token and the roles
     */
    rxTrialLogin = (username, password) => new Observable((/** @type {Subscriber} */ emitter) => {
        const opts = {
            credentials: new UserCredentialsPlain(username, password)
        }
        const httpRequest = new WebSenderApi(apiClient).trialLogin(opts, 
            (/** @type {Error} */ error, /** @type {AuthInfo} */ data, response) => {
                console.log("WebSenderApi Trial Login", response, error)
                processResponse(emitter, error, data, response)
            }
        )
        return () => httpRequest.abort()
    })
    
    /**
     * Logout the user using the trial kit.
     * 
     * @returns {Observable<void>} Observable without any data
     */
    rxTrialLogout = (idToken) => new Observable((/** @type {Subscriber} */ emitter) => {
        const httpRequest = new WebSenderApi(apiClient).trialLogout(idToken, 
            (/** @type {Error} */ error, /** @type {void} */ data, response) => {
                console.log("WebSenderApi Trial Logout", response, error)
                processResponse(emitter, error, data, response)
            }
        )
        return () => httpRequest.abort()
    })
    
    /**
     * Send a message via the backend
     * 
     * @param {String} idToken
     * @param {MessageDetails} messageDetails
     * 
     * @returns {Observable<void>} Observable without any data
     */
     rxForwardMessage = (idToken, messageDetails) => new Observable((/** @type {Subscriber} */ emitter) => {
        const opts = {
            xAuth: idToken,
            messagedetails: messageDetails
        }
        const httpRequest = new WebAnonymousApi(apiClient).forwardmessage(opts, 
            (/** @type {Error} */ error, /** @type {void} */ data, response) => {
                console.log("WebAnonymousApi Forward Message", response, error)
                processResponse(emitter, error, data, response)
            }
        )
        return () => httpRequest.abort()
    })
}

export default new RxNetApi()