/*
 * UT b2b API
 * Urbantracker business 2 business API
 *
 * OpenAPI spec version: 1.2.0
 * Contact: nospam@stetel.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.18
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from "../ApiClient";
import {ActivationConfig} from '../model/ActivationConfig';
import {ActivationStatus} from '../model/ActivationStatus';
import {AuthInfo} from '../model/AuthInfo';
import {RolesList} from '../model/RolesList';
import {SessionStatus} from '../model/SessionStatus';
import {UserCredentialsPlain} from '../model/UserCredentialsPlain';

/**
* WebSender service.
* @module api/WebSenderApi
* @version 1.2.0
*/
export class WebSenderApi {

    /**
    * Constructs a new WebSenderApi. 
    * @alias module:api/WebSenderApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the activate operation.
     * @callback module:api/WebSenderApi~activateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ActivationStatus} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * activates a new b2b tracker
     * activates a new b2b tracker
     * @param {String} xAuth 
     * @param {String} trackerId The trackerId taken via QR Code
     * @param {Object} opts Optional parameters
     * @param {module:model/ActivationConfig} opts.activationconfig configuration for this activation
     * @param {module:api/WebSenderApi~activateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ActivationStatus}
     */
    activate(xAuth, trackerId, opts, callback) {
      opts = opts || {};
      let postBody = opts['activationconfig'];

      // verify the required parameter 'xAuth' is set
      if (xAuth === undefined || xAuth === null) {
        throw new Error("Missing the required parameter 'xAuth' when calling activate");
      }

      // verify the required parameter 'trackerId' is set
      if (trackerId === undefined || trackerId === null) {
        throw new Error("Missing the required parameter 'trackerId' when calling activate");
      }


      let pathParams = {
        'trackerId': trackerId
      };
      let queryParams = {
      };
      let headerParams = {
        'X-Auth': xAuth
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ActivationStatus;

      return this.apiClient.callApi(
        '/activate/{trackerId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the activationstatus operation.
     * @callback module:api/WebSenderApi~activationstatusCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ActivationStatus} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * allows to check the status of a tracker
     * check activation status
     * @param {String} xAuth 
     * @param {String} sessionId The sessionid to verify
     * @param {module:api/WebSenderApi~activationstatusCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ActivationStatus}
     */
    activationstatus(xAuth, sessionId, callback) {
      let postBody = null;

      // verify the required parameter 'xAuth' is set
      if (xAuth === undefined || xAuth === null) {
        throw new Error("Missing the required parameter 'xAuth' when calling activationstatus");
      }

      // verify the required parameter 'sessionId' is set
      if (sessionId === undefined || sessionId === null) {
        throw new Error("Missing the required parameter 'sessionId' when calling activationstatus");
      }


      let pathParams = {
        'sessionId': sessionId
      };
      let queryParams = {
      };
      let headerParams = {
        'X-Auth': xAuth
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ActivationStatus;

      return this.apiClient.callApi(
        '/activationstatus/{sessionId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the deactivate operation.
     * @callback module:api/WebSenderApi~deactivateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ActivationStatus} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * status of the tracker
     * deactivates an active tracker making it ready for another activation.
     * @param {String} xAuth 
     * @param {String} trackerId The trackerId to deactivate
     * @param {String} sessionid The sessionId associated to the tracker to deactivate
     * @param {module:api/WebSenderApi~deactivateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ActivationStatus}
     */
    deactivate(xAuth, trackerId, sessionid, callback) {
      let postBody = null;

      // verify the required parameter 'xAuth' is set
      if (xAuth === undefined || xAuth === null) {
        throw new Error("Missing the required parameter 'xAuth' when calling deactivate");
      }

      // verify the required parameter 'trackerId' is set
      if (trackerId === undefined || trackerId === null) {
        throw new Error("Missing the required parameter 'trackerId' when calling deactivate");
      }

      // verify the required parameter 'sessionid' is set
      if (sessionid === undefined || sessionid === null) {
        throw new Error("Missing the required parameter 'sessionid' when calling deactivate");
      }


      let pathParams = {
        'trackerId': trackerId,
        'sessionid': sessionid
      };
      let queryParams = {
      };
      let headerParams = {
        'X-Auth': xAuth
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ActivationStatus;

      return this.apiClient.callApi(
        '/deactivate/{trackerId}/{sessionid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the myroles operation.
     * @callback module:api/WebSenderApi~myrolesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/RolesList} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * roles associated to this authenticated caller
     * returns current roles of the calling user
     * @param {Object} opts Optional parameters
     * @param {String} opts.xAuth 
     * @param {module:api/WebSenderApi~myrolesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/RolesList}
     */
    myroles(opts, callback) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
        'X-Auth': opts['xAuth']
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = RolesList;

      return this.apiClient.callApi(
        '/roles/myroles', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the sessionslist operation.
     * @callback module:api/WebSenderApi~sessionslistCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/SessionStatus>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * return the list of sessions
     * return all sessions (either sent or received) with the given status
     * @param {String} xAuth 
     * @param {module:model/String} direction The desired direction (sent, receiving)
     * @param {module:model/String} status The desired status
     * @param {Object} opts Optional parameters
     * @param {String} opts.startingfromsessionId 
     * @param {Number} opts.maxsessions 
     * @param {module:api/WebSenderApi~sessionslistCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/SessionStatus>}
     */
    sessionslist(xAuth, direction, status, opts, callback) {
      opts = opts || {};
      let postBody = null;

      // verify the required parameter 'xAuth' is set
      if (xAuth === undefined || xAuth === null) {
        throw new Error("Missing the required parameter 'xAuth' when calling sessionslist");
      }

      // verify the required parameter 'direction' is set
      if (direction === undefined || direction === null) {
        throw new Error("Missing the required parameter 'direction' when calling sessionslist");
      }

      // verify the required parameter 'status' is set
      if (status === undefined || status === null) {
        throw new Error("Missing the required parameter 'status' when calling sessionslist");
      }


      let pathParams = {
        'direction': direction,
        'status': status
      };
      let queryParams = {
        'startingfromsessionId': opts['startingfromsessionId'],
        'maxsessions': opts['maxsessions']
      };
      let headerParams = {
        'X-Auth': xAuth
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [SessionStatus];

      return this.apiClient.callApi(
        '/sessions/{direction}/{status}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the status operation.
     * @callback module:api/WebSenderApi~statusCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SessionStatus} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * status of the tracker session
     * returns current status of the indicated session
     * @param {String} sessionId The sessionId to verify
     * @param {Object} opts Optional parameters
     * @param {String} opts.xAuth 
     * @param {Number} opts.startingfromid 
     * @param {Number} opts.maxpoints 
     * @param {module:api/WebSenderApi~statusCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SessionStatus}
     */
    status(sessionId, opts, callback) {
      opts = opts || {};
      let postBody = null;

      // verify the required parameter 'sessionId' is set
      if (sessionId === undefined || sessionId === null) {
        throw new Error("Missing the required parameter 'sessionId' when calling status");
      }


      let pathParams = {
        'sessionId': sessionId
      };
      let queryParams = {
        'startingfromid': opts['startingfromid'],
        'maxpoints': opts['maxpoints']
      };
      let headerParams = {
        'X-Auth': opts['xAuth']
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = SessionStatus;

      return this.apiClient.callApi(
        '/status/{sessionId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the trialLogin operation.
     * @callback module:api/WebSenderApi~trialLoginCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AuthInfo} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * allows to obtain a auth token for a trial account
     * allows to obtain a auth token for a trial account
     * @param {Object} opts Optional parameters
     * @param {module:model/UserCredentialsPlain} opts.credentials the credentials to access the account
     * @param {module:api/WebSenderApi~trialLoginCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AuthInfo}
     */
    trialLogin(opts, callback) {
      opts = opts || {};
      let postBody = opts['credentials'];


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = AuthInfo;

      return this.apiClient.callApi(
        '/trial/login', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the trialLogout operation.
     * @callback module:api/WebSenderApi~trialLogoutCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * allows to logout by voiding the X-Auth
     * allows to logout by voiding the X-Auth
     * @param {String} xAuth 
     * @param {module:api/WebSenderApi~trialLogoutCallback} callback The callback function, accepting three arguments: error, data, response
     */
    trialLogout(xAuth, callback) {
      let postBody = null;

      // verify the required parameter 'xAuth' is set
      if (xAuth === undefined || xAuth === null) {
        throw new Error("Missing the required parameter 'xAuth' when calling trialLogout");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
        'X-Auth': xAuth
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/trial/logout', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }


}
